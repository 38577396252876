<ng-container *ngIf="!projectId">
    <app-sub-header [title]="[currentUser.role === UserRole.ROLE_SUPERADMIN ? (globalStore.companyName$ | async) : '', 'Users']"></app-sub-header>
</ng-container>
<app-filter-fields btnText="Invite new user" [searchPlaceholder]="projectId ? 'Search by Name, Email or ID ' : companyId ? 'Search by Name or Email' : 'Search by Name, Email or Company'" [disabled]="isNoFilteringEnabled() && usersPage?.empty" [filtersAmount]="getFiltersActiveAmount()"
                   (inputChanged)="query = $event; getUsersList()" (clearAllFilters)="clearFilters()"
                   (openFormBtnClick)="inviteUsersButtonClicked()" (openAddUsersBtnClick)="addUsersFormOpened = true"
                   (deleteSelectedItem)="deletingPopupOpened = true" (cancelSelectedItem)="multiSelectedUsers = []; isMultiSelectAll = false" [selectedItemsAmount]="multiSelectedUsers.length">
    <div class="filter-field-wrapper">
        <label class="filter-label">
          Filter by Role:
        </label>
        <div class="ng-select-wrapper">
            <ng-select [(ngModel)]="selectedUserRole"
                       notFoundText="No results matching"
                       [editableSearchTerm]="true"
                       (change)="getUsersList()"
                       placeholder="Add Role">
                <ng-option *ngFor="let userRole of userRoleStringForSelector | keyvalue" [value]="userRole.key">{{userRole.value}}</ng-option>
            </ng-select >
        </div>
    </div>
    <div *ngIf="!companyId" class="filter-field-wrapper">
        <label class="filter-label">
            Filter by Company:
        </label>
        <div class="ng-select-wrapper">
            <ng-select [items]="companiesFilterValues"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="selectedCompanyId"
                       notFoundText="No results matching"
                       [editableSearchTerm]="true"
                       (change)="getUsersList()"
                       placeholder="Add Company">
            </ng-select>
        </div>
    </div>

    <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>


<ng-scrollbar *ngIf="usersPage && !isLoading;" [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.usersPage.totalElements > 20 && !(isMobile | async)" [attr.isUsersInProjectPage]="isProjectUsersPage" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.usersPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-users" *ngIf="!usersPage.empty; else emptyList">
        <ul class="table-users__header-row">
          <li class="table-users__multiselected" *ngIf="multiSelectEnabled && multiSelectedUsers.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>
          <li class="table-users__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedUsers.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['id']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('id')" [style.opacity]="sorting['id'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['id'] === SortType.DESC" *ngIf="sorting['id']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-users__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedUsers.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['name']">
              User Name
              <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                   <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-users__role-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['role']">
              Role
              <button class="table-sort-icon-btn" (click)="onSortBy('role')" [style.opacity]="sorting['role'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['role'] === SortType.DESC" *ngIf="sorting['role']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-users__email-title">Email</li>
          <li [ngClass]="currentUser.role === UserRole.ROLE_SUPERADMIN || !isCompanyUsersPage ? 'table-users__phone-title no-right-border' : 'table-users__phone-title'">Phone Number</li>
          <li class="table-users__company-title no-right-border" *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN && !isCompanyUsersPage">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['company.name']">
              Company Name
              <button class="table-sort-icon-btn" (click)="onSortBy('company.name')" [style.opacity]="sorting['company.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['company.name'] === SortType.DESC" *ngIf="sorting['company.name']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-users__actions no-right-border fixed-column"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let user of usersPage.content; index as i" [id]="user.id" class="table-users__row" [attr.multiselected]="multiSelectedUsers.includes(user.id)" [ngStyle]="multiSelectedUsers.includes(user.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="company-id multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedUsers.length > 0">
                <div class="checkbox-container" *ngIf="isShowDeleteBtn(user.id, user.role) && currentUser.id !== user.id">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedUsers.includes(user.id)" (change)="toggleCheck(user.id)" [id]="'selectItemCheckbox-' + i" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="company-id fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedUsers.length > 0" [routerLink]="generateUserRouterLink(user)" [ngStyle]="multiSelectedUsers.includes(user.id) ? {'background-color': '#F7F7F8'} : {}">
                     <span class="item-code-way user-id-wrapper">
                        <span class="list-name-wrapper">
                          {{user.id}}
                        </span>
                     </span>
              </li>
              <li class="company-name fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedUsers.length > 0" [routerLink]="generateUserRouterLink(user)" [ngStyle]="multiSelectedUsers.includes(user.id) ? {'background-color': '#F7F7F8'} : {}">
                      <span class="list-name-wrapper user-name-wrapper" [title]="user.name" *ngIf="user.name">
                        {{user.name}}
                      </span>
                <ng-template [ngIf]="!user.name">-</ng-template>
              </li>
              <li class="user-role-wrapper"> <span class="table-users__role" [attr.role]="user.role">{{roleDescByEnumForTable.get(user.role)}}</span> </li>
              <li class="user-email"><span class="user-email-wrapper">{{user.email}}</span></li>
              <li class="user-phone"> {{user.phone | phonePrettify}}</li>
              <li class="user-company-name" *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN && !isCompanyUsersPage">{{user.company.name}}</li>
              <li tabindex="1" id="dotsMenu" class="dots-actions fixed-column" *ngIf="isShowDotsBtn(user.id, user.role); else dotsMock"
                   #usersDots (click)="usersDots.toggleAttribute('editMenuOpen'); $event.stopPropagation()" [enabled]="usersDots.hasAttribute('editMenuOpen')" (appOutsideClick)="usersDots.removeAttribute('editMenuOpen')">
                <ul class="editMenuList" #editMenuList (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'" [ngStyle]="{width: projectId ? '208px' : '140px'}" [@fadeIn] *ngIf="usersDots.hasAttribute('editMenuOpen')">
                  <li class="editMenuList__item" *ngIf="isShowDeleteBtn(user.id, user.role)" id="selectItem" (click)="toggleCheck(user.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li class="editMenuList__item" id="editItem" *ngIf="(currentUser.role !== UserRole.ROLE_PROJECT_MANAGER && currentUser.id !== user.id) || currentUser.id === user.id" (click)="getUserById(user.id); userEditFormOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>
                  <li class="editMenuList__item" *ngIf="isShowDeleteBtn(user.id, user.role)" id="deleteItem" (click)="selectedUser = user; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    {{projectId ? 'Remove from project' : 'Delete'}}
                  </li>
                </ul>
              </li>

              <ng-template #dotsMock><li class="dots-actions-mock fixed-column"></li></ng-template>

            </ul>
          </li>

          <app-pagination *ngIf="this.usersPage.totalElements > 20 && (isMobile | async)" [isLoading]="!usersPage" [paginationInfo]="this.usersPage" [showAmountForMobile]="pageSizeOnMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </div>

    </div>
  </div>
</ng-scrollbar>

<div *ngIf="!usersPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>

<app-pagination *ngIf="this.usersPage?.totalElements > 20 && !(isMobile | async)" [showAmountForDesk]="pageSizeOnDesktop" [isLoading]="!usersPage" [paginationInfo]="this.usersPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>

<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && usersPage.empty; else notFound">The list of users is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Users found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>
<app-invite-user-form *ngIf="inviteFormOpened" (close)="inviteFormOpened = false" (created)="userInvited()"></app-invite-user-form>
<app-add-users-form *ngIf="addUsersFormOpened" (close)="addUsersFormOpened = false" (created)="addUsersFormOpened = false; getUsersList()"></app-add-users-form>


<div class="backdrop manual" [attr.show]="deletingPopupOpened">
  <div [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="projectId">Remove {{selectedUser ? 'this user' : 'these users'}} from the project?</p>
      <p class="confirmDeleting-title" *ngIf="!projectId">Delete {{selectedUser ? 'this User' : 'All Users'}}?</p>
      <p class="confirmDeleting-text">
        <ng-container *ngIf="projectId">If you remove {{selectedUser ? 'this user' : 'these users'}} from the project, all statistics for {{selectedUser ? 'this user' : 'these users'}} will be saved, but {{selectedUser ? 'this user' : 'these users'}} will no longer have access to this project.</ng-container>
        <ng-container *ngIf="!projectId">Do you really want to delete {{selectedUser ? 'this user' : 'these users'}}? All information about {{selectedUser ? 'this user' : 'these users'}} will be deleted, as well as access to the system.</ng-container>
      </p>
    </div>

    <div class="form-btn-wrapper form-btn-wrapper-remove-from-project" [attr.inProject]="!!projectId">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" id="btnDeleteProject" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="projectId && selectedUser ? removeUserFromProject() : projectId ? removeUsersFromProject() : (selectedUser ? deleteUser(selectedUser.id): deleteAllSelected()); closeDeletingPopup()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        <ng-container *ngIf="projectId">Remove {{selectedUser ? 'User' : 'Users'}} from project</ng-container>
        <ng-container *ngIf="!projectId">Delete {{selectedUser ? 'User' : 'Users'}}</ng-container>
      </button>
    </div>
  </div>
</div>

<app-user-profile-form *ngIf="userEditFormOpened" (close)="userEditFormOpened = false; selectedUser = undefined" (created)="userEditFormOpened = false; selectedUser = undefined; getUsersList()" [currentUser]="currentUser" [user]="selectedUser"></app-user-profile-form>
