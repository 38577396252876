import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {UserRole} from "../../../../../../../_models/user";
import {AreasService} from "../../../../../../../_services/areas.service";
import {NotificationService} from "../../../../../../../_services/notification.service";
import {AreaFormStore} from "../area-form.store";
import {SelectedArea} from "../../../types";
import {GlobalStore} from "../../../../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";

@Component({
  selector: 'app-files-area',
  templateUrl: './files-area.component.html',
  styleUrls: ['./files-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesAreaComponent implements OnDestroy {
  imageFormatsToRenderImage: string[] = ['jpeg', 'png', 'svg', 'webp', 'jpg'];
  private readonly destroy$ = new Subject<void>()

  @Input() selectedArea: SelectedArea;

  companyId: number;
  projectId: number;

  currentUser = this.globalStore.currentUser$;

  constructor(public areaFormStore: AreaFormStore,
              private areasService: AreasService,
              public notifService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.projectId = projectId);
  }

  renderImage(url: string) {
    let result = false;

    this.imageFormatsToRenderImage.forEach(e => {
      if (url.endsWith(e)) {
        result = true;
        return;
      }
    })
    return result;
  }

  deleteFile(commentId: number) {
      this.areasService.deleteAreaFile(this.companyId, this.projectId, this.selectedArea.floorId, this.selectedArea.unitId, this.selectedArea.roomId, this.selectedArea.area.id, commentId).subscribe(() => {
        this.notifService.successNotification('Changes have been saved')
        this.areaFormStore.deleteAllFilesFromComment(commentId);
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
