import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {MaterialPaymentStatus, MaterialStoredLocation} from "../_models/material";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaterialStoredLocationService {

  constructor(private http: HttpClient) { }

  getAllMaterialStoredLocations(companyId: number, projectId: number):Observable<MaterialStoredLocation[]> {
    return this.http.get<MaterialStoredLocation[]>(`${apiUrl}/company/${companyId}/project/${projectId}/material/storedLocation`, { withCredentials: true})
  }

  createStoredLocation(companyId: number, projectId: number, name: string) {
    return this.http.post(`${apiUrl}/company/${companyId}/project/${projectId}/material/storedLocation`, name,{withCredentials: true})
  }

  deleteStoredLocation(companyId: number, projectId: number, storedLocationId: number) {
    let params = new HttpParams();
    if (storedLocationId) params = params.set('id', storedLocationId);

    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/material/storedLocation`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }
}
