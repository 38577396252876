import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {apiUrl} from "../../environments/environment";
import {Project} from "../_models/project";
import {Page} from "../_models/page";
import {ProjectTable} from "../_models/project-table";
import {FloorDuplicateMenu} from "../_models/floor";
import {User} from "../_models/user";

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private http: HttpClient) {
  }

  getProjectListByParams(companyName: string, hideCompleted: boolean, clientName: string, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<Project>> {
    let params = new HttpParams().set('page', page);
    if (companyName) params = params.set('companyName', companyName);
    if (hideCompleted) params = params.set('hideCompleted', hideCompleted);
    if (clientName) params = params.set('clientName', clientName);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<Project>>(`${apiUrl}/project/list`, {params: params, withCredentials: true})
  }

  getProjectListByCompanyIdAndParams(companyId: number, hideCompleted: boolean, companyName: string, clientName: string, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<Project>> {
    let params = new HttpParams();
    if (companyName) params = params.set('companyName', companyName);
    if (hideCompleted) params = params.set('hideCompleted', hideCompleted);
    if (clientName) params = params.set('clientName', clientName);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<Project>>(`${apiUrl}/company/${companyId}/project/list`, {
      params: params,
      withCredentials: true
    })
  }

  getProjectShortList(companyId: number): Observable<Project[]> {
    return this.http.get<Project[]>(`${apiUrl}/company/${companyId}/project/short`, {withCredentials: true})
  }

  getAllProjectsShortList(): Observable<Project[]> {
    return this.http.get<Project[]>(`${apiUrl}/project/short`, {withCredentials: true})
  }

  getProjectById(projectId: number, companyId: number): Observable<Project> {
    return this.http.get<Project>(`${apiUrl}/company/${companyId}/project/${projectId}`, {withCredentials: true})
  }

  getProjectProgressById(projectId: number, companyId: number): Observable<number> {
    return this.http.get<number>(`${apiUrl}/company/${companyId}/project/${projectId}/progress`, {withCredentials: true})
  }

  createProject(project: Project, companyId: number): Observable<Project> {
    return this.http.post<Project>(`${apiUrl}/company/${companyId}/project`, project, {withCredentials: true})
  }

  createProjectKey(projectName: string): Observable<any> {
    return this.http.post(`${apiUrl}/project/key/generateKey`, projectName, {withCredentials: true})
  }

  validateProjectKey(projectCode: string): Observable<{error: string}> {
    let params = new HttpParams().append('projectKey', projectCode)
    return this.http.get<{error: string}>(`${apiUrl}/project/key/validate`, {withCredentials: true, params: params})
  }

  updateProject(project: Project, companyId: number): Observable<Project> {
    return this.http.put<Project>(`${apiUrl}/company/${companyId}/project`, project, {withCredentials: true})
  }

  deleteProject(companyId: number, projectIds: number[]) {
    let params = new HttpParams();
    projectIds.forEach(e => params = params.append('projectIds', e));

    return this.http.delete(`${apiUrl}/company/${companyId}/project`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }

  deleteProjectAsSuperAdmin(projectIds: number[]) {
    let params = new HttpParams();
    projectIds.forEach(e => params = params.append('projectIds', e));

    return this.http.delete(`${apiUrl}/project`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }


  assignEmployeesToProject(companyId: number, projectId: number, employeeIds: number[]) {
    return this.http.post(`${apiUrl}/company/${companyId}/project/${projectId}/employees`, employeeIds, {
      observe: "response",
      withCredentials: true
    })
  }

   removeEmployeeFromProject(companyId: number, projectId: number, employeeId: number) {
    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/employee`, {
      params: {employeeId: employeeId},
      observe: "response",
      withCredentials: true
    })
  }

  removeEmployeesFromProject(companyId: number, projectId: number, employeeIds: number[]) {
    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/employees`, {
      params: {employeeIds: employeeIds},
      observe: "response",
      withCredentials: true
    }
    )
  }

  getProjectProgressTable(companyId: number, projectId: number): Observable<ProjectTable> {
    return this.http.get<ProjectTable>(`${apiUrl}/company/${companyId}/project/${projectId}/table`, {withCredentials: true})
  }

  updateRoomsOrderProjectProgressTable(companyId: number, projectId: number, headMap: Map<string, number>, tableVersion: number) {
    return this.http.put<ProjectTable>(`${apiUrl}/company/${companyId}/project/${projectId}/table/rooms/order`, Object.fromEntries(headMap), {params: {tableVersion}, withCredentials: true})
  }

  updateAreasOrderProjectProgressTable(companyId: number, projectId: number, headMap: Map<string, number[]>, tableVersion: number) {
    return this.http.put<ProjectTable>(`${apiUrl}/company/${companyId}/project/${projectId}/table/areas/order`, Object.fromEntries(headMap), {params: {tableVersion}, withCredentials: true})
  }

  getUnitsListInProject(companyId: number, projectId: number) {
    return this.http.get<FloorDuplicateMenu[]>(`${apiUrl}/company/${companyId}/project/${projectId}/units/short`, {withCredentials: true})
  }

  getProjectMentionUsers(companyId: number, projectId: number, username: string): Observable<User[]> {
    return this.http.get<User[]>(`${apiUrl}/company/${companyId}/project/${projectId}/employee/mention`, {params: {username}, withCredentials: true})
  }
}
