<app-sub-header [title]="[(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN ? company?.name : '', 'Settings']"></app-sub-header>

<div class="settings-container">
  <div class="settings-wrapper" *ngIf="company">
    <div class="settings-wrapper__header">
      <p class="settings-wrapper__title">About Company</p>
      <button type="button" class="settings-wrapper__btn-edit" (click)="companyEditFormOpened = true">
        <svg class="edit-icon">
          <use spriteVersion iconName="edit-icon"></use>
        </svg>
        Edit
      </button>
    </div>

    <div class="table-details-wrapper">
      <ul class="table-details">
          <li class="table-details-info-row">
            <p class="table-details__title">ID</p>
            <p class="table-details__info">{{company.id}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Company Name</p>
            <p class="table-details__info">{{company.name}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Address</p>
            <p class="table-details__info">{{company.address1}}, {{company.address2}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">City</p>
            <p class="table-details__info">{{company.city}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">ZIP Code</p>
            <p class="table-details__info">{{company.zipCode}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">State</p>
            <p class="table-details__info">{{company.state}}</p>
          </li>

          <li class="table-details-info-row">
            <p class="table-details__title">Phone Number</p>
            <p class="table-details__info">{{company.phoneNumber | phonePrettify}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Email</p>
            <p class="table-details__info">{{company.email}}</p>
          </li>

          <li class="table-details-info-row">
            <p class="table-details__title">Plan</p>
            <p class="table-details__info">{{company.companyPlan.planName}}</p>
          </li>

          <li class="table-details-info-row">
            <p class="table-details__title">Users</p>
            <p class="table-details__info">{{company.usersCount}}/{{company.companyPlan.maxUsers}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Subdomain name</p>
            <p class="table-details__info">{{company.subdomain || '-'}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Type</p>
            <p class="table-details__info table-details__info--company-type">{{companyTypeByEnum.get(company.type)}}</p>
          </li>
      </ul>
    </div>
  </div>

  <div class="settings-wrapper" *ngIf="!company">
    <div class="settings-wrapper__header">
      <p class="settings-wrapper__title">About Company</p>
    </div>

    <div class="table-details-wrapper">
      <app-loader-profile></app-loader-profile>
    </div>
  </div>

  <button type="button" class="btn-delete" id="btnDeleteCompanyOpenModal" (click)="isOpenConfirmDeletingModal = true">
    <svg class="btn-delete__icon">
      <use spriteVersion iconName="trash-delete-icon"></use>
    </svg>
    Delete Company
  </button>
</div>

<div class="backdrop manual" [attr.show]="isOpenConfirmDeletingModal">
  <div class="modal-narrow" [enabled]="isOpenConfirmDeletingModal" (appOutsideClick)="isOpenConfirmDeletingModal = false">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="isOpenConfirmDeletingModal = false">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
        <p class="confirmDeleting-title">Delete the Company “{{company?.name}}”?</p>
        <p class="confirmDeleting-text">
          Delete an existing Company within the system. If deleted this company, will also be deleted:
        </p>
      <ul class="confirmDeleting-list">
        <li> - All created projects</li>
        <li> - All created workers and admins of the company</li>
        <li>- All created and assigned tasks</li>
      </ul>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" class="btn-cancel" id="btnCancel" (click)="isOpenConfirmDeletingModal = false">Cancel</button>
      <button type="submit" class="btn-delete" id="btnDelete" [disabled]="deletingBtnDisabled" (click)="deleteCompany()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete Company
      </button>
    </div>
  </div>
</div>
<app-company-form *ngIf="companyEditFormOpened" (close)="companyEditFormOpened = false" (created)="companyEditFormOpened = false; company = $event" [company]="company"></app-company-form>
