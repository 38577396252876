import {Injectable, OnDestroy} from "@angular/core";
import {ProjectCustomStatuses} from "../../../../../_models/project";
import {Floor, FloorDuplicateMenu} from "../../../../../_models/floor";
import {ComponentStore} from "@ngrx/component-store";
import {exhaustMap, Observable, of, Subject, Subscription, switchMap, takeUntil, tap} from "rxjs";
import {ProjectsService} from "../../../../../_services/projects.service";
import {ProjectAreaStatusesService} from "../../../../../_services/project-area-statuses.service";
import {FloorsService} from "../../../../../_services/floors.service";
import {Unit} from "../../../../../_models/unit";
import {Area} from "../../../../../_models/area";
import {Room} from "../../../../../_models/room";
import {TemplatesService} from "../../../../../_services/templates.service";
import {GlobalStore} from "../../../../../global.store";
import {SelectedArea, SelectedRoom} from "../types";
import {moveItemInArrayCopyAndUpdateVersion} from "../../../../../_util/utils";


export enum PopupOpened {
  NONE, ROOM_EDIT, ROOM_CREATE, CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE, TEMPLATE_EDIT, AREA_VIEW
}
export interface ProjectViewState {
  floorsShortList: Floor[] | null;
  floorDuplicateMenuList: FloorDuplicateMenu[] | null;
  roomTemplatesShortList: Room[] | null;
  currentFloor: Floor | null;
  floorInLoading: number | null;
  projectStatuses: ProjectCustomStatuses[] | null;
  statistics: Map<number, number> | null;
  isCdkDragDisabled: boolean | null;
  popupOpened: PopupOpened | null;
  selectedObject: any | null;
}

@Injectable()
export class ProjectViewStore extends ComponentStore<ProjectViewState> implements OnDestroy {
  private isNotDesktop: boolean;

  companyId: number;
  projectId: number;

  private readonly destroySubj$ = new Subject<void>()

  constructor(private roomTemplatesService: TemplatesService,
              private floorsService: FloorsService,
              private projectService: ProjectsService,
              readonly globalStore: GlobalStore,
              private projectAreaStatusesService: ProjectAreaStatusesService) {
    super({
      floorsShortList: null,
      floorDuplicateMenuList: null,
      roomTemplatesShortList: null,
      floorInLoading: null,
      currentFloor: null,
      projectStatuses: null,
      statistics: null,
      isCdkDragDisabled: false,
      popupOpened: PopupOpened.NONE,
      selectedObject: null
    });
    this.globalStore.isNotDesktop$.pipe(takeUntil(this.destroySubj$)).subscribe((isNotDesktop) => this.isNotDesktop = isNotDesktop);
    this.globalStore.companyId$.pipe(takeUntil(this.destroySubj$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroySubj$)).subscribe(projectId => this.projectId = projectId);
  }

  readonly floorsInLoading$ = this.select(
    (state) => state.floorInLoading ?? null,
  );

  readonly floorsShortList$ = this.select(
    (state) => state.floorsShortList ?? [],
  );

  readonly floorDuplicateMenuList$ = this.select(
    (state) => state.floorDuplicateMenuList ?? [],
  );

  readonly roomTemplatesShortList$ = this.select(
    (state) => state.roomTemplatesShortList ?? [],
  );

  readonly floorDuplicateMenuListAsUnits$ = this.select(
    (state) => {
      const result: Unit[] = [];
      state.floorDuplicateMenuList?.forEach(floor => Array.prototype.push.apply(result, floor.units));
      return result;
    },
  );

  readonly currentFloor$ = this.select(
    (state) => state.currentFloor ?? null,
  );

  readonly statistics$ = this.select(
    (state) => state.statistics ?? new Map(),
  );

  readonly projectStatuses$ = this.select(
    (state) => state.projectStatuses ?? [],
  );

  readonly isCdkDragDisabled$ = this.select(
    (state) => state.isCdkDragDisabled,
  );

  readonly popupOpened$ = this.select(
    (state) => state.popupOpened,
  );

  readonly selectedObject$ = this.select(
    (state) => state.selectedObject,
  );

  readonly loadProjectStatuses = this.effect((trigger$: Observable<void>) => trigger$.pipe(
    switchMap(() => this.projectAreaStatusesService.getProjectAreaStatuses(this.companyId, this.projectId).pipe(
      tap((projectStatuses) => {
        const currentState = this.get();

        const statistics: Map<number, number> = new Map<number, number>();

        if (currentState.currentFloor) {
          projectStatuses.forEach(statuse => statistics.set(statuse.id, 0));

          currentState.currentFloor.units.forEach(unit => {
            unit.rooms.forEach(room => {
              room.areas.forEach(area => statistics.set(area.status.id, statistics.get(area.status.id) + 1))
            })
          })
        }

        this.patchState({projectStatuses: projectStatuses, statistics: statistics});
      })
    ))
  ));

  readonly loadFloorsShortList = this.effect((trigger$: Observable<{ goToFirst: boolean }>) => trigger$.pipe(
    switchMap((goToFirst) => this.floorsService.getFloorsShortList(this.companyId, this.projectId).pipe(
      tap((floorsShortList) => {
        this.patchState({floorsShortList: floorsShortList});
        if (goToFirst) this.loadFloor(of({floorId: floorsShortList[0].id}));
      })
    ))
  ));

  readonly loadRoomTemplatesShortList = this.effect((trigger$: Observable<void>) => trigger$.pipe(
    switchMap(() => this.roomTemplatesService.getRoomTemplatesShortList(this.companyId, this.projectId).pipe(
      tap((roomTemplatesShortList) => {
        this.patchState({roomTemplatesShortList: roomTemplatesShortList});
      })
    ))
  ));

  readonly loadFloorDuplicateMenuList = this.effect((trigger$: Observable<void>) => trigger$.pipe(
    switchMap(() => this.projectService.getUnitsListInProject(this.companyId, this.projectId).pipe(
      tap((floorDuplicateMenuList) => {
        this.patchState({floorDuplicateMenuList: floorDuplicateMenuList});
      })
    ))
  ));

  readonly loadFloor = this.effect((trigger$: Observable<{ floorId: number }>) => trigger$.pipe(
    exhaustMap(({floorId}) => {
      this.patchState({floorInLoading: floorId});
      return this.floorsService.getFloorById(floorId, this.companyId, this.projectId).pipe(
        tap((floor) => {

          const currentState = this.get();

          const statistics: Map<number, number> = new Map<number, number>();

          if (currentState.projectStatuses) {
            currentState.projectStatuses.forEach(status => statistics.set(status.id, 0));

            floor.units.forEach(unit => {
              unit.rooms.forEach(room => {
                room.areas.forEach(area => statistics.set(area.status.id, statistics.get(area.status.id) + 1))
              })
            })
          }

          this.patchState({currentFloor: floor, statistics: statistics, floorInLoading: null});
        })
      )
    })
  ));

  readonly updateCurrentFloor = this.updater((state: ProjectViewState, floor: Floor) => {
    return {
      ...state,
      currentFloor: structuredClone(floor)
    };
  });

  readonly updateCurrentFloorNameAndVersion = this.updater((state: ProjectViewState, floor: Floor) => {
    const updatedFloorDuplicateMenuList = state.floorDuplicateMenuList.map(item => {
      if (item.name !== state.currentFloor.name) return item;
      item.name = floor.name;
      return item;
    });

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        version: floor.version,
        name: floor.name
      },
      floorDuplicateMenuList: updatedFloorDuplicateMenuList
    };
  });


  readonly reloadCurrentFloor = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      tap(() => {
        const state = this.get();
        return this.loadFloor(of({floorId: state.currentFloor.id}));
      })
    )
  );

  readonly updateSelectedObject = this.updater((state: ProjectViewState, selectedObjectInfo: {
    obj: any,
    isSelectedArea?: boolean
  }) => {
    let selectedAreaObject
    if (selectedObjectInfo.isSelectedArea) {
      selectedAreaObject = new SelectedArea();
      selectedAreaObject.area = selectedObjectInfo.obj.area;
      selectedAreaObject.floorId = selectedObjectInfo.obj.floorId;
      selectedAreaObject.floorName = selectedObjectInfo.obj.floorName;
      selectedAreaObject.unitId = selectedObjectInfo.obj.unitId;
      selectedAreaObject.unitName = selectedObjectInfo.obj.unitName;
      selectedAreaObject.roomId = selectedObjectInfo.obj.roomId;
      selectedAreaObject.roomName = selectedObjectInfo.obj.roomName;
    }
    return {
      ...state,
      selectedObject: selectedObjectInfo.isSelectedArea ? structuredClone(selectedAreaObject) : structuredClone(selectedObjectInfo.obj),
    };
  });


  readonly addRoom = this.updater((state: ProjectViewState, roomForAdd: SelectedRoom) => {
    const currentState = this.get();
    const statistics: Map<number, number> = new Map<number, number>();


    const updatedUnits = state.currentFloor.units.map(unit => {
      if (unit.id === roomForAdd.unitId) {
        unit.rooms.push(roomForAdd.room)
        return {...unit, rooms: [...unit.rooms]}
      }
      return unit
    })

    //update statuses statistics
    if (currentState.projectStatuses) {
      currentState.projectStatuses.forEach(status => statistics.set(status.id, 0));
      updatedUnits.map(unit => {
        unit.rooms.map(room => {
          room.areas.forEach(area => statistics.set(area.status.id, statistics.get(area.status.id) + 1))
        })
      })
    }

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      },
      statistics: statistics
    };
  });

  readonly deleteRoom = this.updater((state: ProjectViewState, payload: { unitId: number, roomId: number }) => {
    let updatedUnits = state.currentFloor.units.map(unit => {
      if (unit.id === payload.unitId) {
        return {
          ...unit,
          rooms: unit.rooms.filter(room => room.id !== payload.roomId)
        }
      }
      return unit
    })

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      }
    };
  });

  readonly updateRoom = this.updater((state: ProjectViewState, roomForUpdate: SelectedRoom) => {
    const currentState = this.get();
    const statistics: Map<number, number> = new Map<number, number>();

    let updatedUnits = state.currentFloor.units.map(unit => {
      if (unit.id === roomForUpdate.unitId) {
        return {
          ...unit,
          rooms: unit.rooms.map(room => {
            if (room.id === roomForUpdate.room.id) {
              return roomForUpdate.room
            }
            return room
          })
        }
      }
      return unit
    })

    //update statuses statistics
    if (currentState.projectStatuses) {
      currentState.projectStatuses.forEach(status => statistics.set(status.id, 0));
      updatedUnits.map(unit => {
        unit.rooms.map(room => {
          room.areas.forEach(area => statistics.set(area.status.id, statistics.get(area.status.id) + 1))
        })
      })
    }

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      },
      statistics: statistics
    };
  });


  readonly deleteStatusWithReplacement = this.updater((state: ProjectViewState, updatedStatusesInfo: {
    newStatus: ProjectCustomStatuses,
    deletedStatus: ProjectCustomStatuses
  }) => {
    const statistics = new Map(state.statistics);
    const updatedStatuses = [...state.projectStatuses].filter(floor => floor.id !== updatedStatusesInfo.deletedStatus.id);

    const updatedUnits = state.currentFloor.units.map(unit => ({
      ...unit,
      rooms: unit.rooms.map(room => ({
        ...room,
        areas: room.areas.map(area => {
          if (area.status.id === updatedStatusesInfo.deletedStatus.id) {
            statistics.set(updatedStatusesInfo.newStatus.id, statistics.get(updatedStatusesInfo.newStatus.id) + 1);
            const updatedArea = {...area}
            updatedArea.status = {...updatedStatusesInfo.newStatus}
            return updatedArea;
          }
          return area;
        })
      }))
    }));

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      },
      statistics: statistics,
      projectStatuses: updatedStatuses
    };
  })


  readonly updateArea = this.updater((state: ProjectViewState, updatedArea: Area) => {
    const statistics = new Map(state.statistics);

    const updatedUnits = state.currentFloor.units.map(unit => ({
      ...unit,
      rooms: unit.rooms.map(room => ({
        ...room,
        areas: room.areas.map(area => {
          if (area.id === updatedArea.id) {
            if(area.status.id !== updatedArea.status.id) {
              statistics.set(area.status.id, statistics.get(area.status.id) - 1);
              statistics.set(updatedArea.status.id, statistics.get(updatedArea.status.id) + 1);
            }
            return updatedArea;
          }
          return area;
        })
      }))
    }));

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      },
      statistics: statistics
    };
  });


  readonly updateUnitName = this.updater((state: ProjectViewState, payload: { unitId: number, unitName: string }) => {
    const updatedUnits = state.currentFloor.units.map(unit => {
      if (unit.id === payload.unitId) {
        unit.name = payload.unitName
        return unit
      } else return unit
    });

    const updatedFloorUnitDuplicateMenuList = state.floorDuplicateMenuList.map(floor => ({
      ...floor,
      units: floor.units.map(unit => {
        if (unit.id === payload.unitId) {
          unit.name = payload.unitName
          return unit
        } else return unit
      })
    }))

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      },
      floorDuplicateMenuList: updatedFloorUnitDuplicateMenuList,
    };
  })

  readonly deleteUnit = this.updater((state: ProjectViewState, unitId: number) => {
    const updatedStatistics = new Map(state.statistics);

    let targetUnit = state.currentFloor.units.find(unit => unit.id === unitId);

    targetUnit.rooms.forEach(room => {
      room.areas.forEach(area => {
        updatedStatistics.set(area.status.id, updatedStatistics.get(area.status.id) - 1);
      })
    })

    const updatedUnits = state.currentFloor.units.filter(unit => unit.id !== unitId);
    const updatedFloorUnitDuplicateMenuList = state.floorDuplicateMenuList.map(floor => ({
      ...floor,
      units: floor.units.filter(unit => unit.id !== unitId)
    }))

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: updatedUnits
      },
      floorDuplicateMenuList: updatedFloorUnitDuplicateMenuList,
      statistics: updatedStatistics
    };
  })

  readonly deleteTemplate = this.updater((state: ProjectViewState, templateId: number) => {
    const updatedTemplates = [...state.roomTemplatesShortList].filter(template => template.id !== templateId);

    return {
      ...state,
      roomTemplatesShortList: updatedTemplates
    }
  })

  readonly deleteFloor = this.updater((state: ProjectViewState, deletedFloor: Floor) => {
    const updatedFloors = [...state.floorsShortList].filter(floor => floor.id !== deletedFloor.id);
    const updatedFloorUnitDuplicateMenuList = state.floorDuplicateMenuList.filter(floor => floor.name !== deletedFloor.name)

    return {
      ...state,
      floorsShortList: updatedFloors,
      floorDuplicateMenuList: updatedFloorUnitDuplicateMenuList
    }
  })


  readonly addUnit = this.updater((state: ProjectViewState, unit: Unit) => {
    const currentState = this.get();
    const statistics: Map<number, number> = new Map<number, number>();


    const units = state.currentFloor.units;
    units.push(unit);

    //update statuses statistics
    if (currentState.projectStatuses) {
      currentState.projectStatuses.forEach(status => statistics.set(status.id, 0));
      units.map(u => {
        u.rooms.map(room => {
          room.areas.forEach(area => statistics.set(area.status.id, statistics.get(area.status.id) + 1))
        })
      })
    }

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: units
      },
      statistics: statistics
    };
  })

  readonly addFloor = this.updater((state: ProjectViewState, floor: Floor) => {
    const floors = [...state.floorsShortList];
    floors.push(floor);

    return {
      ...state,
      floorsShortList: floors
    };
  })

  readonly changeRoomOrder = this.updater((state: ProjectViewState, {unitId, newOrder, oldOrder}: {
    unitId: number,
    newOrder: number,
    oldOrder: number,
  }) => {
    if (oldOrder === newOrder) return state;
    let units = state.currentFloor.units;
    let unit = units.find(unit => unit.id === unitId);
    unit.rooms = moveItemInArrayCopyAndUpdateVersion(newOrder, oldOrder, unit.rooms);

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: units
      }
    };
  })

  readonly changeUnitOrder = this.updater((state: ProjectViewState, {newOrder, oldOrder}: {
    newOrder: number,
    oldOrder: number,
  }) => {

    if (oldOrder === newOrder) return state;

    return {
      ...state,
      currentFloor: {
        ...state.currentFloor,
        units: moveItemInArrayCopyAndUpdateVersion(newOrder, oldOrder, state.currentFloor.units)
      }
    };
  })

  readonly changeFloorOrder = this.updater((state: ProjectViewState, {newOrder, oldOrder}: {
    newOrder: number,
    oldOrder: number
  }) => {
    if (oldOrder === newOrder) return state;

    return {
      ...state,
      floorsShortList: moveItemInArrayCopyAndUpdateVersion(newOrder, oldOrder, state.floorsShortList)
    };
  })

  readonly updateIsCdkDragDisabled = this.updater((state: ProjectViewState, isDisabled: boolean) => {
    if (this.isNotDesktop) isDisabled = true;

    return {
      ...state,
      isCdkDragDisabled: isDisabled
    };
  })

  readonly updatePopupOpened = this.updater((state: ProjectViewState, popupOpened: PopupOpened) => {
    return {
      ...state,
      popupOpened: popupOpened
    };
  })

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroySubj$.next()
    this.destroySubj$.complete()
  }
}
