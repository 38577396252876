<app-sub-header [title]="['Companies']"></app-sub-header>
<app-filter-fields btnText="Create Company" searchPlaceholder="Search by ID, Name or State" [disabled]="isNoFilteringEnabled() && companiesPage?.empty" (openFormBtnClick)="companyFormOpened = true"
                   (inputChanged)="query = $event; getCompanyList()" [filtersAmount]="getFiltersActiveAmount()" (clearAllFilters)="clearFilters()"
                   (deleteSelectedItem)="deletingPopupOpened = true" (cancelSelectedItem)="multiSelectedCompanies = []; isMultiSelectAll = false" [selectedItemsAmount]="multiSelectedCompanies.length">
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Type:
    </label>
    <div class="ng-select-wrapper">
      <ng-select [(ngModel)]="selectedCompanyType"
                 notFoundText="No results matching"
                 [editableSearchTerm]="true"
                 (change)="getCompanyList()"
                 placeholder="Add Type">
        <ng-option *ngFor="let companyType of companyTypeByEnum | keyvalue" [value]="companyType.key">{{companyType.value}}</ng-option>
      </ng-select>
    </div>
  </div>
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by State:
    </label>
    <div class="ng-select-wrapper">
      <ng-select [items]="states"
                 [(ngModel)]="selectedState"
                 notFoundText="No results matching"
                 [editableSearchTerm]="true"
                 (change)="stateChanged();"
                 placeholder="Add State">
      </ng-select>
    </div>
  </div>
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by City:
    </label>
    <div class="ng-select-wrapper">
      <ng-select [items]="cities"
                 #citySelector [(ngModel)]="selectedCity"
                 [disabled]="!selectedState"
                 notFoundText="No results matching"
                 [editableSearchTerm]="true"
                 (change)="getCompanyList()"
                 placeholder="Add City">
      </ng-select>
    </div>
  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>

<ng-scrollbar *ngIf="companiesPage && !isLoading" [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.companiesPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.companiesPage.totalElements > 20" class="table-wrapper-with-scroll">
      <ul class="table-companies" *ngIf="!companiesPage.empty; else emptyList">
        <ul class="table-companies__header-row">
          <li class="table-companies__multiselected" *ngIf="multiSelectEnabled && multiSelectedCompanies.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>

          <li class="table-companies__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedCompanies.length > 0">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['id']">
                ID
                <button class="table-sort-icon-btn" (click)="onSortBy('id')" [style.opacity]="sorting['id'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['id'] === SortType.DESC" *ngIf="sorting['id']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedCompanies.length > 0">
              <span class="table-title-wrapper"  [attr.shouldBlack]="sorting['name']">
                Company Name
                <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>
                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__type-title">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['type']">
                Type
                <button class="table-sort-icon-btn" (click)="onSortBy('type')" [style.opacity]="sorting['type'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['type'] === SortType.DESC" *ngIf="sorting['type']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__state-title">State</li>
          <li class="table-companies__email-title">Email</li>
          <li class="table-companies__phone-title">Phone Number</li>
          <li class="table-companies__plan-title">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['companyPlan']">
                Plan
                <button class="table-sort-icon-btn" (click)="onSortBy('companyPlan')" [style.opacity]="sorting['companyPlan'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['companyPlan'] === SortType.DESC" *ngIf="sorting['companyPlan']; else grayicon">
                     <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__user-title">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['usersCount']">
                Users
                <button class="table-sort-icon-btn" (click)="onSortBy('usersCount')" [style.opacity]="sorting['usersCount'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['usersCount'] === SortType.DESC" *ngIf="sorting['usersCount']; else grayicon">
                     <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__subdomain-title no-right-border">Subdomain name</li>
          <li class="table-companies__actions no-right-border fixed-column"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let company of companiesPage.content; index as i" [id]="company.id" class="table-companies__row" [attr.multiselected]="multiSelectedCompanies.includes(company.id)" [ngStyle]="multiSelectedCompanies.includes(company.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="company-id multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedCompanies.length > 0">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedCompanies.includes(company.id)" (change)="toggleCheck(company.id)" [id]="'selectItemCheckbox-' + i" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="company-id fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedCompanies.length > 0" [ngStyle]="multiSelectedCompanies.includes(company.id) ? {'background-color': '#F7F7F8'} : {}">
                <span class="item-code-way">
                    {{company.id}}
                </span>
              </li>
              <li class="company-name fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedCompanies.length > 0" [routerLink]="['/system/company', company.id]" [ngStyle]="multiSelectedCompanies.includes(company.id) ? {'background-color': '#F7F7F8'} : {}">
                <ng-template [nestedEllipsis]>
                  <span class="ellipsis-text" [title]="company.name"> {{company.name}}</span>
                </ng-template>
              </li>
              <li class="company-type-item"> <span class="table-companies__type">{{companyTypeByEnum.get(company.type)}}</span> </li>
              <li class="company-state-item">{{company.state}}</li>
              <li class="company-email-item"><span class="company-email-wrapper">{{company.email}}</span></li>
              <li class="company-phone-item">{{company.phoneNumber | phonePrettify}}</li>
              <li class="company-plan-item">{{company.companyPlan.planName}}</li>
              <li class="company-users-item">{{company.usersCount}}/{{company.companyPlan.maxUsers}}</li>
              <li class="company-subdomain-item">
                <ng-template [nestedEllipsis]>
                  <span class="ellipsis-text">{{company.subdomain || '-' }}</span>
                </ng-template>
              </li>
              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu" [attr.index]="i" #companyDots (click)="companyDots.toggleAttribute('editMenuOpen'); $event.stopPropagation()" [enabled]="companyDots.hasAttribute('editMenuOpen')" (appOutsideClick)="companyDots.removeAttribute('editMenuOpen')">
                <ul class="editMenuList" #editMenuList (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'" [@fadeIn] *ngIf="companyDots.hasAttribute('editMenuOpen')" [attr.dropDownShowTop]="showDropDownTop ? 'top' : 'bottom'">
                  <li class="editMenuList__item" id="selectItem"  (click)="toggleCheck(company.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li class="editMenuList__item" id="editItem" (click)="getCompanyById(company.id); companyFormOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>
                  <li class="editMenuList__item" id="deleteItem" (click)="selectedCompany = company; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <app-pagination *ngIf="this.companiesPage.totalElements > 20 && (isMobile | async)" [isLoading]="!companiesPage" [paginationInfo]="this.companiesPage" [showAmountForMobile]="pageSizeOnMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </ul>

    </div>
  </div>
</ng-scrollbar>

<div *ngIf="!companiesPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>

<app-pagination *ngIf="this.companiesPage?.totalElements > 20 && !(isMobile | async)" [isLoading]="!companiesPage" [showAmountForDesk]="pageSizeOnDesktop" [paginationInfo]="this.companiesPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>

<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled()  && companiesPage.empty; else notFound">The list of companies is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Companies found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

<div class="backdrop manual" [attr.show]="deletingPopupOpened">
  <div class="modal-narrow" [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="selectedCompany">Delete the Company “{{selectedCompany.name}}”?</p>
      <p class="confirmDeleting-title" *ngIf="!selectedCompany">Delete All Companies?</p>
      <p class="confirmDeleting-text">
        Delete {{selectedCompany ? 'an existing Company' : 'existing companies'}} within the system. If delete
        {{selectedCompany ? 'this company' : 'these companies'}}, will also be deleted:
      </p>
      <ul class="confirmDeleting-list">
        <li> - All created projects</li>
        <li> - All created workers and admins of {{selectedCompany ? 'the company' : 'these companies'}}</li>
        <li>- All created and assigned tasks</li>
      </ul>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" class="btn-cancel" id="btnCancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" class="btn-delete" id="btnDelete" [disabled]="deletingBtnDisabled" (click)="selectedCompany ? deleteCompany() : deleteSelected(); closeDeletingPopup()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{selectedCompany ? 'Company' : 'Companies'}}
      </button>
    </div>
  </div>
</div>

<app-company-form *ngIf="companyFormOpened" (close)="companyFormOpened = false; selectedCompany = undefined" (created)="companyFormOpened = false; selectedCompany = undefined; getCompanyList()" [company]="selectedCompany"></app-company-form>
