<app-sub-header [style.width]="selectedTab === 'Project Progress' ? '100%' : '100%'" [title]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN  ? [companyName, project?.name, selectedTab] : [project?.name, selectedTab]"></app-sub-header>

<div class="tab-list-with-status-project-wrapper" [attr.isView]="selectedTab === 'View' ? 'true' : 'false'">
  <ul class="tab-list" #scrollContainer >
      <li class="tab-list__item" id="0" #tabItem (click)="scrollToTab(0)">
          <a class="tab-list__link" id="viewTab" [routerLink]="['view']" routerLinkActive="active-tab">View</a>
      </li>
      <li class="tab-list__item" id="1" #tabItem (click)="scrollToTab(1)">
          <a class="tab-list__link" id="progressTab" [routerLink]="['progress']" routerLinkActive="active-tab" >Project Progress</a>
      </li>
      <li class="tab-list__item" id="2" #tabItem (click)="scrollToTab(2)">
          <a class="tab-list__link" id="tasksTab" [routerLink]="['tasks']" routerLinkActive="active-tab">Tasks</a>
      </li>
      <li class="tab-list__item" id="3" #tabItem (click)="scrollToTab(3)">
          <a class="tab-list__link" id='usersTab' [routerLink]="['users']"  routerLinkActive="active-tab">Users</a>
      </li>
    <li class="tab-list__item" id="4" #tabItem (click)="scrollToTab(4)">
      <a class="tab-list__link" id='activityTab' [routerLink]="['activity']"  routerLinkActive="active-tab">Activity</a>
    </li>
    <li class="tab-list__item" id="5" #tabItem (click)="scrollToTab(5)">
      <a class="tab-list__link" id='materialsTab' [routerLink]="['materials']"  routerLinkActive="active-tab">Materials</a>
    </li>
      <li class="tab-list__item" id="6" #tabItem (click)="scrollToTab(6)">
          <a class="tab-list__link" id="settingsTab" [routerLink]="['settings']" routerLinkActive="active-tab">Settings</a>
      </li>
  </ul>


  <div class="project-status-wrapper" [attr.isActivity]="selectedTab === 'Activity'" *ngIf="project?.id">
    Project Progress:
    <div class="status-bar-with-percent-wrapper">
      <div class="status-bar-colored" [attr.status]="project.status" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + project.status + '%, #F0F2F6 ' + project.status + '%), #F0F2F6 100%'"></div>
      <span>{{project.status}} % </span>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
