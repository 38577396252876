import {
  Component, OnDestroy, OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";
import {Project} from "../../../../_models/project";
import {ProjectsService} from "../../../../_services/projects.service";
import {User, UserRole} from "../../../../_models/user";
import {SortType} from "src/app/config/constants";
import {CompaniesService} from "../../../../_services/companies.service";
import {NgSelectComponent} from "@ng-select/ng-select";
import {Page} from "../../../../_models/page";
import {NotificationService} from "../../../../_services/notification.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {GlobalStore} from "../../../../global.store";
import {Subject, take, takeUntil} from "rxjs";

const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [animate(300)])
]);
@Component({
  selector: 'app-companies',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [fadeIn]
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @ViewChildren(NgSelectComponent) ngSelectComponent: QueryList<NgSelectComponent>;
  currentUser: User;
  projectFormOpened = false;
  projectsPage: Page<Project>;
  selectedProject: Project;
  isLoading = false;

  sorting: any = {};
  query: string;
  hideCompleted = false;
  pageNumber = 0;
  pageSizeDesktop = 20;
  pageSizeMobile: number;

  clientsList: string[] = [];
  companiesList: string[] = [];

  selectedClientName: string;
  selectedProjectCompanyName: string;

  multiSelectEnabled = false;
  deletingPopupOpened = false;
  deletingBtnDisabled = false;
  multiSelectedProjects: number[] = [];
  isMobile = this.globalStore.isMobile$;
  isMultiSelectAll = false;

  isIOS = this.globalStore.isIOS$;

  companyId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private projectService: ProjectsService,
              private companyService: CompaniesService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user: User) => this.currentUser = user);
  }

  ngOnInit() {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => {
      this.companyId = companyId;
      this.getProjectsList();
      this.updateFiltersData();
    });
  }

  updateFiltersData() {
    if (this.companyId) {
      this.companyService.getCompanyClientNames(this.companyId).subscribe((data) => this.clientsList = data);
    } else {
      this.companyService.getAllClientNames().subscribe((data) => this.clientsList = data);
      this.companyService.getShortCompanyList().subscribe(data => this.companiesList = data.map(e => e.name))
    }
  }

  getProjectsList() {
    this.isLoading = true;
    this.multiSelectedProjects = [];
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    if (this.companyId) this.projectService.getProjectListByCompanyIdAndParams(this.companyId, this.hideCompleted, this.selectedProjectCompanyName, this.selectedClientName, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeDesktop).subscribe((data) => this.checkPageResults(data))
    else this.projectService.getProjectListByParams(this.selectedProjectCompanyName, this.hideCompleted, this.selectedClientName, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeDesktop).subscribe((data) => this.checkPageResults(data))
  }

  checkPageResults(page: Page<Project>) {
    if (page.totalPages > 0 && this.pageNumber + 1 > page.totalPages) {
      this.pageNumber = page.totalPages - 1;
      this.getProjectsList();
    } else {
      this.projectsPage = page;
      this.isLoading = false;
    }
  }

  changeShowNumber(amount: number) {
    this.pageSizeDesktop = amount;
    this.pageSizeMobile = amount;
    this.pageNumber = 0;
    this.getProjectsList();
  }
  changeCurrentPage(newPage: number) {
    this.pageNumber = newPage;
    this.getProjectsList();
    this.isMultiSelectAll = false;
  }

  getProjectById(projectId: number, companyId: number) {
    this.projectService.getProjectById(projectId, companyId).subscribe((project) => this.selectedProject = project);
  }

  clearFilters() {
    this.ngSelectComponent.forEach(e => e.handleClearClick());
  }

  toggleCheckAll() {
    this.multiSelectedProjects = [];
    this.isMultiSelectAll = !this.isMultiSelectAll;
    if (this.isMultiSelectAll) {
      this.projectsPage.content.map(project => this.multiSelectedProjects.push(project.id))
      this.isMultiSelectAll = true;
    }
  }

  toggleCheck(projectId: number) {
    if (this.multiSelectedProjects.includes(projectId)) this.multiSelectedProjects.splice(this.multiSelectedProjects.indexOf(projectId), 1);
    else this.multiSelectedProjects.push(projectId);

    this.multiSelectEnabled = this.multiSelectedProjects.length > 0;
  }

  deleteProject(projectId: number, companyId: number) {
    this.deletingBtnDisabled = true;
    this.projectService.deleteProject(companyId, [projectId]).subscribe(() => {
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
      this.getProjectsList();
      if (this.companyId) this.globalStore.deleteProjectsFromCompany([projectId]);
    }, error => this.deletingBtnDisabled = false)
  }

  deleteSelected() {
    this.deletingBtnDisabled = true;
    if (this.currentUser.role === 'ROLE_SUPERADMIN') this.projectService.deleteProjectAsSuperAdmin(this.multiSelectedProjects).subscribe(() => this.deletedSuccessfully(), error => this.deletingBtnDisabled = false)
    else this.projectService.deleteProject(this.companyId, this.multiSelectedProjects).subscribe(() => this.deletedSuccessfully(), error => this.deletingBtnDisabled = false)
  }

  deletedSuccessfully() {
    this.getProjectsList();
    this.deletingPopupOpened = false;
    this.notifService.successNotification('Changes have been saved');
    this.deletingBtnDisabled = false;
    if (this.companyId) this.globalStore.deleteProjectsFromCompany(this.multiSelectedProjects);
  }

  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getProjectsList();
  }

  isNoFilteringEnabled() {
    return this.getFiltersActiveAmount() === 0 && !this.hideCompleted && (!this.query || this.query.length === 0);
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedProjectCompanyName) result++;
    if (this.selectedClientName) result++;

    return result;
  }

  closeDeletingPopup() {
    this.deletingPopupOpened = false;
    setTimeout(this.selectedProject = undefined, 250);
  }

  projectCreated(createdProject: Project) {
    this.projectFormOpened = false;
    this.selectedProject = undefined;
    this.getProjectsList();
    if (this.companyId) this.globalStore.addProject(createdProject);
  }

  projectUpdated(updatedProject: Project) {
    this.projectFormOpened = false;
    this.selectedProject = undefined;
    this.getProjectsList();
    if (this.companyId) this.globalStore.updateProject(updatedProject);
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly SortType = SortType;
    protected readonly UserRole = UserRole;
}





