import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {InviteUser, User, UserRole} from "../_models/user";
import {map, Observable} from "rxjs";
import {Page} from "../_models/page";
import {apiUrl} from "../../environments/environment";
import {Material, OrderStatus, SubmittalStatus} from "../_models/material";

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {

  constructor(private http: HttpClient) { }

  getAllMaterialsListByParams(companyId: number, projectId: number,
                              submittalStatus: SubmittalStatus,
                              orderStatus: OrderStatus,
                              paymentStatusId: number,
                              query: string, sortField: string,
                              sortDirection: string, page: number, pageSize: number):Observable<Page<Material>> {
    let params = new HttpParams().set('page', page);
    if (submittalStatus) params = params.set('submittalStatus', submittalStatus);
    if (orderStatus) params = params.set('orderStatus', orderStatus);
    if (paymentStatusId) params = params.set('paymentStatusId', paymentStatusId);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (pageSize) params = params.set('pageSize', pageSize);
    return this.http.get<Page<Material>>(`${apiUrl}/company/${companyId}/project/${projectId}/material/list`, {params: params, withCredentials: true})
  }
  createMaterial(companyId: number, projectId: number, material: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${apiUrl}/company/${companyId}/project/${projectId}/material`, material,{headers: headers, observe: "response", withCredentials: true})
  }

  updateMaterial(companyId: number, projectId: number, material: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/material`, material,{headers: headers, observe: "response", withCredentials: true})
  }

  updateSubmittalStatus(companyId: number, projectId: number, materialId: number, status: SubmittalStatus) {
    let params = new HttpParams().set('status', status);
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/material/${materialId}/submittalStatus`, null,{params: params, withCredentials: true})
  }

  updateOrderStatus(companyId: number, projectId: number, materialId: number, status: OrderStatus) {
    let params = new HttpParams().set('status', status);
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/material/${materialId}/orderStatus`, null,{params: params, withCredentials: true})
  }

  deleteMaterial(companyId: number, projectId: number, materialIds: number[]) {
    let params = new HttpParams();
    materialIds.forEach(e => params = params.append('ids', e));

    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/material`, {
        params: params,
        observe: "response",
        withCredentials: true
      })
  }
}
