import {
  Component,
  HostListener, OnDestroy,
} from '@angular/core';
import {ProjectsService} from "../../../../../_services/projects.service";
import {TableHeadRoom} from "../../../../../_models/project-table";
import {Area} from "../../../../../_models/area";
import {AreasService} from "../../../../../_services/areas.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {AreaClickPayload, SelectedArea} from '../types';
import {ProjectProgressStore} from "./project-progress.store";
import {waitForElm} from "../../../../../_util/utils";
import {GlobalStore} from "../../../../../global.store";
import {filter, Subject, take, takeUntil} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-project-progress',
  templateUrl: './project-progress.component.html',
  styleUrls: ['./project-progress.component.scss'],
  providers: [ProjectProgressStore]
})

export class ProjectProgressComponent implements OnDestroy{
  amountMocksHorizontal: number = 0;
  amountMocksVertical: number = 0;
  selectedArea: SelectedArea;
  isLoading = true;
  roomOrderingMode = false;
  areaOrderingMode = false;
  tableHead: TableHeadRoom[] = [];

  calculateMocksByTimeout: any;

  roomNamesWidthMap: Map<string, number> = new Map();
  areasTableOrderMap:  Map<string, number[]> = new Map();

  tableVersion: number;

  private readonly destroy$ = new Subject<void>()

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.calculateMocksByTimeout) clearTimeout(this.calculateMocksByTimeout);
    this.calculateMocksByTimeout = setTimeout(() => this.calculateMocks(), 300);
  }

  constructor(private projectsService: ProjectsService,
              private areaService: AreasService,
              private notifService: NotificationService,
              public projectStore: ProjectProgressStore,
              readonly globalStore: GlobalStore) {
    this.projectStore.loadProjectStatuses();
    this.projectStore.projectStatuses$.pipe(filter(statuses => statuses?.length > 0), take(1)).subscribe((data) => {
      this.projectStore.loadProjectTable();
      this.projectStore.currentProjectTable$.pipe(filter(table => !!table), take(1)).subscribe(() => this.isLoading = false);
      this.projectStore.projectTableVersion$.pipe(takeUntil(this.destroy$)).subscribe((version) => this.tableVersion = version);
      this.projectStore.tableHead$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (data.length === 0) return;
        this.tableHead = data;
        waitForElm('.room-name').then(() => {
          document.querySelectorAll('.room .room-name').forEach(el => {
            this.roomNamesWidthMap.set((el as HTMLElement).title, ((el as HTMLElement).parentElement.querySelector('.room-areas') as HTMLElement).offsetWidth);
          });
          this.calculateMocks();
        });
      });
    })
    this.projectStore.areasTableOrderMap$.pipe(takeUntil(this.destroy$)).subscribe(data => this.areasTableOrderMap = data)
  }

  startOrderChanging(orderType: string) {
    if (orderType === 'rooms') this.roomOrderingMode = true;
    else if (orderType === 'areas') {
      this.areaOrderingMode = true
      this.projectStore.createAreasTableOrderMap(this.tableHead)
    }
    this.projectStore.toggleUseTableCopy(true);
  }

  cancelOrderChanging(orderType: string) {
    if (orderType === 'rooms') this.roomOrderingMode = false;
    else if (orderType === 'areas') this.areaOrderingMode = false;
    this.projectStore.toggleUseTableCopy(false);
  }

  saveOrder() {
    let roomOrderMap = new Map();

    this.isLoading = true;
    if(this.roomOrderingMode) {
      this.tableHead.forEach((room, i) => roomOrderMap.set(room.name, i))
      this.projectsService.updateRoomsOrderProjectProgressTable(this.projectStore.companyId, this.projectStore.projectId, roomOrderMap, this.tableVersion).subscribe(() => {
        this.isLoading = false;
        this.roomOrderingMode = false;
        this.projectStore.applyTableCopyAsMain();
        this.projectStore.incrementProjectTableVersion();
        this.notifService.successNotification('Changes have been saved')
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.roomOrderingMode = false;
      })
    }
    else {
      this.projectsService.updateAreasOrderProjectProgressTable(this.projectStore.companyId, this.projectStore.projectId, this.areasTableOrderMap, this.tableVersion).subscribe(() => {
        this.isLoading = false;
        this.areaOrderingMode = false;
        this.projectStore.applyTableCopyAsMain();
        this.projectStore.incrementProjectTableVersion();
        this.notifService.successNotification('Changes have been saved')
      }, (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.areaOrderingMode = false;
      })
    }
  }

  calculateMocks() {
    this.amountMocksVertical = 0;
    this.amountMocksHorizontal = 0;

    setTimeout(() => {
      const tableWrapper = document.querySelector('.table-progress-wrapper');
      const tableWrapperInner = document.querySelector('.table-progress-wrapper__inner');

      const floorsList = document.querySelector('.floors-list');

      const diff = tableWrapper.clientWidth - tableWrapperInner.clientWidth;
      const diffVertical = tableWrapper.clientHeight - floorsList.clientHeight - 120;
      this.amountMocksHorizontal = Math.max(Math.floor(diff / 75), 0);
      this.amountMocksVertical = Math.max(Math.floor(diffVertical > 100 ? diffVertical / 100 : 0));
    })
  }

  updateArea(selectedAreaInfo: SelectedArea) {
    this.projectStore.updateArea(selectedAreaInfo.area);
    this.selectedArea = this.createSelectedArea(
      selectedAreaInfo.area,
      selectedAreaInfo.floorId,
      selectedAreaInfo.floorName,
      selectedAreaInfo.unitId,
      selectedAreaInfo.unitName,
      selectedAreaInfo.roomId,
      selectedAreaInfo.roomName
    );
    this.globalStore.loadProjectProgress()
  }

  createSelectedArea(area: Area, floorId: number, floorName: string,  unitId: number, unitName: string, roomId: number, roomName: string) {
    let result = new SelectedArea();
    result.area = {...area};
    result.floorId = floorId;
    result.floorName = floorName;
    result.unitId = unitId;
    result.unitName = unitName;
    result.roomId = roomId;
    result.roomName = roomName;
    return result;
  }

  openAreaModal({area, floor, unit, room}: AreaClickPayload) {
    this.areaService.getAreaById(this.projectStore.companyId, this.projectStore.projectId, floor.id, unit.id, room.id, area.id).subscribe((data) => {
      this.selectedArea = this.createSelectedArea(data, floor.id, floor.name, unit.id, unit.name, room.id, room.name);
    })
  }


  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
