import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  OrderStatus,
  SubmittalStatus
} from "../../../../../../../_models/material";
import {originalOrder} from "../../../../../../../_util/utils";
import {NG_VALUE_ACCESSOR} from "@angular/forms";


@Component({
  selector: 'app-material-custom-selector-with-colored-options',
  templateUrl: './material-custom-selector-with-colored-options.component.html',
  styleUrls: ['./material-custom-selector-with-colored-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialCustomSelectorWithColoredOptionsComponent),
      multi: true
    }
  ]
})
export class MaterialCustomSelectorWithColoredOptionsComponent {
  @Input() isClearFieldBtn = false;
  @Input()
  set statusesMap(value: Map<SubmittalStatus | OrderStatus, string>) {
    if(value) {
      this._statusesMap = new Map(value);
      this._statusesMap.forEach((value, key) => this.statusFromKeysList.push(key.toString()))
    }
  }
  get statusesMap() {
    return this._statusesMap
  }
  @Input()
  set selectedOption(obj: {value: SubmittalStatus | OrderStatus, isPlaceholder: boolean}) {
    let {value, isPlaceholder} = obj
    this._selectedOption = {value, isPlaceholder}
    if(value && !isPlaceholder) {
      this.isBtnPlaceholder = false
    } else {
      this.isBtnPlaceholder = true
    }
  }

  get selectedOption() {
    return this._selectedOption;
  }
  @Output() changeEl: EventEmitter<any> = new EventEmitter<any>()
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  constructor() {
  }


  private _statusesMap = new Map<SubmittalStatus | OrderStatus, string>()
  private _selectedOption: {value: SubmittalStatus | OrderStatus, isPlaceholder: boolean};
  isBtnPlaceholder = false;
  statusFromKeysList: string[] = [];

  writeValue(value: SubmittalStatus | OrderStatus ): void {
    if (!value) return;

    this.selectedOption.value = value;
    this.selectedOption.isPlaceholder = false
  }

  onChange: any = () => {
    this.isBtnPlaceholder = false
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  clearField() {
    this.selectedOption.value = null
    this.onChange(null);
    this.changeEl.emit(null);
    this.isBtnPlaceholder = true
  }


  closeSelect() {
    this.close.emit()
  }

  protected readonly originalOrder = originalOrder;
}
