import {User} from "./user";
import {TaskFile} from "./task";

export class AreaComment {
  id: number;
  timestamp: number;
  author: User;
  html: any;
  safeHtml: any;
  files: TaskFile[] = [];
}
