import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output} from '@angular/core';
import countriesListJson from '../../../../../assets/data/countries_codes_with_flags.json'
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Country} from "../../../../_models/company";
import {User} from "../../../../_models/user";
import {Subject, takeUntil} from "rxjs";
import {GlobalStore} from "../../../../global.store";

@Component({
  selector: 'app-countries-code-select',
  templateUrl: './countries-code-select.component.html',
  styleUrls: ['./countries-code-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountriesCodeSelectComponent),
      multi: true
    }
  ]
})
export class CountriesCodeSelectComponent implements OnDestroy{
  @Output() changeCountryCode: EventEmitter<any> = new EventEmitter<any>()
  @Input() isError: boolean = false;
  @Input()
    set countryCode(value: string) {
      if(value) {
        this.selectedCountryDialCode = value
        this.selectedCountry = this.findCurrentCountry(this.selectedCountryDialCode, null)
      }
  }

  countriesList: Country[] = [...countriesListJson];
  selectedCountryDialCode: string;
  selectedCountry: Country;
  userCurrentCountry: Country;
  private readonly destroy$ = new Subject<void>()

  constructor(private globalStore: GlobalStore) {
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      if(user.countryCode) this.userCurrentCountry = this.findCurrentCountry(user.countryCode,null)
      else this.userCurrentCountry = this.findCurrentCountry(null, user.currentCountryCode)
      this.selectedCountry = this.userCurrentCountry
    });
  }

  findCurrentCountry(countryDialCode: string | null, countryCode: string | null) {
    if(countryCode) return this.countriesList.find(country => country.code === countryCode)
    else return this.countriesList.find(country => country.dial_code === countryDialCode)
  }

  writeValue(value: string ): void {
    if (!value) return;

    this.selectedCountryDialCode = value;
  }

  onChange: any = () => {};
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  changeOption(country: Country) {
    this.selectedCountry = country;
    this.selectedCountryDialCode = country.dial_code;
    this.changeCountryCode.emit(country.dial_code);
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
