import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Unit} from "../../../../../_models/unit";
import {Floor, FloorDuplicateMenu} from "../../../../../_models/floor";
import {CdkDragDrop, CdkDropList} from '@angular/cdk/drag-drop';
import {UnitsService} from "../../../../../_services/units.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {minLengthValidator, requiredValidator} from "../../../../../_util/validators";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {filter, of, Subject, Subscription, take, takeUntil} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UserRole} from "../../../../../_models/user";
import {Room} from "../../../../../_models/room";
import {TemplatesService} from "../../../../../_services/templates.service";
import {PopupOpened, ProjectViewStore} from "./project-view.store";
import {GlobalStore} from "../../../../../global.store";
import {waitForElm} from "../../../../../_util/utils";
import {modalAnimation} from "../../../../../config/constants";
import {AreasService} from "../../../../../_services/areas.service";
import {NgScrollbar} from "ngx-scrollbar";
import {SelectedArea} from "../types";


@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss'],
  animations: [modalAnimation],
  providers: [ProjectViewStore]
})
export class ProjectViewComponent implements OnInit, OnDestroy {
  @ViewChild('unitsList') unitsList: ElementRef;
  @ViewChild('floorsDuplicateUnitsList') floorsDuplicateUnitsList: ElementRef;
  @ViewChildren('unitsDuplicateList') unitsDuplicateList: QueryList<ElementRef>;
  @ViewChild('addUnitBtn') addUnitBtn: ElementRef;
  @ViewChild('ngScrollbar') ngScrollbar: NgScrollbar;
  @ViewChild('cdkDropList') cdkDropList: CdkDropList;

  private readonly destroy$ = new Subject<void>()

  createUnitForm: FormGroup = this.fb.group({
    name: [null, [minLengthValidator('FLOOR_UNIT_NAME'), requiredValidator()]]
  })

  multipleDuplicateListWrapperTopCoordinate: number;
  unitsDuplicateListTopPosition: number;
  isFloorDuplicateHover = false;
  previousUnitsListRef: ElementRef;

  floorDuplicateSelected: FloorDuplicateMenu;

  deletingPopup:'none' | 'template' | 'unit' = 'none';
  deletingBtnDisabled = false;
  templateToDelete: Room;
  unitToDelete: Unit;

  currentFloor: Floor;
  floorDuplicateList: FloorDuplicateMenu[] = [];
  floorDuplicateListAsUnits: Unit[] = [];

  isNotDesktop = false;

  isAddUnitMenuOpen = false;
  isAddUnitFormOpen = false;

  constructor(readonly projectViewStore: ProjectViewStore,
              private unitsService: UnitsService,
              private fb: FormBuilder,
              private notifService: NotificationService,
              private activatedRoute: ActivatedRoute,
              public router: Router,
              readonly globalStore: GlobalStore,
              readonly roomTemplatesService: TemplatesService,
              private areaService: AreasService) {
    this.globalStore.isNotDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isNotDesktop) => {
      this.isNotDesktop = isNotDesktop;
      this.projectViewStore.updateIsCdkDragDisabled(isNotDesktop);
    });
  }

  ngOnInit() {
    this.projectViewStore.currentFloor$.subscribe((data) => this.currentFloor = data);
    this.projectViewStore.floorDuplicateMenuList$.subscribe((data) => this.floorDuplicateList = data);
    this.projectViewStore.floorDuplicateMenuListAsUnits$.subscribe((data) => this.floorDuplicateListAsUnits = data);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => {
      if (projectId) {
        this.projectViewStore.loadProjectStatuses();
        this.projectViewStore.loadFloorDuplicateMenuList();
        this.projectViewStore.loadRoomTemplatesShortList();
      }
    })

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.handleChangeQueryParamsFromUrl(params)
      });
  }

  openDeletingPopup(event: { type: 'none' | 'template' | 'unit', template: Room | null, unit: Unit | null }) {
    this.deletingPopup = event.type;
    if(this.deletingPopup === 'template') this.templateToDelete = event.template
    else this.unitToDelete = event.unit
  }

  handleChangeQueryParamsFromUrl(qp: any) {
    const currentProjectId = Number(this.activatedRoute.parent.snapshot.params['projectId']);

    this.globalStore.projectId$.pipe(filter(projectId => projectId === currentProjectId), take(1)).subscribe(projectId => {
      if (qp['params']) {
        const paramsArray = qp['params'].split('_');
        const floorId = Number(paramsArray[0]);

        if (this.currentFloor?.id !== floorId) {
          this.projectViewStore.currentFloor$.pipe(filter(floor => floor?.id === floorId), take(1)).subscribe(() => this.getAreaInfoViaQueryParamsAndOpenAreaModal(paramsArray));
          this.projectViewStore.loadFloor(of({floorId}));
        } else {
          this.getAreaInfoViaQueryParamsAndOpenAreaModal(paramsArray);
        }
      }
    });
  }

  getAreaInfoViaQueryParamsAndOpenAreaModal(paramsArray: any) {
   this.areaService.getAreaById(this.projectViewStore.companyId, this.projectViewStore.projectId, paramsArray[0], paramsArray[1], paramsArray[2], paramsArray[3]).subscribe((data) => {
      let selectedUnit = new Unit();
      let selectedRoom = new Room();
      this.currentFloor.units.find(unit => {
        if(unit.id === Number(paramsArray[1])) {
          selectedUnit = unit
          selectedRoom = unit.rooms.find(room => room.id === Number(paramsArray[2]))
        }
      })
      let areaObj = {
        area: data,
        floorId: this.currentFloor.id,
        floorName: this.currentFloor.name,
        unitId: selectedUnit.id,
        unitName: selectedUnit.name,
        roomId: selectedRoom.id,
        roomName: selectedRoom.name}
      this.projectViewStore.updateSelectedObject({obj: areaObj, isSelectedArea: true})
      this.projectViewStore.updatePopupOpened(PopupOpened.AREA_VIEW)
    })
  }

  updateArea(selectedAreaInfo: SelectedArea) {
    this.projectViewStore.updateArea(selectedAreaInfo.area);
    this.projectViewStore.updateSelectedObject({obj: selectedAreaInfo, isSelectedArea: true})
    this.globalStore.loadProjectProgress()
  }

  deleteSelectedUnit() {
    this.deletingBtnDisabled = true;
    this.unitsService.deleteUnitById(this.projectViewStore.companyId, this.projectViewStore.projectId, this.currentFloor.id, this.unitToDelete.id).subscribe(() => {
      this.projectViewStore.deleteUnit(this.unitToDelete.id);
      this.globalStore.loadProjectProgress();

      this.deletingPopup = 'none';
      this.unitToDelete = null;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  deleteSelectedTemplate() {
    this.deletingBtnDisabled = true;
    this.roomTemplatesService.deleteTemplateById(this.projectViewStore.companyId, this.projectViewStore.projectId, this.templateToDelete.id).subscribe(() => {
      this.projectViewStore.deleteTemplate(this.templateToDelete.id);
      this.projectViewStore.reloadCurrentFloor();
      this.globalStore.loadProjectProgress();
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
      this.templateToDelete = null;
      this.deletingPopup = 'none';
    }, error => this.deletingBtnDisabled = false)
  }

  setCoordinateUnitsList(event: any, floor: FloorDuplicateMenu) {
    let floorIdx = this.floorDuplicateList.indexOf(floor)
    this.multipleDuplicateListWrapperTopCoordinate = this.floorsDuplicateUnitsList.nativeElement.getBoundingClientRect().top

    if (this.unitsDuplicateListTopPosition || floor.units.length === 0) {
      if(this.previousUnitsListRef) this.previousUnitsListRef.nativeElement.removeAttribute('opened')
      this.isFloorDuplicateHover = false;
      return
    }

    this.isFloorDuplicateHover = true;
    this.floorDuplicateSelected = floor;

    const elCoordinate = event.currentTarget.getBoundingClientRect();

    if((elCoordinate.top -  this.multipleDuplicateListWrapperTopCoordinate) >= 0) {
      this.unitsDuplicateListTopPosition = elCoordinate.top -  this.multipleDuplicateListWrapperTopCoordinate;
    }

    this.previousUnitsListRef = this.unitsDuplicateList.get(floorIdx);
  }

  mouseOutFloor(event: any) {
    const relatedTarget = (event as any).relatedTarget;
    if (relatedTarget && !relatedTarget.classList.contains('duplicate-units-variants-list')
      && !relatedTarget.classList.contains('duplicate-units-variants__item') && !relatedTarget.classList.contains('duplicate-units-variants-floors-list')) {
      if(this.previousUnitsListRef) this.previousUnitsListRef.nativeElement.removeAttribute('opened');
      this.isFloorDuplicateHover = false;
      this.floorDuplicateSelected = null;
      return;
    }
  }

  openCreateUnitForm(event: any) {
    event.stopPropagation()
    this.isAddUnitMenuOpen = false
    this.isAddUnitFormOpen = true
  }

  dropUnits(event: CdkDragDrop<Floor[]>) {
    let newOrder = event.currentIndex;
    let oldOrder = event.previousIndex;
    let unitId = event.item.data.id;

    if (newOrder === oldOrder) return;

    this.projectViewStore.changeUnitOrder(of({newOrder, oldOrder, unitId}));
    this.unitsService.updateUnitOrderById(this.projectViewStore.companyId, this.projectViewStore.projectId, this.currentFloor.id, unitId, newOrder).subscribe();
  }

createNewUnit(resetBtn: HTMLElement) {
  if(this.createUnitForm.invalid) return;

  this.unitsService.createUnit(this.projectViewStore.companyId, this.projectViewStore.projectId, this.currentFloor.id, this.createUnitForm.controls['name'].value, null).subscribe((data) => {
    this.projectViewStore.addUnit(data);
    this.projectViewStore.loadFloorDuplicateMenuList();
    this.projectViewStore.updateIsCdkDragDisabled(false);

    this.isAddUnitFormOpen = false;
    this.notifService.successNotification('Changes have been saved');
    this.createUnitForm.controls["name"].setValue(null);
    this.createUnitForm.controls['name'].setErrors({invalid: false})

    resetBtn.click();
  }, (error: HttpErrorResponse) => {
    if (error.status === 400 && error.error.error === 'Name already in use') this.createUnitForm.controls['name'].setErrors({error: 'Unit with such name already exists'})
  })
}

  duplicateUnit(unitId: number) {
    this.unitsService.createUnit(this.projectViewStore.companyId, this.projectViewStore.projectId, this.currentFloor.id, null, unitId).subscribe((data) => {
      this.projectViewStore.addUnit(data);
      this.projectViewStore.loadFloorDuplicateMenuList();

      this.notifService.successNotification("Changes have been saved")

      this.unitsDuplicateListTopPosition = null;
      this.isFloorDuplicateHover = false;
      this.floorDuplicateSelected = null;

      this.globalStore.loadProjectProgress();

      if (this.ngScrollbar) {
        waitForElm(`#unit-${data.id}`).then(() => {
          this.ngScrollbar.update();
          this.ngScrollbar.scrollTo({right: 0})
        })
      }

    })
  }


  closeUnitForm() {
    this.isAddUnitFormOpen = false;
    this.projectViewStore.updateIsCdkDragDisabled(false);
  }

  editPopupClosed() {
    this.projectViewStore.updatePopupOpened(PopupOpened.NONE);
    this.projectViewStore.updateIsCdkDragDisabled(false);
  }

  roomCreated(newRoomId: number) {
    this.editPopupClosed();
    waitForElm(`#room-${newRoomId}`).then((el) => (el as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'nearest'}))
  }

  roomUpdated() {
    this.editPopupClosed();
    this.globalStore.loadProjectProgress();
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly PopupOpened = PopupOpened;
  protected readonly UserRole = UserRole;
  protected readonly of = of;
}
