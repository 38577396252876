<div class="filters-wrapper">
  <div class="filters-vs-search-wrapper" (appOutsideClick)="isOpenFilters = false" [enabled]="isOpenFilters === true">
    <div class="btn-vs-form-wrapper" #filtersWrapper>
      <button class="filters-btn" [disabled]="disabled" (click)="onFilters($event)" [attr.amountFilters]="filtersAmount">
        <svg class="filters-icon">
          <use spriteVersion iconName="sort-icon"></use>
        </svg>
        <span>{{filtersAmount > 0 ? 'Filters: ' + filtersAmount : 'Filters'}}</span>
        <svg class="filters-icon close-icon-forCheck" *ngIf="filtersAmount > 0" (click)="this.clearAllFilters.emit()">
          <use spriteVersion iconName="close-icon"></use>
        </svg>
      </button>
      <form class="filters-form" [attr.visible]="isOpenFilters">
        <ng-content></ng-content>
      </form>
    </div>

    <div class="hideBtnWithSearchField-wrapper" [style.flex-direction]="isReplaceSearchAndHideBtn ? 'row-reverse' : 'row'">
      <button class="hide-completed-btn" *ngIf="hideButtonText.length > 0" [disabled]="disabled" (click)="this.hideCompleted.emit(); isHide=!isHide">
        <svg class="hide-icon">
          <use [attr.href]="isHide ? 'assets/sprite.svg#show-password' :'assets/sprite.svg#hide-password'"></use>
        </svg>
        <span>{{isHide ? showButtonText : hideButtonText}}</span>
      </button>
      <label [attr.disabled]="disabled" class="label-search" #labelSearchRef (click)="isOpenFilters = false">
        <svg class="search-icon" (click)="labelSearchRef.setAttribute('open', 'true')">
          <use spriteVersion iconName="search"></use>
        </svg>

        <svg class="close-search-icon" (click)="$event.preventDefault(); searchInput.removeAttribute('open')">
          <use spriteVersion iconName="close-search"></use>
        </svg>
        <input type="text" class="search-input"
               [attr.isUsersInProject]="isUsersInProjectPage"
               [attr.isHideBtnPresent]="isProjectsPage || isTasksPage || isUsersInProjectPage || isMaterialsPage ? 'true' : 'false'"
               [attr.wider]="isWiderInput ? 'true' : 'false'" #searchInput [(ngModel)]="input" [disabled]="disabled" (click)="searchInput.setAttribute('open', 'true')" (input)="this.inputChanged.emit(input)" (blur)="onBlurInput()" [title]="searchPlaceholder"  [placeholder]="searchPlaceholder">
        <svg class="clear-icon" *ngIf="input !== ''" (click)="$event.preventDefault(); clearInput()">
            <use spriteVersion iconName="close-icon"></use>
        </svg>
      </label>
    </div>
  </div>


  <div class="btn-users-project-wrapper">
    <div class="btns-multiselect-wrapper" [attr.isMultiSelect]="selectedItemsAmount > 0">
      <p class="selected-item-text" *ngIf="selectedItemsAmount > 0">Selected: {{selectedItemsAmount}}</p>
      <button *ngIf="selectedItemsAmount > 0" id="btnDeleteSelect" type="button"
              class="btn-delete btn-delete-select-item" (click)="deleteSelected($event)">
        <svg class="btn-delete__icon btn-delete-select-item__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        <span>{{isUsersInProjectPage && currentUser.role !== UserRole.ROLE_EMPLOYEE ? 'Remove from project' : 'Delete'}}</span>
      </button>

      <button *ngIf="selectedItemsAmount > 0" id="btnCancelSelect" type="button"
              class="btn-cansel-selected" (click)="cancelSelected($event)">
        Cancel
      </button>
    </div>
    <button *ngIf="isMaterialsPage" class="export-material-btn">
      <svg class="export-icon">
        <use spriteVersion iconName="export-icon"></use>
      </svg>
      <span>Export</span>
    </button>
    <button *ngIf="
    (currentUser.role === UserRole.ROLE_EMPLOYEE && (isTasksPage)) ||
    (currentUser.role === UserRole.ROLE_PROJECT_MANAGER && (!isProjectsPage)) ||
    currentUser.role === UserRole.ROLE_ADMIN ||
    currentUser.role === UserRole.ROLE_SUPERADMIN" id="btnCreate" type="button"
            [ngClass]="isUsersInProjectPage ? ['btn-invite-white'] : ['btn-create']" (click)="this.openFormBtnClick.emit()">
      <svg class="add-icon">
        <use spriteVersion iconName="close-icon"></use>
      </svg>
      {{isUsersInProjectPage && !isShorterBtnText ? 'Invite New User' : isUsersInProjectPage && isShorterBtnText ? 'Invite User': btnText}}
    </button>

    <button *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE && isUsersInProjectPage"
            id="btnAddUsersToProject" type="button" class="btn-create btn-add-users-to-project" (click)="this.openAddUsersBtnClick.emit()">
      <svg class="add-icon">
        <use spriteVersion iconName="users"></use>
      </svg>
      {{ !isShorterBtnText ? 'Add Existing User' : 'Add User' }}
    </button>

  </div>

</div>
