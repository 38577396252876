<ul class="area-progress-list-rooms">
  <li *ngFor="let room of unit.rooms; trackBy: trackByRoom" [class.room-mock]="room.id === 0"
    class="area-progress-list-rooms__item">
    <ul class="area-progress-list-areas">
      <li class="area-progress-list-areas__item main" *ngFor="let area of room.areas; trackBy: trackByArea"
          #areaItem
          [id]="area.id"
          (click)="area.id === 0 ? null : areaClick.emit({area, unit, room, floor: currentFloor})"
          [attr.status]="area.status?.orderNumber"
          [attr.status-name]="area.status?.name"
          [ngStyle]="{'cursor': area.id !== 0 ? 'pointer' : null}"
          [class.area-mock]="area.id === 0">
        <span class="area-color-container" [attr.hasComments]="area.commentsAmount > 0"
          [ngStyle]="{'background-color': area.status?.orderNumber !== -3 ? area.status?.color : 'white', 'opacity': area.pp_opacity}"></span>
        <span *ngIf="area.commentsAmount > 0"
              class="area-triangle-comment-wrapper"
              [attr.isWhiteTriangle]="addColorToAreaTriangle(area)"
              [attr.hasComments]="area.commentsAmount > 0"
              [attr.hasAttachments]="area.attachmentsAmount > 0"></span>
        <span *ngIf="area.id !== 0 && area.status.orderNumber !== -3"
          class="area-color-container areaProgress-text"
          [ngStyle]="{'color': area.statusProgress >= 60 && area.status.orderNumber < 0 ? 'white' : null}">
          <ng-template [ngIf]="area.status.orderNumber < 0">{{area.statusProgress}}<sup>%</sup></ng-template>
        </span>
      </li>
      <li class="area-progress-list-areas__item area-mock" *ngIf="room.areas.length === 0"></li>
    </ul>
  </li>
  <li *ngFor="let roomMock of [].constructor(amountMocksHorizontal)"
    class="area-progress-list-rooms__item room-mock">
    <ul class="area-progress-list-areas">
      <li class="area-progress-list-areas__item area-mock"></li>
    </ul>
  </li>
</ul>
