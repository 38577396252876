<div class="ng-select-wrapper">
  <div class="material-unit-btn-wrapper" #materialSelect
       [enabled]="materialSelect.hasAttribute('openMenu')"
       (appOutsideClick)="materialSelect.removeAttribute('openMenu'); closeSelect();">
    <button class="material__unit-btn"
           id="materialSelectBtn"
            type="button"
           (click)="materialSelect.toggleAttribute('openMenu')"
           [attr.placeholder]="this.isBtnPlaceholder"
           [attr.value]="statusFromKeysList.indexOf(selectedOption?.value)">
           <span>{{this.isBtnPlaceholder ? 'Select Status' : statusesMap.get(selectedOption.value).toString()}}</span>
           <svg class="material__unit-icon-dropdown"
                *ngIf="!isClearFieldBtn">
             <use spriteVersion iconName="arrow-select-area-status"></use>
           </svg>
          <svg class="clear-field-btn-icon"
               stopClickPropagation
               *ngIf="isClearFieldBtn"
               (click)="clearField()">
            <use spriteVersion iconName="close-icon"></use>
          </svg>
    </button>


    <div class="material__unit-menu-wrapper">
      <ul class="material__unit-menu">
        <li class="material__unit-menu__item" *ngFor="let status of statusesMap | keyvalue: originalOrder; let idx = index" (click)="changeEl.emit(status.key); onChange(status.key); materialSelect.removeAttribute('openMenu')">
          <p class="material__unit-menu__text"
             [attr.value]="idx"
            [attr.selectedOption]="idx === statusFromKeysList.indexOf(selectedOption?.value)">
            {{status.value.toString()}}
          </p>
        </li>
      </ul>
    </div>
  </div>

<!--  [attr.value]="idx"-->
<!--  [attr.selectedOption]="status.value.toString() === statusesMap.get(selectedOption?.value).toString()"-->

</div>
