import {ComponentStore} from "@ngrx/component-store";
import {ProjectTable, TableHeadRoom} from "../../../../../_models/project-table";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject, switchMap, takeUntil, tap} from "rxjs";
import {Area, AreaStatusDefaultOrder} from "../../../../../_models/area";
import {ProjectsService} from "../../../../../_services/projects.service";
import {Floor} from "../../../../../_models/floor";
import {ProjectCustomStatuses} from "../../../../../_models/project";
import {ProjectAreaStatusesService} from "../../../../../_services/project-area-statuses.service";
import {GlobalStore} from "../../../../../global.store";

export interface ProjectProgressState {
  projectTable: ProjectTable | null;
  projectTableCopyForModifying: ProjectTable | null;
  useTableCopy: boolean;
  floorFilter: number[] | null;
  statistics: Map<number, number> | null;
  projectStatuses: ProjectCustomStatuses[] | null;
  areasTableOrderMap: Map<string, number[]>
}

@Injectable()
export class ProjectProgressStore extends ComponentStore<ProjectProgressState> implements OnDestroy {
  companyId: number;
  projectId: number;

  private readonly destroySubj$ = new Subject<void>()
  constructor(private projectService: ProjectsService,
              readonly globalStore: GlobalStore,
              private projectAreaStatusesService: ProjectAreaStatusesService,) {
    super({
      projectTable: null,
      projectTableCopyForModifying: null,
      useTableCopy: false,
      floorFilter: null,
      statistics: null,
      projectStatuses: null,
      areasTableOrderMap: null
    });
    this.globalStore.companyId$.pipe(takeUntil(this.destroySubj$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroySubj$)).subscribe((projectId) => this.projectId = projectId);
  }

  readonly currentProjectTable$ = this.select(
    (state) => (state.useTableCopy ? state.projectTableCopyForModifying : state.projectTable)
  );

  readonly projectTableVersion$ = this.select(
    (state) => state.projectTable?.version
  );

  readonly tableHead$ = this.select(
    this.currentProjectTable$,
    (projectTable) => projectTable?.tableHead ?? []
  );

  readonly projectStatuses$ = this.select(
    (state) => (state.projectStatuses ?? [])
  )

  readonly floors$ = this.select(
    this.currentProjectTable$,
    (projectTable) => projectTable?.floors ?? []
  );

  readonly floorFilter$ = this.select(
    state => state.floorFilter ?? []
  );

  readonly statistics$ = this.select(
    (state) => (state.statistics ?? new Map())
  )

  readonly areasTableOrderMap$ = this.select(
    (state) => (state.areasTableOrderMap ?? new Map())
  )

  readonly deleteStatusWithReplacement = this.updater((state: ProjectProgressState, updatedStatusesInfo: {newStatus: ProjectCustomStatuses,  deletedStatus: ProjectCustomStatuses}) => {
    const statistics = new Map(state.statistics);
    const updatedStatuses = [...state.projectStatuses].filter(floor => floor.id !== updatedStatusesInfo.deletedStatus.id);

    const updatedFloors = state.projectTable.floors.map(floor => ({
      ...floor,
      units: floor.units.map(unit => ({
        ...unit,
        rooms: unit.rooms.map(room => ({
          ...room,
          areas: room.areas.map(area => {
            if (area.status?.id === updatedStatusesInfo.deletedStatus.id) {
              const updatedArea = {...area}
              statistics.set(updatedStatusesInfo.newStatus.id, statistics.get(updatedStatusesInfo.newStatus.id) + 1);
              updatedArea.status = {...updatedStatusesInfo.newStatus}
              return updatedArea;
            }
            return area;
          })
        }))
      }))
    }));

    return {
      ...state,
      projectTable: {
        ...state.projectTable,
        floors: updatedFloors
      },
      statistics: statistics,
      projectStatuses: updatedStatuses
    };
  })

  readonly updateArea = this.updater((state: ProjectProgressState, updatedArea: Area) => {
    updatedArea.pp_opacity = this.calculateColorOpacity(updatedArea);

    const statistics = new Map(state.statistics);

    const updatedFloors = state.projectTable.floors.map(floor => ({
      ...floor,
      units: floor.units.map(unit => ({
        ...unit,
        rooms: unit.rooms.map(room => ({
          ...room,
          areas: room.areas.map(area => {
            if (area.id === updatedArea.id) {
              if(area.status.id !== updatedArea.status.id) {
                statistics.set(area.status.id, statistics.get(area.status.id) - 1);
                statistics.set(updatedArea.status.id, statistics.get(updatedArea.status.id) + 1);
              }
              return updatedArea;
            }
            return area;
          })
        }))
      }))
    }));

    return {
      ...state,
      projectTable: {
        ...state.projectTable,
        floors: updatedFloors
      },
      statistics: statistics
    };
  });

  readonly moveRoomRight = this.updater((state, index: number) => {
    if (!state.projectTableCopyForModifying || index >= state.projectTableCopyForModifying.tableHead.length - 1) return state;

    const tableHeadCopy = [...(state.projectTableCopyForModifying.tableHead || [])];

    // Перемещаем комнату вправо
    [tableHeadCopy[index + 1], tableHeadCopy[index]] = [tableHeadCopy[index], tableHeadCopy[index + 1]];

    return {
      ...state,
      projectTableCopyForModifying: {
        ...state.projectTableCopyForModifying,
        tableHead: tableHeadCopy,
        floors: this.syncRooms(state, tableHeadCopy),
      },
    };
  });

  private syncRooms(state: ProjectProgressState, tableHeadCopy: TableHeadRoom[]) {
    // Синхронизируем комнаты в floors
    const roomOrder = tableHeadCopy.map(room => room.name);
    return state.projectTableCopyForModifying.floors.map((floor) => ({
      ...floor,
      units: floor.units.map(unit => ({
        ...unit,
        rooms: unit.rooms.sort((a, b) => roomOrder.indexOf(a.name) - roomOrder.indexOf(b.name))
      }))
    }));
  }

  readonly moveRoomLeft = this.updater((state, index: number) => {
    if (index <= 0 || !state.projectTableCopyForModifying) return state;

    const tableHeadCopy = [...(state.projectTableCopyForModifying.tableHead || [])];

    // Перемещаем комнату влево
    [tableHeadCopy[index - 1], tableHeadCopy[index]] = [tableHeadCopy[index], tableHeadCopy[index - 1]];

    return {
      ...state,
      projectTableCopyForModifying: {
        ...state.projectTableCopyForModifying,
        tableHead: tableHeadCopy,
        floors: this.syncRooms(state, tableHeadCopy)
      },
    };
  });


  readonly moveAreaLeft = this.updater((state, areaInfo: {roomIndex: number, areaIndex: number,}) => {
    if (areaInfo.areaIndex <= 0 || !state.projectTableCopyForModifying) return state;

    const tableHeadCopy = [...(state.projectTableCopyForModifying.tableHead || [])];
    const areasCopy = [...(tableHeadCopy[areaInfo.roomIndex].areas || [])];

    [areasCopy[areaInfo.areaIndex - 1], areasCopy[areaInfo.areaIndex]] = [areasCopy[areaInfo.areaIndex], areasCopy[areaInfo.areaIndex - 1]];

    tableHeadCopy[areaInfo.roomIndex].areas = areasCopy;

    return {
      ...state,
      projectTableCopyForModifying: {
        ...state.projectTableCopyForModifying,
        tableHead: tableHeadCopy,
        floors: this.syncAreas(state, {tableHeadCopy, roomIndex: areaInfo.roomIndex, oldIdx: areaInfo.areaIndex, newIdx: areaInfo.areaIndex - 1})
      },
      areasTableOrderMap: this.updateAreasTableOrderMap(state, {tableHeadCopy, roomIndex: areaInfo.roomIndex, oldIdx: areaInfo.areaIndex, newIdx: areaInfo.areaIndex - 1})
    };
  });

  readonly moveAreaRight = this.updater((state, areaInfo: {roomIndex: number, areaIndex: number}) => {
    if (areaInfo.areaIndex >= (state.projectTableCopyForModifying.tableHead[areaInfo.roomIndex].areas.length - 1) || !state.projectTableCopyForModifying) return state;

    const tableHeadCopy = [...(state.projectTableCopyForModifying.tableHead || [])];
    const areasCopy = [...(tableHeadCopy[areaInfo.roomIndex].areas || [])];

    [areasCopy[areaInfo.areaIndex], areasCopy[areaInfo.areaIndex + 1]] = [areasCopy[areaInfo.areaIndex + 1], areasCopy[areaInfo.areaIndex]];

    tableHeadCopy[areaInfo.roomIndex].areas = areasCopy;

    return {
      ...state,
      projectTableCopyForModifying: {
        ...state.projectTableCopyForModifying,
        tableHead: tableHeadCopy,
        floors: this.syncAreas(state, {tableHeadCopy, roomIndex: areaInfo.roomIndex, oldIdx: areaInfo.areaIndex, newIdx: areaInfo.areaIndex + 1})
      },
      areasTableOrderMap: this.updateAreasTableOrderMap(state, {tableHeadCopy, roomIndex: areaInfo.roomIndex, oldIdx: areaInfo.areaIndex, newIdx: areaInfo.areaIndex + 1})
    };
  });

  private syncAreas(state: ProjectProgressState, info: {tableHeadCopy: TableHeadRoom[], roomIndex: number, oldIdx: number, newIdx: number}): Floor[] {
    const areaOrderMap: { [roomName: string]: string[] } = {};
    info.tableHeadCopy.forEach(room => {
      areaOrderMap[room.name] = room.areas;
    });


    return state.projectTableCopyForModifying.floors.map((floor) => ({
      ...floor,
      units: floor.units.map(unit => ({
        ...unit,
        rooms: unit.rooms.map((room, index) => {
          if(index === info.roomIndex) {
            let areasCopy = [...room.areas]
            let temp = areasCopy[info.oldIdx];
            areasCopy[info.oldIdx] = areasCopy[info.newIdx];
            areasCopy[info.newIdx] = temp;
            return {
              ...room,
              areas: areasCopy
            }
          }
          return room
        })
      }))
    }));
  }

  //update areas order map
 private updateAreasTableOrderMap(state: ProjectProgressState, info: {tableHeadCopy: TableHeadRoom[], roomIndex: number, oldIdx: number, newIdx: number}): Map<string, number[]> {
   const areaOrderMapCopy = new Map(state.areasTableOrderMap);
   const roomName = info.tableHeadCopy[info.roomIndex].name
   const areasIdxArr = areaOrderMapCopy.get(roomName)
   const oldValue = areasIdxArr[info.oldIdx];
   areasIdxArr[info.oldIdx] = areasIdxArr[info.newIdx]
   areasIdxArr[info.newIdx] = oldValue
   areaOrderMapCopy.set(roomName, areasIdxArr)
   return areaOrderMapCopy
 }

  readonly createAreasTableOrderMap = this.updater((state, tableHeadCopy: TableHeadRoom[]) => {
   const areasTableOrderMap: Map<string, number[]> = new Map();
   tableHeadCopy.forEach(room => {
     let areasIdxArr: number[] = []
     room.areas.forEach((area, i) => areasIdxArr.push(i))
     areasTableOrderMap.set(room.name, areasIdxArr)
   })

   return {
     ...state,
     areasTableOrderMap: areasTableOrderMap
   };
 })


  // Метод для переключения использования копии или оригинала
  readonly toggleUseTableCopy = this.updater((state, useTableCopy: boolean) => {

    return {
      ...state,
      projectTableCopyForModifying: useTableCopy ? structuredClone(state.projectTable) : null,
      useTableCopy: useTableCopy
    };
  });

  // Метод для применения копии таблицы как основной
  readonly applyTableCopyAsMain = this.updater((state) => {
    if (!state.projectTableCopyForModifying) {
      return state; // Если копия не существует, ничего не делаем
    }

    // Обновляем tableHead в projectTable
    const updatedProjectTable = {
      ...state.projectTable,
      tableHead: state.projectTableCopyForModifying.tableHead,
    };

    // Синхронизируем комнаты на основе нового порядка tableHead
    const updatedFloors = this.syncRooms(state, updatedProjectTable.tableHead);

    return {
      ...state,
      projectTable: {
        ...updatedProjectTable,
        floors: updatedFloors, // Применяем синхронизированные комнаты
      },
      projectTableCopyForModifying: null, // Удаляем копию
      useTableCopy: false, // Отключаем использование копии
    };
  });

  readonly updateFloorFilter = this.updater((state, floorFilter: number[] | null) => {
    return {
      ...state,
      floorFilter
    };
  });

  readonly incrementProjectTableVersion = this.updater((state) => {
    return {
      ...state,
      projectTable: {
        ...state.projectTable,
        version: state.projectTable.version + 1
      }
    };
  });


  readonly loadProjectTable = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      switchMap(() =>
        this.projectService.getProjectProgressTable(this.companyId, this.projectId).pipe(
          tap((projectTable: ProjectTable) => {
            const currentState = this.get();

            const statistics: Map<number, number> = new Map<number, number>();

            if (currentState.projectStatuses) {
              currentState.projectStatuses.forEach(statuse => {
                statistics.set(statuse.id, 0)
              });
              projectTable.floors.forEach(floor => {
                floor.units.forEach(unit => {
                  unit.rooms.forEach(room => {
                    if (room.id !== 0) {
                      let roomInHead = projectTable.tableHead.find(roomInHead => roomInHead.name === room.name)
                      if (typeof roomInHead.usageAmount !== 'number' || isNaN(roomInHead.usageAmount)) {
                        roomInHead.usageAmount = 1;
                      } else roomInHead.usageAmount += 1
                      room.areas.forEach(area => {
                        if (area.id !== 0) {
                          area.pp_opacity = this.calculateColorOpacity(area);
                          statistics.set(area.status.id, statistics.get(area.status.id) + 1);
                        }
                      })
                    }
                  })
                })
              })
            }
            this.patchState({projectTable: projectTable, statistics: statistics});
          })
        )
      ),
    )
  );

  readonly loadProjectStatuses = this.effect((trigger$: Observable<void>) => trigger$.pipe(
    switchMap(() => this.projectAreaStatusesService.getProjectAreaStatuses(this.companyId, this.projectId).pipe(
      tap((projectStatuses) => {
        const currentState = this.get();

        const statistics: Map<number, number> = new Map<number, number>();

        if (currentState.projectTable) {
          projectStatuses.forEach(statuse => {statistics.set(statuse.id, 0)});

          currentState.projectTable.floors.forEach(floor => {
            floor.units.forEach(unit => {
              unit.rooms.forEach(room => {
                room.areas.forEach(area => {
                  if (area.status) statistics.set(area.status.id, statistics.get(area.status.id) + 1)
                })
              })
            })
          })
        }

        this.patchState({projectStatuses: projectStatuses, statistics: statistics});
      }))
    )
  ))

  public calculateColorOpacity(area: Area) {
    if (area.id === 0) return null;

    let result;
    if (area.status.orderNumber !== -2) result = '1';
    if (area.status.orderNumber === AreaStatusDefaultOrder.IN_PROGRESS) result = String(Math.max(area.statusProgress, 10) / 100);

    return result || null;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroySubj$.next()
    this.destroySubj$.complete()
  }
}
