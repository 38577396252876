import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Room} from "../_models/room";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private http: HttpClient) {}

  getRoomTemplatesShortList(companyId: number, projectId: number) {
    return this.http.get<Room[]>(`${apiUrl}/company/${companyId}/project/${projectId}/templates/short`, {withCredentials: true})
  }

  getTemplateById(companyId: number, projectId: number, templateId: number) {
    return this.http.get<Room>(`${apiUrl}/company/${companyId}/project/${projectId}/templates/${templateId}`, {withCredentials: true})
  }

  updateTemplate(companyId: number, projectId: number, templateId: number, template: Room) {
    return this.http.put<Room>(`${apiUrl}/company/${companyId}/project/${projectId}/templates/${templateId}`,template, {withCredentials: true})
  }

  deleteTemplateById(companyId: number, projectId: number, templateId: number) {
    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/templates/${templateId}`, {withCredentials: true})
  }
}
