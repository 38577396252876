import {Component, OnDestroy, OnInit} from '@angular/core';
import {Page} from "../../../../../_models/page";
import {Project} from "../../../../../_models/project";
import {ProjectsService} from "../../../../../_services/projects.service";
import {SortType} from "../../../../../config/constants";
import {GlobalStore} from "../../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";
import {DashboardStore} from "../dashboard.store";

@Component({
  selector: 'app-dashboard-projects-table',
  templateUrl: './dashboard-projects-table.component.html',
  styleUrls: ['./dashboard-projects-table.component.scss']
})
export class DashboardProjectsTableComponent implements OnInit, OnDestroy {
  projectsPage: Page<Project> = new Page<Project>();
  isLoadingProjects = false;
  sorting: any = {};
  page = 0;
  pageSize = 10;
  isIOS = this.globalStore.isIOS$;

  companyId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private projectService: ProjectsService,
               readonly globalStore: GlobalStore,
              readonly dashboardStore: DashboardStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
  }

  ngOnInit() {
    this.getProjectsList()
  }


  getProjectsList() {
    this.isLoadingProjects = true;

    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }
    if (this.companyId) {
      this.projectService.getProjectListByCompanyIdAndParams(this.companyId, true, null, null, null, sortingField, sortingDirection, this.page, this.pageSize).subscribe((data) => {
        this.checkResults(data)
      });
    } else this.projectService.getProjectListByParams(null, true, null, null, sortingField, sortingDirection, this.page, this.pageSize).subscribe((data) => {
      this.checkResults(data)
    });
  }

  checkResults(data: Page<any>) {
    if (data.totalPages > 0 && this.page + 1 > data.totalPages) {
      this.page = data.totalPages - 1;
      this.getProjectsList()
    } else {
      if (this.page === 0) this.projectsPage = data;
      else {
        this.projectsPage = {
          ...data,
          content: [...this.projectsPage.content, ...data.content]
        };
      }
      this.dashboardStore.updateProjectsTotalAmount(data.totalElements);
      this.isLoadingProjects = false;
    }
  }

  onSortBy(sortBy: string) {
    this.page = 0;
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getProjectsList()
  }

  getNextPage() {
    if (this.projectsPage.last) return;

    this.page += 1;
    this.getProjectsList()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly SortType = SortType;
}
