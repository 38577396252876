import {animate, state, style, transition, trigger} from "@angular/animations";
import {UserRole} from "../_models/user";
import {CompanyType} from "../_models/company";
import {TaskPriority, TaskStatus} from "../_models/task";
import {AreaStatus, AreaWeight} from "../_models/area";
import {OrderStatus, SubmittalStatus} from "../_models/material";

export const modalAnimation = trigger('modalAnimation', [
  state('void', style({
    visibility: 'hidden',
    opacity: 0
  })),
  state('*', style({
    visibility: 'visible',
    opacity: 1
  })),
  transition('void <=> *', animate('0.3s ease-in-out')),
  transition('* => void', animate('0.3s ease-in-out'))
]);

export const emailPattern = "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$";
export const phoneMask = "(000) 000-0000";
export enum SortType {ASC="ASC", DESC="DESC"}

export const companyTypeByEnum: Map<CompanyType, string> = new Map<CompanyType, string>([
  [CompanyType.PAINTING, "Painting"],
  [CompanyType.FLOORING, "Flooring"],
  [CompanyType.ELECTRICAL, "Electrical"],
  [CompanyType.PLUMBING, "Plumbing"],
  [CompanyType.TILING, "Tiling"],
  [CompanyType.HOTEL, "Hotel"],
  [CompanyType.GENERAL_CONTRACTOR, "General Contractor"]
]);

export const taskPriorityByEnum: Map<TaskPriority, string> = new Map<TaskPriority, string>([
  [TaskPriority.NO_PRIORITY, "No priority"],
  [TaskPriority.LOW, "Low"],
  [TaskPriority.MEDIUM, "Medium"],
  [TaskPriority.HIGH, "High"],
]);

export const taskStatusByEnum: Map<TaskStatus, string> = new Map<TaskStatus, string>([
  [TaskStatus.TO_DO, "To Do"],
  [TaskStatus.IN_PROGRESS, "In progress"],
  [TaskStatus.READY, "Ready"],
  [TaskStatus.DONE, "Done"],
]);

export const areaStatusByEnum: Map<AreaStatus, string> = new Map<AreaStatus, string>([
  [AreaStatus.TO_DO, "To Do"],
  [AreaStatus.IN_PROGRESS, "In Progress"],
  [AreaStatus.DONE, "Done"],
]);

export const areaWeightByEnum: Map<AreaWeight, string> = new Map<AreaWeight, string>([
  [AreaWeight.NO_WEIGHT, 'No Weight'],
  [AreaWeight.SMALL, "Small"],
  [AreaWeight.MEDIUM, "Medium"],
  [AreaWeight.LARGE, "Large"],
  [AreaWeight.HUGE, "Huge"]
]);

export const roleDescByEnum: Map<UserRole, string> = new Map<UserRole, string>([
  [UserRole.ROLE_SUPERADMIN, "Super Admin"],
  [UserRole.ROLE_ADMIN, "Company Admin"],
  [UserRole.ROLE_EMPLOYEE, "Standard User"],
  [UserRole.ROLE_PROJECT_MANAGER, "Project Manager"]
]);
export const roleDescByEnumForTable: Map<UserRole, string> = new Map<UserRole, string>([
  [UserRole.ROLE_SUPERADMIN, "Super Admin"],
  [UserRole.ROLE_ADMIN, "Admin"],
  [UserRole.ROLE_EMPLOYEE, "Standard User"],
  [UserRole.ROLE_PROJECT_MANAGER, "Project Manager"]
]);
export let userRoleStringForSelector: Map<UserRole, string> = new Map<UserRole, string>([
  [UserRole.ROLE_ADMIN, "Company Admin"],
  [UserRole.ROLE_PROJECT_MANAGER, "Project Manager"],
  [UserRole.ROLE_EMPLOYEE, "Standard User"]
]);


export let submittalStatusStringForSelector: Map<SubmittalStatus, string> = new Map<SubmittalStatus, string>([
  [SubmittalStatus.REJECTED, "Rejected"],
  [SubmittalStatus.SAMPLES_ORDERED, "Samples Ordered"],
  [SubmittalStatus.SUBMITTED, "Submitted"],
  [SubmittalStatus.APPROVED_AS_NOTED, "Approved As Noted"],
  [SubmittalStatus.APPROVED, "Approved"],
]);

export let orderStatusStringForSelector: Map<OrderStatus, string> = new Map<OrderStatus, string>([
  [OrderStatus. NOT_ORDERED, "Not Ordered"],
  [OrderStatus.PO_SUBMITTED, "PO Submitted"],
  [OrderStatus.PARTIALLY_ORDERED, "Partially Ordered"],
  [OrderStatus.ORDERED, "Ordered"],
  [OrderStatus.ARRIVED, "Arrived"],
]);
