<div class="backdrop">
  <div class="modal">
    <form class="form-invite" #form="ngForm" firstInputAutofocus scrollToFirstInvalidInput [formGroup]="userProfileForm" (ngSubmit)="submitForm()">
      <button type="button" class="close-modal-btn" id="btnClose" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>

      <div class="form-modal-way-wrapper" *ngIf="user.id">
        <ng-template [ngIf]="currentUser.role === UserRole.ROLE_SUPERADMIN && currentUser.id !== user.id">
          <span class="form-modal-way">{{user.company.name}}</span>
          /
        </ng-template>
        <ng-template [ngIf]="userOldName || (userOldName  && currentUser.role === UserRole.ROLE_SUPERADMIN && currentUser.id !== user.id)">
          <span class="form-modal-way">{{userOldName}}</span>
          /
        </ng-template>
        <span class="form-modal-way project-code-way">{{user.id}}</span>
      </div>
      <div class="form-inner-wrapper" [style.padding-top]="user.id ? '0' : '32px'">
        <p class="form-modal-title">Edit User</p>

        <div class="main-fields-wrapper">
          <p class="main-error" *ngIf="userProfileForm.invalid && form.submitted" id="mainError">
            <svg class="error-icon">
              <use spriteVersion iconName="error-icon"></use>
            </svg>
            All fields are required
          </p>
          <div class="field-wrapper">
            <label class="form-label"><span class="label-text">Name</span> </label>
            <span class="input-with-error-wrapper">
              <input class="form-input-modal" formInput="NAME" id="userName" formControlName="name" placeholder="Name">
              <p class="small-error-text-field" id="userNameError"
                 *ngIf="userProfileForm.controls['name'].invalid && form.submitted">
                 {{userProfileForm.controls['name'].getError('error')}}
              </p>
            </span>
          </div>

          <div class="group-fields-wrapper">
            <div class="field-wrapper">
              <label class="form-label"><span class="label-text">Phone Number</span> </label>
              <div class="phone-with-code-wrapper">
                <app-countries-code-select
                  [countryCode]="user.countryCode"
                  [formControl]="$any(userProfileForm).controls['countryCode']"
                  (changeCountryCode)="changeCountryCode($event)"
                  [isError]="userProfileForm.controls['phone'].invalid && form.submitted">
                </app-countries-code-select>
                <span class="input-with-error-wrapper">
                  <input class="form-input-modal" [mask]="phoneMask" id="userPhone" formControlName="phone"
                     placeholder="(000) 000-0000">
                  <p class="small-error-text-field" id="userPhoneError"
                      *ngIf="userProfileForm.controls['phone'].invalid && form.submitted">{{userProfileForm.controls['phone'].getError('error')}}</p>
                </span>
              </div>
            </div>
            <div class="field-wrapper">
              <label class="form-label"><span class="label-text">Email</span> </label>
              <span class="input-with-error-wrapper">
                <input class="form-input-modal" formInput="EMAIL" id="userEmail" formControlName="email" placeholder="Email">
                <p class="small-error-text-field" id="userEmailError"
                 *ngIf="userProfileForm.controls['email'].invalid && form.submitted">
                  {{userProfileForm.controls['email'].getError('error')}}
                </p>
              </span>
            </div>
          </div>
          <ng-container>
            <div class="group-fields-wrapper">
              <div *ngIf="user.id !== currentUser.id && currentUser.role === UserRole.ROLE_SUPERADMIN" class="field-wrapper">
                <label class="form-label"><span class="label-text">Company</span></label>
                <div class="ng-select-wrapper ng-select-wrapper--wider">
                  <ng-select bindLabel="name"
                             id="companySelect"
                             bindValue="id"
                             formControlName="companyId"
                             [clearable]="false"
                             notFoundText="No results matching"
                             [editableSearchTerm]="false"
                             [searchable]="false"
                             placeholder="Company Name">
                    <ng-option *ngFor="let company of companiesList" [value]="company.id">{{company.name}}</ng-option>
                  </ng-select>
                </div>
                <p class="small-error-text-field" id="companySelectError" *ngIf="userProfileForm.controls['companyId'].invalid && form.submitted">This field is required</p>
              </div>
              <div *ngIf="user.role !== UserRole.ROLE_SUPERADMIN" class="field-wrapper">
                <label class="form-label"><span class="label-text">Role</span></label>
                <div class="ng-select-wrapper ng-select-wrapper--wider">
                  <ng-select bindLabel="value"
                             id="roleSelect"
                             bindValue="key"
                             formControlName="role"
                             [clearable]="false"
                             notFoundText="No results matching"
                             [editableSearchTerm]="false"
                             [searchable]="false"
                             placeholder="Select role">
                    <ng-option *ngFor="let userRole of userRoleStringForSelector | keyvalue"
                               [value]="userRole.key">{{userRole.value}}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="field-wrapper project-list"
                   *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE && currentUser.role !== UserRole.ROLE_PROJECT_MANAGER  && (user.role === UserRole.ROLE_EMPLOYEE || user.role === UserRole.ROLE_PROJECT_MANAGER )">
                <label class="form-label project-optional-label">Projects <span class="project-optional-text">(Optional)</span>
                </label>
                <div class="ng-select-wrapper ng-select-wrapper--wider">
                  <ng-select #projectsSelector bindLabel="name"
                             id="projectSelect"
                             bindValue="id"
                             formControlName="projectIds"
                             [clearable]="false"
                             notFoundText="No results matching"
                             [hideSelected]="true"
                             [multiple]="true"
                             placeholder="Select Project">
                    <ng-option *ngFor="let project of projectList" [value]="project.id">{{project.name}}</ng-option>
                    <ng-template [ngIf]="projectList?.length !== 0" ng-footer-tmp><a [attr.hasUsers]="projectList?.length !== 0 && projectList?.length !== projectsSelector.selectedValues.length" (click)="addAllProjects($event)">Add All Projects</a></ng-template>
                    <ng-option *ngIf="projectList.length === projectsSelector.selectedValues.length" value="no-results" disabled>{{projectsSelector.notFoundText}}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" loader="white" [disabled]="submitBtnDisabled" [showLoader]="submitBtnDisabled" id="btnSave">Save</button>
      </div>
    </form>
  </div>
</div>

