import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {Company, CompanyType} from "../_models/company";
import {apiUrl} from "../../environments/environment";
import {convertCompanyTypeToEnum} from "../_util/utils";
import {Page} from "../_models/page";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }

  getCompanyListByParams(companyType: CompanyType, state: string, city: string, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<Company>> {
    let params = new HttpParams();
    if (companyType) params = params.set('companyType', companyType);
    if (state) params = params.set('state', state);
    if (city) params = params.set('city', city);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<Company>>(`${apiUrl}/company/list`, {params: params, withCredentials: true}).pipe(map(page => {
      page.content.forEach(e => e.type = convertCompanyTypeToEnum(e.type));
      return page;
    }))
  }
  createCompany(company: Company): Observable<Company>  {
   return this.http.post<Company>(`${apiUrl}/company`, company, {withCredentials: true})
  }

  updateCompany(company: Company): Observable<Company>  {
    return this.http.put<Company>(`${apiUrl}/company`, company, {withCredentials: true}).pipe(map(result => {
      result.type = convertCompanyTypeToEnum(result.type);
      return result;
    }))
  }

  getShortCompanyList(): Observable<Company[]>  {
    return this.http.get<Company[]>(`${apiUrl}/company/short`, {withCredentials: true})
  }

  getCompanyClientNames(companyId: number): Observable<string[]>  {
    return this.http.get<string[]>(`${apiUrl}/company/${companyId}/clients`, {withCredentials: true})
  }

  getAllClientNames(): Observable<string[]>  {
    return this.http.get<string[]>(`${apiUrl}/company/clients`, {withCredentials: true})
  }

  getCompanyById(companyId: number) {
    return this.http.get<Company>(`${apiUrl}/company/${companyId}`, {withCredentials: true}).pipe(map(result => {
      result.type = convertCompanyTypeToEnum(result.type);
      return result;
    }))
  }

  getCompanyByIdShort(companyId: number) {
    return this.http.get<Company>(`${apiUrl}/company/${companyId}/short`, {withCredentials: true});
  }

  deleteCompany(companyIds: number[]) {
    let params = new HttpParams();
    companyIds.forEach(e => params = params.append('companyIds', e));

    return this.http.delete(`${apiUrl}/company`, {params: params, withCredentials: true})
  }

}
