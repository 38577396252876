import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {UserRole} from "../../../../../../_models/user";
import {forkJoin, Subject, switchMap, takeUntil, tap} from "rxjs";
import {GlobalStore} from "../../../../../../global.store";
import {NotificationService} from "../../../../../../_services/notification.service";
import {etaDateValidator, minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import {
  modalAnimation,
  submittalStatusStringForSelector,
  orderStatusStringForSelector
} from "../../../../../../config/constants";
import {
  convertMaterialOrderStatusToEnum,
  convertMaterialSubmittalStatusToEnum,
  originalOrder
} from "../../../../../../_util/utils";
import {HttpErrorResponse} from "@angular/common/http";
import {
  Material,
  FormFile,
  MaterialPaymentStatus, MaterialStoredLocation,
  MaterialUnit,
  OrderStatus, SubmittalStatus
} from "../../../../../../_models/material";
import {MaterialUnitService} from "../../../../../../_services/material-unit.service";
import {MaterialsService} from "../../../../../../_services/materials.service";
import {MaterialPaymentStatusService} from "../../../../../../_services/material-payment-status.service";
import {MaterialStoredLocationService} from "../../../../../../_services/material-stored-location.service";

@Component({
  selector: 'app-project-material-form',
  templateUrl: './project-material-form.component.html',
  styleUrls: ['./project-material-form.component.scss'],
  animations: [modalAnimation]
})
export class ProjectMaterialFormComponent implements AfterViewInit, OnDestroy{
  @ViewChildren('graySectionWrapper') graySectionWrapperRefList: QueryList<ElementRef>;
  @ViewChildren('graySectionInnerWrapper') graySectionInnerWrapperRefList: QueryList<ElementRef>;
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  //Material
  materialUnits: MaterialUnit[] = [];
  paymentStatusList: MaterialPaymentStatus[] = []
  storedLocationList: MaterialStoredLocation[] = []
  newSubmittalFiles: FormFile[] = [];
  newPurchasingFiles: FormFile[] = [];
  updatingSectionHeightTimeOut: any;

  isRolledUpFirstSection = false;
  isRolledUpSecondSection = false;

  materialForm: FormGroup;
  isSubmittedForm = false;
  submitBtnDisabled = false;
  materialInitialTag: string;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()
  private _material = new Material();

  get material(): Material {
    return this._material;
  }

  @Input()
  set material(value: Material) {
    if (value) {
      this._material = {...value};
      this.fillMaterialForm();
      this.materialInitialTag = this._material.tag
    }
  }

  constructor(private fb: FormBuilder,
              readonly globalStore: GlobalStore,
              private notifService: NotificationService,
              private materialUnitService: MaterialUnitService,
              private materialPaymentStatusService: MaterialPaymentStatusService,
              private materialStoredLocationService: MaterialStoredLocationService,
              private materialService: MaterialsService) {
    this.fillMaterialForm();
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$
      .pipe(
        tap((projectId) => {
          this.projectId = projectId;
        }),
        switchMap((projectId) =>
          forkJoin({
            materialUnits: this.materialUnitService.getAllMaterialUnits(this.companyId, projectId),
            paymentStatuses: this.materialPaymentStatusService.getAllMaterialPaymentStatuses(this.companyId, projectId),
            storedLocations: this.materialStoredLocationService.getAllMaterialStoredLocations(this.companyId, projectId)
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.materialUnits = data.materialUnits;
        this.paymentStatusList = data.paymentStatuses;
        this.storedLocationList = data.storedLocations;
      });
  }

  ngAfterViewInit() {
    this.graySectionInnerWrapperRefList.get(0).nativeElement.style.maxHeight = this.graySectionInnerWrapperRefList.get(0).nativeElement.scrollHeight + 'px'
    this.graySectionInnerWrapperRefList.get(1).nativeElement.style.maxHeight = this.graySectionInnerWrapperRefList.get(1).nativeElement.scrollHeight + 'px'
  }


  onRollUpSection(sectionNumber: number) {
    switch (sectionNumber) {
      case 1: {
        let firstElInner = this.graySectionInnerWrapperRefList.get(0);
        let firstEl =  this.graySectionWrapperRefList.get(0);
        if(!this.isRolledUpFirstSection) {
          firstElInner.nativeElement.style.maxHeight = firstElInner.nativeElement.scrollHeight + 'px';
        }
        this.isRolledUpFirstSection = !this.isRolledUpFirstSection;
        firstElInner.nativeElement.toggleAttribute('rollUp');
        firstEl.nativeElement.toggleAttribute('rollUp');
        this.updateSectionHeight(sectionNumber);
        break;
      }

      case 2: {
        let secondElInner = this.graySectionInnerWrapperRefList.get(1)
        let secondEl = this.graySectionWrapperRefList.get(1)
        if(!this.isRolledUpSecondSection) {
          secondElInner.nativeElement.style.maxHeight = secondElInner.nativeElement.scrollHeight + 'px';
        }
        this.isRolledUpSecondSection = !this.isRolledUpSecondSection;
        secondElInner.nativeElement.toggleAttribute('rollUp');
        secondEl.nativeElement.toggleAttribute('rollUp');
        this.updateSectionHeight(sectionNumber);
        break;
      }

    }
  }

  updateSectionHeight(sectionNumber: number) {
    switch (sectionNumber) {
      case 1: {
        let firstEl = this.graySectionInnerWrapperRefList.get(0)
        if (this.isRolledUpFirstSection) {
          firstEl.nativeElement.style.maxHeight = '0px';
        }
        else {
          firstEl.nativeElement.style.maxHeight = firstEl.nativeElement.scrollHeight + 'px';
        }
        break
      }
      case 2: {
        let secondEl = this.graySectionInnerWrapperRefList.get(1)
        if (this.isRolledUpSecondSection) secondEl.nativeElement.style.maxHeight = '0px';
        else secondEl.nativeElement.style.maxHeight = secondEl.nativeElement.scrollHeight + 'px';
        break
      }
    }
  }

  //Create New Option (Unit, Payment Status, Stored Location)
  createNewOption(obj: {resetBtn: HTMLElement, createForm: FormGroup}, type: 'unit' | 'paymentStatus' | 'storedLocation') {
    if(obj.createForm.invalid) return;

    if(type === 'unit') {
      this.materialUnitService.createMaterialUnit(this.companyId, this.projectId, obj.createForm.controls['name'].value).subscribe((data) => {
        this.materialUnitService.getAllMaterialUnits(this.companyId, this.projectId).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.materialUnits = data
        })
        this.processSuccessResponse(obj)
      }, (error: HttpErrorResponse) => {
        if (error.status === 400 && error.error.error === 'Name already in use') obj.createForm.controls['name'].setErrors({error: 'Unit with such name already exists'})
      })
    }

    if(type === 'paymentStatus') {
      this.materialPaymentStatusService.createPaymentStatus(this.companyId, this.projectId, obj.createForm.controls['name'].value).subscribe((data) => {
        this.materialPaymentStatusService.getAllMaterialPaymentStatuses(this.companyId, this.projectId).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.paymentStatusList = data
        })
        this.processSuccessResponse(obj)
      }, (error: HttpErrorResponse) => {
        if (error.status === 400 && error.error.error === 'Name already in use') obj.createForm.controls['name'].setErrors({error: 'Payment Status with such name already exists'})
      })
    }

    if(type === 'storedLocation') {
      this.materialStoredLocationService.createStoredLocation(this.companyId, this.projectId, obj.createForm.controls['name'].value).subscribe((data) => {
        this.materialStoredLocationService.getAllMaterialStoredLocations(this.companyId, this.projectId).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.storedLocationList = data
        })
        this.processSuccessResponse(obj)

      }, (error: HttpErrorResponse) => {
        if (error.status === 400 && error.error.error === 'Name already in use') obj.createForm.controls['name'].setErrors({error: 'Stored Location with such name already exists'})
      })
    }
  }

  processSuccessResponse(obj: {resetBtn: HTMLElement, createForm: FormGroup}) {
    this.notifService.successNotification('Changes have been saved');
    obj.createForm.controls["name"].setValue(null);
    obj.createForm.controls['name'].setErrors({invalid: false})

    obj.resetBtn.click();
  }

  changeOption(optionId: number, type: 'unit' | 'paymentStatus' | 'storedLocation') {
    let typesLists = {
      unit: this.materialUnits,
      paymentStatus: this.paymentStatusList,
      storedLocation: this.storedLocationList
    }
    this.material[type] = typesLists[type].find(op => op.id === optionId)
    if(type === 'unit') this.materialForm.controls['unitName'].setValue(this.material.unit.name)
    else this.materialForm.controls[type].setValue(this.material[type].name)

  }

  deleteOption(optionId: number, type: 'unit' | 'paymentStatus' | 'storedLocation') {
    switch (type) {
      case 'unit': {
        this.materialUnitService.deleteMaterialUnit(this.companyId, this.projectId, optionId).subscribe(() => {
          if(this.material.unit?.id === optionId) this.material.unit = null
          this.materialUnits = this.materialUnits.filter(unit => unit.id !== optionId)
          this.notifService.successNotification('Changes have been saved');
        })
        break
      }
      case 'paymentStatus': {
        this.materialPaymentStatusService.deletePaymentStatus(this.companyId, this.projectId, optionId).subscribe(() => {
          if(this.material.paymentStatus?.id === optionId) this.material.paymentStatus = null
          this.paymentStatusList = this.paymentStatusList.filter(paymentStatus => paymentStatus.id !== optionId)
          this.notifService.successNotification('Changes have been saved');
        })
        break
      }
      case 'storedLocation': {
        this.materialStoredLocationService.deleteStoredLocation(this.companyId, this.projectId, optionId).subscribe(() => {
          if(this.material.storedLocation?.id === optionId) this.material.storedLocation = null
          this.storedLocationList = this.storedLocationList.filter(storedLocation => storedLocation.id !== optionId)
          this.notifService.successNotification('Changes have been saved');
        })
        break
      }
    }
  }


  //Submital status
  changeSubmittalStatus(status: SubmittalStatus) {
    this.materialForm.controls['submittalStatus'].setValue(status)
  }

  //OrderStatus
  changeOrderStatus(status: OrderStatus) {
    this.materialForm.controls['orderStatus'].setValue(status)
  }

  //Update files
  updateFiles(files: FormFile[], type: 'oldSubmittal' | 'oldPurchasing' | 'newSubmittal' | 'newPurchasing') {
    switch (type) {
      case "oldSubmittal": {
        this.material.submittalFiles = [...files];
        break
      }
      case "oldPurchasing": {
        this.material.purchasingFiles = [...files];
        break
      }

      case "newSubmittal": {
        this.newSubmittalFiles = [...files];
        break
      }
      case "newPurchasing": {
        this.newPurchasingFiles = [...files];
        break
      }
    }

    if(this.updatingSectionHeightTimeOut) clearTimeout(this.updatingSectionHeightTimeOut)
    this.updatingSectionHeightTimeOut = setTimeout(() => {
      this.updateSectionHeight(1)
      this.updateSectionHeight(2)
    }, 50)
  }


  subscribeToFormChanges() {
    this.materialForm.valueChanges.subscribe(() => {
      this._material.tag = this.materialForm.controls['tag'].value;
      this._material.description = this.materialForm.controls['description'].value;
      this._material.supplier = this.materialForm.controls['supplier'].value;
      this._material.area = this.materialForm.controls['area'].value;
      this._material.submittalStatus = convertMaterialSubmittalStatusToEnum(this.materialForm.controls['submittalStatus'].value);
      this._material.orderStatus = convertMaterialOrderStatusToEnum(this.materialForm.controls['orderStatus']?.value);
      this._material.eta = this.materialForm.controls['etaDate'].value;
      this._material.grossQty = this.materialForm.controls['grossQty'].value;
      this._material.orderQty = this.materialForm.controls['orderQty'].value;
      this._material.orderDate = this.materialForm.controls['orderDate'].value;
      this._material.receivedQty = this.materialForm.controls['receivedQty'].value;
      this._material.notes = this.materialForm.controls['notes'].value;
    })

  }

  fillMaterialForm() {
    this.materialForm = this.fb.group({
      tag: [this._material.tag, [minLengthValidator('NAME'), requiredValidator()]],
      description: [this._material.description || null],
      unitName: [this._material.unit?.name || null, [requiredValidator()]],
      supplier: [this._material.supplier || null, [minLengthValidator('NAME'), requiredValidator()]],
      area: [this._material.area || null, [minLengthValidator('NAME'), requiredValidator()]],
      submittalStatus: [this._material.submittalStatus || null, [requiredValidator()]],
      orderStatus: [this._material.orderStatus || OrderStatus.NOT_ORDERED, [requiredValidator()]],
      etaDate: [this._material.eta || null],
      grossQty: [this._material.grossQty || null],
      orderQty: [this._material.orderQty || null],
      orderDate: [this._material.orderDate || null],
      paymentStatus: [this._material.paymentStatus?.name || null],
      receivedQty: [this._material.receivedQty || null],
      storedLocation: [this._material.storedLocation?.name || null],
      notes: [this._material.notes || null],
    })
    this.subscribeToFormChanges();
  }

  submitForm() {
    this.isSubmittedForm = true;
    if (this.materialForm.invalid) {
      if(this.updatingSectionHeightTimeOut) clearTimeout(this.updatingSectionHeightTimeOut)
      this.updatingSectionHeightTimeOut = setTimeout(() => {
        this.updateSectionHeight(1)
        this.updateSectionHeight(2)
      }, 50)
      return
    }
    this.submitBtnDisabled = true;

    const fd = new FormData();
    fd.append('material', JSON.stringify(this._material))
    // @ts-ignore
    for (const file of this.newSubmittalFiles) fd.append('submittalFiles', file.fileToSave);
    for (const file of this.newPurchasingFiles) fd.append('purchasingFiles', file.fileToSave);


    if (!this.material.id) {
      this.materialService.createMaterial(this.companyId, this.projectId, fd).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
        this.isSubmittedForm = false;
      }, error => this.submitBtnDisabled = false)
    } else {
      this.materialService.updateMaterial(this.companyId, this.projectId, fd).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
        this.isSubmittedForm = false;
      }, error => this.submitBtnDisabled = false);
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly submittalStatusByEnum = submittalStatusStringForSelector
  protected readonly orderStatusByEnum = orderStatusStringForSelector
  protected readonly event = event;
  protected readonly originalOrder = originalOrder;
  protected readonly UserRole = UserRole;
}
