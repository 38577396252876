import {AfterViewInit, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {UsersService} from "../../../../../_services/users.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {User, UserRole} from "../../../../../_models/user";
import {ProjectsService} from "../../../../../_services/projects.service";
import {
  modalAnimation,
} from 'src/app/config/constants';
import {NgSelectComponent} from "@ng-select/ng-select";
import {GlobalStore} from "../../../../../global.store";
import {Subject, Subscription, switchMap, take, takeUntil, tap} from "rxjs";

@Component({
  selector: 'app-add-users-form',
  templateUrl: './add-users-form.component.html',
  styleUrls: ['./add-users-form.component.scss'],
  animations: [modalAnimation]
})
export class AddUsersFormComponent implements OnDestroy {
  @HostBinding('@modalAnimation') modalAnimation: any;

  @Output() created:EventEmitter<any> = new EventEmitter<any>();
  @Output() close:EventEmitter<any> = new EventEmitter<any>();

  private readonly destroy$ = new Subject<void>();

  usersNotInProject: User[] = [];
  submitBtnDisabled = false;
  usersInvitationForm =  this.fb.group({
    users: new FormControl(null, Validators.required),
  })

  companyId: number;
  projectId: number;


  constructor(private fb: FormBuilder,
              private userService: UsersService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore,
              private projectService: ProjectsService) {
      this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
      this.globalStore.projectId$.pipe(
        tap(projectId => this.projectId = projectId),
        switchMap(() => this.userService.getCompanyUsersNotInProject(this.companyId, this.projectId)),
        takeUntil(this.destroy$),
      ).subscribe((users) => {
        this.usersNotInProject = users;
      });
  }

  addAllUsers(event: any) {
    event.preventDefault()
    let usersIds = this.usersNotInProject.map(user => user.id)
    this.usersInvitationForm.controls['users'].setValue(usersIds)
  }

  submitForm() {
    if (this.usersInvitationForm.invalid) return;
    this.submitBtnDisabled = true;

    const users = this.usersInvitationForm.controls["users"].value
    this.projectService.assignEmployeesToProject(this.companyId, this.projectId, users).subscribe(() => {
      this.created.emit();
      this.notifService.successNotification("Changes have been saved")
    }, error => this.submitBtnDisabled = false);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;
}


