 <div class="backdrop">
   <ng-scrollbar #ngScrollbar class="remove-overflow-on-tablet modal" [autoHeightDisabled]="true" [track]="'vertical'">
    <form class="form-invite form-create" autofocus firstInputAutofocus scrollToFirstInvalidInput appAutoTrim #form="ngForm" [formGroup]="companyForm" (ngSubmit)="submitForm()">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <div class="form-modal-way-wrapper" *ngIf="company.id">
        <span class="form-modal-way">{{companyOldName}}</span>
        /
        <span class="form-modal-way project-code-way">{{company.id}}</span>
      </div>
      <div class="form-inner-wrapper" [attr.paddingTop]="!!company.id">
        <p class="form-modal-title">{{!this.company.id ? 'Create Company' : 'Edit Company' }}</p>

        <div class="main-fields-wrapper">

          <p class="main-error" *ngIf="companyForm.invalid && form.submitted"  id="mainErrorText">
            <svg class="error-icon">
              <use spriteVersion iconName="error-icon"></use>
            </svg>
            All fields are required
          </p>

          <div class="field-wrapper">
            <label class="form-label"><span class="label-text">Company Name</span> </label>
            <span class="input-with-error-wrapper">
              <input class="form-input-modal" formControlName="name" formInput="NAME" placeholder="Company Name" id="companyNameMobile">
              <p class="small-error-text-field"  id="companyNameError" *ngIf="companyForm.controls['name'].invalid && form.submitted">{{companyForm.controls['name'].getError('error')}}</p>
            </span>
          </div>

          <div class="group-fields-wrapper">
            <div class="field-wrapper">
              <label class="form-label">Address</label>

              <div class="input-with-error-wrapper">
                <input class="form-input-modal" formControlName="address1" formInput="ADDRESS" placeholder="Street address, P.O. box." id="companyAddressMobile">
                <p class="small-error-text-field" id="companyAddressError" *ngIf="companyForm.controls['address1'].invalid && form.submitted">{{companyForm.controls['address1'].getError('error')}}</p>

                <input class="form-input-modal" id="companyAddressSecond" formInput="ADDRESS" formControlName="address2" placeholder="Apartment, suite, unit, building, floor, etc.">
                <p class="small-error-text-field" id="companyAddressAdditionalError" *ngIf="companyForm.controls['address2'].invalid && form.submitted">{{companyForm.controls['address2'].getError('error')}}</p>

                <div class="flex-field-wrapper">
                  <div class="field-wrapper-shorter">
                    <span class="input-with-error-wrapper">
                      <input class="form-input-modal" id="companyCity" formInput="ADDRESS" formControlName="city" placeholder="City">
                      <p class="small-error-text-field" id="companyCityError" *ngIf="companyForm.controls['city'].invalid && form.submitted">{{companyForm.controls['city'].getError('error')}}</p>
                    </span>
                  </div>

                  <div class="input-with-error-wrapper">
                    <div class="ng-select-wrapper field-wrapper-shorter">
                      <ng-select class="custom select-without-absolute-last-option"
                                 id="companyState"
                                 bindLabel="name"
                                 bindValue="id"
                                 formControlName="state"
                                 [clearable]="false"
                                 notFoundText="No results matching"
                                 [editableSearchTerm]="true"
                                 [searchable]="true"
                                 placeholder="Select State">
                        <ng-option *ngFor="let state of states" [value]="state">{{state}}</ng-option>
                      </ng-select>
                    </div>
                    <p class="small-error-text-field" id="companyStateError" *ngIf="companyForm.controls['state'].invalid && form.submitted">This field is required</p>
                  </div>

                  <div class="field-wrapper-shorter">
                    <span class="input-with-error-wrapper">
                      <input class="form-input-modal"  id="companyZipCode" [mask]="'00000'" formInput="ZIP_CODE" formControlName="zipCode" placeholder="ZIP Code">
                      <p class="small-error-text-field" id="companyZipCodeError" *ngIf="companyForm.controls['zipCode'].invalid && form.submitted">{{companyForm.controls['zipCode'].getError('error')}}</p>
                    </span>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="group-fields-wrapper">
            <div class="field-wrapper phone-number-wrapper">
              <label class="form-label project-optional-label">Phone Number</label>
              <div class="phone-with-code-wrapper">
                <app-countries-code-select
                  [countryCode]="company.countryCode"
                  [formControl]="$any(companyForm).controls['countryCode']"
                  (changeCountryCode)="changeCountryCode($event)"
                  [isError]="companyForm.controls['phone'].invalid && form.submitted"></app-countries-code-select>
                <span class="input-with-error-wrapper">
                  <input class="form-input-modal" id="companyPhone" [mask]="phoneMask" formControlName="phone" placeholder="(000) 000-0000">
                  <p class="small-error-text-field" *ngIf="companyForm.controls['phone'].invalid && form.submitted">{{companyForm.controls['phone'].getError('error')}}</p>
                </span>
              </div>
            </div>

            <div class="field-wrapper">
              <label class="form-label project-optional-label">Email</label>
              <span class="input-with-error-wrapper">
                <input class="form-input-modal" id="companyEmail" formInput="EMAIL" formControlName="email" placeholder="Email">
                <p class="small-error-text-field" id="companyEmailErrorInvalid" *ngIf="companyForm.controls['email'].invalid && form.submitted">{{companyForm.controls['email'].getError('error')}}</p>
              </span>
            </div>
          </div>


          <div class="group-fields-wrapper" *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN || currentUser.role === UserRole.ROLE_ADMIN">
            <div class="field-wrapper">
              <label class="form-label"><span class="label-text">Company Plan</span></label>
              <span class="input-with-error-wrapper">
                <div class="ng-select-wrapper ng-select-wrapper--wider">
                  <ng-select class="custom select-without-absolute-last-option"
                             id="companyPlan"
                             formControlName="companyPlan"
                             [clearable]="false"
                             notFoundText="No results matching"
                             [editableSearchTerm]="true"
                             [searchable]="true"
                             [items]="companyPlans"
                             bindLabel="planName"
                             placeholder="Select Company Plan">
                  </ng-select>
                </div>
                <p class="small-error-text-field" id="companyPlanError" *ngIf="companyForm.controls['state'].invalid && form.submitted">This field is required</p>
              </span>
            </div>

            <div class="field-wrapper">
              <label class="form-label"><span class="label-text">Number of users</span> </label>
              <input class="form-input-modal" formInput="CUSTOM_PLAN_MAX_USERS"
                     [mask]="'0||00||000||0000||00000||000000'"
                     id="companyPlanMaxNumberUsers"
                     formControlName="maxUsers"
                     placeholder="Number of users">
              <p class="small-error-text-field" id="companyPlanMaxNumberUsersError" *ngIf="companyForm.controls['maxUsers'].invalid && form.submitted">{{companyForm.controls['maxUsers'].getError('error')}}</p>
            </div>

            <div class="field-wrapper">
              <label class="form-label"><span class="label-text">Subdomain name</span> </label>
              <span class="input-with-error-wrapper">
                <input class="form-input-modal" formControlName="subdomain"
                     (input)="onInputChange($event)"
                     formInput="NAME" placeholder="Subdomain name" id="subdomainName">
                <p class="small-error-text-field"  id="subdomainNameError" *ngIf="companyForm.controls['subdomain'].invalid && form.submitted">{{companyForm.controls['subdomain'].getError('error')}}</p>
              </span>
            </div>
          </div>

          <div class="field-wrapper">
            <label class="form-label">Type</label>
            <span class="input-with-error-wrapper">
              <div class="ng-select-wrapper ng-select-wrapper--wider">
                <ng-select class="custom select-typeCompany"
                           id="companyType"
                           bindLabel="name"
                           bindValue="id"
                           formControlName="type"
                           [clearable]="false"
                           notFoundText="No results matching"
                           [editableSearchTerm]="true"
                           [searchable]="true"
                           (open)="selectorOpened()"
                           placeholder="Select Company Type">
                  <ng-option *ngFor="let type of companyTypeByEnum | keyvalue" [value]="type.key">{{type.value}}</ng-option>
                </ng-select>
              </div>
              <p class="small-error-text-field"  id="companyTypeError" *ngIf="companyForm.controls['type'].invalid && form.submitted">This field is required</p>
            </span>
          </div>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel"  id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" [disabled]="submitBtnDisabled" id="btnSubmit" loader="white" [showLoader]="submitBtnDisabled">{{!this.company.id ? 'Create Company' : 'Update Company'}}</button>
      </div>
    </form>
   </ng-scrollbar>
</div>
