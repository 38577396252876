import {AfterViewInit, Component, isDevMode, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {User, UserRole} from "../../../../_models/user";
import {UsersService} from "../../../../_services/users.service";
import {roleDescByEnumForTable, SortType, userRoleStringForSelector} from 'src/app/config/constants';
import {ProjectsService} from "../../../../_services/projects.service";
import * as events from "events";
import {NotificationService} from "../../../../_services/notification.service";
import {NgSelectComponent} from "@ng-select/ng-select";
import {Page} from "../../../../_models/page";
import {Company} from "../../../../_models/company";
import {CompaniesService} from "../../../../_services/companies.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {GlobalStore} from "../../../../global.store";
import {Subject, Subscription, take, takeUntil} from "rxjs";
import {DomainsService} from "../../../../_services/domains.service";
import {environment} from "../../../../../environments/environment";

const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [animate(300)])
]);

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [fadeIn]
})
export class UsersComponent implements AfterViewInit, OnDestroy {
  @ViewChildren(NgSelectComponent) ngSelectComponent: QueryList<NgSelectComponent>;
  currentUser: User;
  addUsersFormOpened = false;
  inviteFormOpened = false;
  deletingPopupOpened = false;
  deletingBtnDisabled = false;
  userEditFormOpened = false;
  usersPage: Page<User>;
  selectedUser: User;
  isMultiSelectAll = false;
  isLoading = false;

  sorting: any = {};
  query: string;

  pageNumber = 0;
  pageSizeOnDesktop = 20;
  pageSizeOnMobile: number;

  selectedUserRole: any;
  selectedCompanyId: any;

  companiesFilterValues: Company[] = [];
  multiSelectEnabled = false;
  multiSelectedUsers: number[] = [];

  isGlobalUsersPage = false;
  isCompanyUsersPage = false;
  isProjectUsersPage = false;

  isMobile = this.globalStore.isMobile$;
  isIOS = this.globalStore.isIOS$;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private userService: UsersService,
              private companyService: CompaniesService,
              public projectService: ProjectsService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore,
              private domainService: DomainsService) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => this.currentUser = user);
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => {
      this.companyId = companyId;
      this.isGlobalUsersPage = !this.companyId;
      this.isCompanyUsersPage = this.companyId && !this.projectId;
      this.isProjectUsersPage = !!this.companyId && !!this.projectId;
    });
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => {
      this.projectId = projectId;
      this.isGlobalUsersPage = !this.companyId;
      this.isCompanyUsersPage = this.companyId && !this.projectId;
      this.isProjectUsersPage = !!this.companyId && !!this.projectId;
    });
  }

  ngAfterViewInit() {
    this.getUsersList();

    if (!this.companyId) this.companyService.getShortCompanyList().subscribe((data) => this.companiesFilterValues = data);
  }

  getUsersList() {
    this.isLoading = true;
    this.multiSelectedUsers = [];
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    if (this.projectId) this.userService.getCompanyUsersInProjectByParams(this.companyId, this.projectId, this.selectedUserRole, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe((usersPage) => this.checkPageResults(usersPage));
    else if (this.companyId) this.userService.getUserListByCompanyIdAndParams(this.companyId, this.selectedUserRole, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe((usersPage) => this.checkPageResults(usersPage));
    else this.userService.getUsersListByParams(this.selectedCompanyId, this.selectedUserRole, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe((usersPage) => this.checkPageResults(usersPage));
  }

  checkPageResults(page: Page<User>) {
    if (page.totalPages > 0 && this.pageNumber + 1 > page.totalPages) {
      this.pageNumber = page.totalPages - 1;
      this.getUsersList();
    } else {
      this.usersPage = page
      this.isLoading = false;
    }
  }

  changeShowNumber(event: any) {
    this.pageSizeOnDesktop = event;
    this.pageNumber = 0;
    this.pageSizeOnMobile = event
    this.getUsersList();
  }
  changeCurrentPage(event: any) {
    this.pageNumber = event;
    this.getUsersList();
    this.isMultiSelectAll = false;
  }


  getUserById(userId: number) {
    this.userService.getUserById(userId).subscribe((task) => this.selectedUser = task);
  }

  removeUserFromProject() {
    this.projectService.removeEmployeeFromProject(this.companyId, this.projectId, this.selectedUser.id).subscribe(() => {
      this.deletingPopupOpened = false;
      this.notifService.successNotification("Changes have been saved");
      this.getUsersList();
    })
  }

  removeUsersFromProject() {
    this.projectService.removeEmployeesFromProject(this.companyId, this.projectId, this.multiSelectedUsers).subscribe(() => {
      this.deletingPopupOpened = false;
      this.notifService.successNotification("Changes have been saved");
      this.getUsersList();
    })
  }

  isShowDotsBtn(userId: number, userRole: UserRole) {
    if (this.currentUser.role === UserRole.ROLE_EMPLOYEE && userId !== this.currentUser.id) return false;
    if (userId === this.currentUser.id) return true;

    if (this.projectId) {
      if (this.currentUser.role === UserRole.ROLE_PROJECT_MANAGER) return userRole === UserRole.ROLE_EMPLOYEE;
      return true;
    }
    else return this.currentUser.role === UserRole.ROLE_SUPERADMIN || this.currentUser.role === UserRole.ROLE_ADMIN;
  }

  isShowDeleteBtn(userId: number, userRole: UserRole) {
    if (userId === this.currentUser.id) return false;

    if (this.projectId) {
      if (userRole === UserRole.ROLE_ADMIN) return false;

      if (this.currentUser.role === UserRole.ROLE_PROJECT_MANAGER) return userRole === UserRole.ROLE_EMPLOYEE;
      else if (this.currentUser.role === UserRole.ROLE_ADMIN) return userRole === UserRole.ROLE_EMPLOYEE || userRole === UserRole.ROLE_PROJECT_MANAGER;
      return true;
    } else return this.currentUser.role === UserRole.ROLE_SUPERADMIN || this.currentUser.role === UserRole.ROLE_ADMIN;
  }

  toggleCheckAll() {
    this.multiSelectedUsers = [];
    if(this.isMultiSelectAll) {
      this.isMultiSelectAll = false;
    }
    else {
      this.usersPage.content.map(user => {
        if(this.isShowDeleteBtn(user.id, user.role) && this.currentUser.id !== user.id) {
          this.multiSelectedUsers.push(user.id)
        }
        else return
      })
      this.isMultiSelectAll = true
    }
  }

  toggleCheck(userId: number) {
    if (this.multiSelectedUsers.includes(userId)) this.multiSelectedUsers.splice(this.multiSelectedUsers.indexOf(userId), 1);
    else this.multiSelectedUsers.push(userId);

    this.multiSelectEnabled = this.multiSelectedUsers.length > 0;
  }

  deleteUser(userId: number) {
    this.deletingBtnDisabled = true;
    this.userService.deleteUser([userId]).subscribe(() => {
      this.notifService.successNotification('Changes have been saved');
      this.getUsersList();
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  deleteAllSelected() {
    this.deletingBtnDisabled = true;
    this.userService.deleteUser(this.multiSelectedUsers).subscribe(() => {
      this.getUsersList();
      this.deletingPopupOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getUsersList();
  }

  clearFilters() {
    this.ngSelectComponent.forEach(e => e.handleClearClick());
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedUserRole) result++;
    if (this.selectedCompanyId) result++;

    return result;
  }

  isNoFilteringEnabled() {
    return !this.selectedUserRole && !this.selectedCompanyId && (!this.query || this.query.length === 0);
  }

  closeDeletingPopup() {
    this.deletingPopupOpened = false;
    setTimeout(this.selectedUser = undefined, 250);
  }

  userInvited() {
    this.inviteFormOpened = false;
    this.getUsersList();
  }

  generateUserRouterLink(user: User): any {
    if (!user.name) return null;
    if (this.currentUser.role === UserRole.ROLE_EMPLOYEE) {
      if (user.id === this.currentUser.id) return ['/system/company', user.company.id, 'profile'];
      return null;
    }
    return this.currentUser.role === UserRole.ROLE_SUPERADMIN && !this.companyId ? ['/system/users', user.id] : ['/system/company', user.company.id, 'users', user.id]
  }

  inviteUsersButtonClicked() {
    if (this.companyId && environment.production) {
      this.globalStore.company$.pipe(take(1)).subscribe((company) => {
        this.domainService.checkSite(company.subdomain).subscribe((result) => {
          if (result) this.inviteFormOpened = true;
          else alert("Please first register subdomain with name - " + company.subdomain)
        })
      })
    } else this.inviteFormOpened = true;
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly SortType = SortType;
    protected readonly UserRole = UserRole;
  protected readonly roleDescByEnumForTable = roleDescByEnumForTable;
  protected readonly events = events;
  protected readonly userRoleStringForSelector = userRoleStringForSelector;
  protected readonly Company = Company;
  protected readonly User = User;
}
