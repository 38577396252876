import {User} from "./user";
import {Project} from "./project";
import {Company} from "./company";

export class Task {
  id: number;
  name: string;
  publicId: string;
  member: User;
  description: string;
  priority: TaskPriority;
  status: TaskStatus;
  dueDate: number;
  files: TaskFile[] = [];
  hasAttachments: boolean;
  version: number;
  company: Company;
  project: Project;
  creator: User;
}

export class TaskFile {
  id: number;
  name: string;
  sizeBytes: number;
  fileUrl: string;
  fileToSave: any;
  progress: number;
  timeLeft: number;
  imgSrc: string;
}

export enum TaskStatus {
  READY = "READY",
  TO_DO = "TO_DO",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}

export enum TaskPriority {
  NO_PRIORITY = "NO_PRIORITY",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}
