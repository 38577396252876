import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserRole} from "../../../../../../../_models/user";
import {HttpErrorResponse} from "@angular/common/http";
import {FormBuilder, FormGroup} from "@angular/forms";
import {minLengthValidator, requiredValidator} from "../../../../../../../_util/validators";
import {FloorsService} from "../../../../../../../_services/floors.service";
import {GlobalStore} from "../../../../../../../global.store";
import {ProjectViewStore} from "../../project-view.store";
import {NotificationService} from "../../../../../../../_services/notification.service";
import {Floor} from "../../../../../../../_models/floor";
import {modalAnimation} from "../../../../../../../config/constants";

@Component({
  selector: 'app-floors-swiper-el',
  templateUrl: './floors-swiper-el.component.html',
  styleUrls: ['./floors-swiper-el.component.scss'],
  animations: [modalAnimation]
})
export class FloorsSwiperElComponent {
  @Input() floorsShortList: Floor[] = [];
  @Input() floor: Floor;
  @Input() currentFloor: Floor;
  @Input() isCdkDragDisabled: boolean;

  @Output() changeFloor: EventEmitter<any> = new EventEmitter<any>()
  @Output() deleteFloor: EventEmitter<any> = new EventEmitter<any>()

  isRighterThenMiddleOfScreen: boolean;
  isFloorMenuOpen: boolean = false;
  isFloorEditFormOpen: boolean = false;

  editFloorForm: FormGroup = this.fb.group({
    name: [null, [minLengthValidator('FLOOR_UNIT_NAME'), requiredValidator()]]
  })

  constructor(private floorsService: FloorsService,
              private fb: FormBuilder,
              readonly globalStore: GlobalStore,
              readonly projectViewStore: ProjectViewStore,
              private notifService: NotificationService) {
  }

  deletingFloor(floor: Floor) {
    if(this.floorsShortList.length === 1) return;

    this.deleteFloor.emit(floor)
    this.isFloorMenuOpen = false;
  }

  updateFloorAndRemoveOpenedAttr(floor: Floor) {
    if (this.editFloorForm.invalid) return;
    if (floor.name === this.editFloorForm.controls['name'].value) {
      this.notifService.successNotification('Changes have been saved')
      this.isFloorEditFormOpen = false;
      this.projectViewStore.updateIsCdkDragDisabled(false);
      return;
    }
    this.floorsService.updateFloorNameById(floor.id, this.projectViewStore.companyId, this.projectViewStore.projectId, this.editFloorForm.controls['name'].value, floor.version).subscribe(() => {
      floor.name = this.editFloorForm.controls['name'].value;
      floor.version++;
      this.notifService.successNotification('Changes have been saved')
      this.projectViewStore.updateCurrentFloorNameAndVersion(floor);
      this.projectViewStore.updateIsCdkDragDisabled(false);
      this.isFloorEditFormOpen = false;
    }, (error: HttpErrorResponse) => {
      if (error.status === 400 && error.error.error === 'Name already in use') this.editFloorForm.controls['name'].setErrors({error: 'Floor with such name already exists'})
    } )
  }

    protected readonly UserRole = UserRole;
}
