import {
  Component,
  OnDestroy,
  QueryList,
  ViewChildren
} from "@angular/core";
import {User, UserRole} from "../../../../../_models/user";
import {SortType, taskStatusByEnum} from "src/app/config/constants";
import {Task, TaskPriority, TaskStatus} from "../../../../../_models/task";
import {TasksService} from "../../../../../_services/tasks.service";
import {originalOrder} from "../../../../../_util/utils";
import {NgSelectComponent} from "@ng-select/ng-select";
import {Page} from "../../../../../_models/page";
import {UsersService} from "../../../../../_services/users.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalStore} from "../../../../../global.store";
import {filter, Subject, switchMap, takeUntil, tap} from "rxjs";

const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [animate(300)]), // 300 мілісекунд для прикладу, ви можете змінити це значення
]);

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  animations: [fadeIn]
})
export class TasksComponent implements OnDestroy {
  @ViewChildren(NgSelectComponent) ngSelectComponent: QueryList<NgSelectComponent>;

  sorting: any = {};
  query: string;
  hideCompleted = false;
  pageNumber = 0;
  pageSizeOnDesktop = 20;
  pageSizeOnMobile: number;
  isLoading = false;

  tasksPage: Page<Task>;
  multiSelectedTasks: number[] = [];
  multiSelectEnabled = false;
  taskFormOpened = false;
  deletingPopupOpened = false;
  deletingBtnDisabled = false;
  isMultiSelectAll = false;

  selectedTask: Task;

  currentUser = this.globalStore.currentUser$;

  membersList: User[] = [];
  selectedMemberId: number;

  selectedStatus: TaskStatus;

  isMobile= this.globalStore.isMobile$;
  isIOS = this.globalStore.isIOS$;
  includeSelfCreatedTask = true;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private taskService: TasksService,
              private userService: UsersService,
              private notifService: NotificationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);

    this.globalStore.projectId$.pipe(
      takeUntil(this.destroy$),
      filter((projectId) => !!projectId),
      switchMap((projectId) => {
        this.projectId = projectId;
        return this.userService.getConfirmedEmployeesOnProjectShort(this.companyId, this.projectId).pipe(
          tap(data => this.membersList = data),
          switchMap(() => this.activatedRoute.queryParams),
          tap(params => {
            let queryParams = params['params'];
            if (queryParams) this.getTaskById(Number(params['params']), true);
          })
        );
      })
    ).subscribe(() => this.getTasksList());
  }

  addAttrsToFilterByStatusDropdownOptions() {
    setTimeout(() => document.querySelectorAll('#filterByStatus .ng-option-label').forEach(e => e.setAttribute('data-val', e.textContent.trim().toLowerCase())));
  }

  getTasksList() {
    this.isLoading = true;
    this.multiSelectedTasks = [];
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    if(this.selectedMemberId) this.includeSelfCreatedTask = false

      this.taskService.getTasksListByParams(this.companyId, this.projectId, this.hideCompleted, this.includeSelfCreatedTask, this.selectedMemberId, this.selectedStatus, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe(data => {
        if (data.totalPages > 0 && this.pageNumber + 1 > data.totalPages) {
          this.pageNumber = data.totalPages - 1;
          this.getTasksList();
        } else {
          this.tasksPage = data;
          this.isLoading = false;
        }
      });
  }

  changeShowNumber(newNumber: number) {
    this.pageSizeOnDesktop = newNumber;
    this.pageNumber = 0;
    this.pageSizeOnMobile = newNumber;
    this.getTasksList();
  }

  changeCurrentPage(newPage: number) {
    this.pageNumber = newPage;
    this.getTasksList();
    this.isMultiSelectAll = false;
  }

  getTaskById(taskId: number, isOpenViewTask?: boolean) {
    this.taskService.getTaskById(taskId, this.companyId, this.projectId).subscribe((task) => {
      this.selectedTask = task
      if(this.selectedTask.id && isOpenViewTask) this.taskFormOpened = true;
    });
  }

  updateTaskStatus(task: Task, newStatus: TaskStatus) {
    this.taskService.updateStatusTask(this.companyId, this.projectId, task.id, newStatus).subscribe(() => {
      task.status = newStatus;
      this.tasksPage.content.filter(e => e.id === task.id)[0].status = newStatus;
      this.notifService.successNotification('Changes have been saved');
    });
  }

  deleteTask(taskId: number) {
    this.deletingBtnDisabled = true;
    this.taskService.deleteTasks([taskId], this.companyId, this.projectId).subscribe(() => {
      this.getTasksList();
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  toggleCheckAll() {
    this.multiSelectedTasks = [];
    if(this.isMultiSelectAll) {
      this.isMultiSelectAll = false;
    }
    else {
      this.tasksPage.content.map(task => this.multiSelectedTasks.push(task.id))
      this.isMultiSelectAll = true
    }
  }

  toggleCheck(taskId: number) {
    if (this.multiSelectedTasks.includes(taskId)) this.multiSelectedTasks.splice(this.multiSelectedTasks.indexOf(taskId), 1);
    else this.multiSelectedTasks.push(taskId);

    this.multiSelectEnabled = this.multiSelectedTasks.length > 0;
  }

  deleteSelected() {
    this.deletingBtnDisabled = true;
    this.taskService.deleteTasks(this.multiSelectedTasks, this.companyId, this.projectId).subscribe(() => {
      this.getTasksList()
      this.deletingPopupOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    } ,error => this.deletingBtnDisabled = false)
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedMemberId) result++;
    if (this.selectedStatus) result++;

    return result;
  }

  clearFilters() {
    this.ngSelectComponent.forEach(e => e.handleClearClick());
  }

  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getTasksList();
  }

  isNoFilteringEnabled() {
    return this.getFiltersActiveAmount() === 0 && !this.hideCompleted && (!this.query || this.query.length === 0);
  }

  closeDeletingPopup() {
    this.deletingPopupOpened = false;
    setTimeout(() => this.selectedTask = undefined, 250);
  }

  closeTaskInfoPopup() {
    this.taskFormOpened = false;
    setTimeout(() => this.selectedTask = undefined, 250);
    this.router.navigate([], { queryParams: {} })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly taskStatusByEnum = taskStatusByEnum;
  protected readonly SortType = SortType;
  protected readonly UserRole = UserRole;
  protected readonly originalOrder = originalOrder;
  protected readonly TaskPriority = TaskPriority;
}









