import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {Unit} from "../_models/unit";

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  constructor(private http: HttpClient) { }

  createUnit(companyId: number, projectId: number, floorId: number, unitName: string | null, duplicateUnitId: number | null) {
    let params = new HttpParams()
    if (unitName) params =  params.append('unitName', unitName)
    if (duplicateUnitId) params = params.append('duplicateUnitId', duplicateUnitId)
    return this.http.post<Unit>(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit`, null,  {params: params, withCredentials: true})
  }

  updateUnitNameById(companyId: number, projectId: number, floorId: number, unitId: number, unitName: string, version: number) {
    let params = new HttpParams().append('newName', unitName).append('version', version);
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/name`, null, {params: params, withCredentials: true})
  }

  updateUnitOrderById( companyId: number, projectId: number, floorId: number, unitId: number, newOrder: number,) {
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/order?newOrder=${newOrder}`, null, {withCredentials: true})
  }

  deleteUnitById(companyId: number, projectId: number, floorId: number,  unitId: number,) {
    let params = new HttpParams();
    params = params.append('id', unitId);
    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit`, {params: params, withCredentials: true})
  }
}
