import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input, OnDestroy, Output,
  ViewChild
} from '@angular/core';
import {TaskFile} from "../../../../../../../../_models/task";
import "quill-mention";
import Quill from "quill";
import {QuillEditorComponent} from "ngx-quill";
import {AreasService} from "../../../../../../../../_services/areas.service";
import {AreaComment} from "../../../../../../../../_models/areaComment";
import {ProjectsService} from "../../../../../../../../_services/projects.service";
import {NotificationService} from "../../../../../../../../_services/notification.service";
import {SelectedArea} from "../../../../types";
import {GlobalStore} from "../../../../../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";
import {waitForElm} from "../../../../../../../../_util/utils";
var icons = Quill.import('ui/icons');
icons['bold'] = '<svg><use href="assets/sprite.svg#bold-icon"></use></svg>';
icons['italic'] = '<svg><use href="assets/sprite.svg#italic-icon"></use></svg>';
icons['underline'] = '<svg><use href="assets/sprite.svg#underline-icon"></use></svg>';
icons['strike'] = '<svg><use href="assets/sprite.svg#strike-icon"></use></svg>';
icons['list']['ordered'] = '<svg><use href="assets/sprite.svg#ordered-list"></use></svg>';
icons['list']['bullet'] = '<svg><use href="assets/sprite.svg#unordered-list"></use></svg>';
icons['link'] = '<svg><use href="assets/sprite.svg#link-icon"></use></svg>';

@Component({
  selector: 'app-area-editor',
  templateUrl: './area-editor.component.html',
  styleUrls: ['./area-editor.component.scss']
})
export class AreaEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;
  @ViewChild('fileInput') fileInputRef: ElementRef;
  @Input() selectedArea: SelectedArea;
  @Output() createComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() editComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  _comment: AreaComment;

  companyId: number;
  projectId: number;

  get comment() {
    return this._comment;
  }

  @Input()
  set comment(value: AreaComment) {
    this._comment = JSON.parse(JSON.stringify(value));
  }

  randEditorId = Math.floor(Math.random() * (1000000 - 2) + 1);

  quill: Quill;

  errorUploadFileSize = false;
  errorUploadFilesAmount = false;
  commentsFile: TaskFile[] = [];
  isShowEditorMenu = false;
  submitBtnDisabled = false;
  showLoader = false;
  imageFormatsToRenderImage: string[] = ['jpeg', 'png', 'svg', 'webp', 'jpg'];

  private readonly destroy$ = new Subject<void>()

  constructor(private areasService: AreasService,
              private projectService: ProjectsService,
              readonly globalStore: GlobalStore,
              public notifService: NotificationService) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.projectId = projectId);
  }

  isFileCanBePreviewed(fileName: string) {
    let result = false;
    this.imageFormatsToRenderImage.forEach(e => {
      if (fileName.endsWith(e)) {
        result = true;
        return;
      }
    })
    return result;
  }

  toggleStatusLoadingFile(files: any) {
    for (let file of files) {
      if (file.size > 52428800) {
        this.errorUploadFileSize = true;
        return
      }

      this.errorUploadFileSize = false;

      if (this.commentsFile.length + (this.comment ? this.comment.files.length : 0) === 10) {
        this.errorUploadFilesAmount = true;
        return
      }
      this.errorUploadFilesAmount = false;
      const taskFile = new TaskFile()
      taskFile.name = file.name;
      taskFile.sizeBytes = file.size;
      taskFile.fileToSave = file;

      this.commentsFile.push(taskFile)

      if (files.length !== 0) {
        const reader = new FileReader();

        reader.onloadstart = (e) => taskFile.timeLeft = Math.floor(e.timeStamp / 1000);
        reader.onprogress = (e) => {
          if (e.lengthComputable) taskFile.progress = Math.floor((e.loaded / e.total) * 100);
        };

        reader.onload = (e) => {
          const binaryString = Array.from(new Uint8Array(e.target.result as ArrayBuffer)).map(byte => String.fromCharCode(byte)).join('');
          if(file.name.includes('png') || file.name.includes('jpg') || file.name.includes('jpeg'))taskFile.imgSrc = 'data:image/png;base64,' + btoa(binaryString);
        }

        reader.readAsArrayBuffer(file);
        this.fileInputRef.nativeElement.removeAttribute('open')
      }
    }
  }

  removeFile(file: TaskFile) {
    if(this.showLoader) return
    let objIdInFilesToSend = this.commentsFile.indexOf(file);

    if (objIdInFilesToSend !== -1) {
      this.commentsFile.splice(objIdInFilesToSend, 1);
    }
    else this.comment.files.splice(this.comment.files.indexOf(file), 1);
    this.fileInputRef.nativeElement.value = '';
  }

  getSumCommentFiles() {
    if(!!this.comment) {
      return [...this.comment.files, ...this.commentsFile]
    }
    return this.commentsFile
  }

  cancelComment() {
    this.close.emit();
    this.isShowEditorMenu = false;
    if(!this.comment) this.quill.root.innerHTML = '';
    this.errorUploadFilesAmount = false;
    this.errorUploadFileSize = false;
    this.commentsFile = [];
  }

  ngAfterViewInit() {
    this.quill = new Quill(`#editor-${this.randEditorId}`, {
      modules: {
        toolbar: `#toolbar-${this.randEditorId}`,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@"],
          positioningStrategy: 'fixed',
          source: (searchTerm: string, renderList: any) => {
            this.projectService.getProjectMentionUsers(this.companyId, this.projectId, searchTerm).subscribe((data) => {
              // @ts-ignore
              data.forEach(e => e.value = e.name);
              renderList(data, searchTerm);
              waitForElm('ul#quill-mention-list').then(() => {
                let htmlListEls = document.querySelectorAll('ul#quill-mention-list li');
                for (let i = 0; i < htmlListEls.length; i++) {
                  htmlListEls.item(i).innerHTML = `<p>${data[i].name}</p><span>${data[i].email}</span>`
                }
              })
            });
          }
        },
      },
      placeholder: 'Write a comment...',
      theme: 'snow'
    });

    if(this.comment) this.quill.pasteHTML(0, this.comment.html)
  }

  clearAfterSubmit() {
    this.quill.root.innerHTML = '';
    this.commentsFile = [];
    this.showLoader  = false;
    this.submitBtnDisabled = false;
  }

  sendComment() {
    if(this.quill.root.innerText.trim().toString() === '' && ((!this.comment?.files.length || this.comment?.files.length === 0) && this.commentsFile.length === 0)) return
    let comment = new AreaComment();
    comment.html = this.quill.root.innerText.trim().toString() === '' ? '' : this.quill.root.innerHTML;
    comment.timestamp = new Date().getTime();
    this.submitBtnDisabled = true;
    this.showLoader  = true;
    this.errorUploadFilesAmount = false;
    this.errorUploadFileSize = false;

    if(this.comment) {
      comment.id = this.comment.id
      comment.files = this.comment.files;
    }
    const fd = new FormData();
    fd.append('comment', JSON.stringify(comment))
    // @ts-ignore
    for (const file of this.commentsFile) fd.append('files', file.fileToSave)

    if(this.comment) {
      this.areasService.editAreaComment(this.companyId, this.projectId, this.selectedArea.floorId, this.selectedArea.unitId, this.selectedArea.roomId, this.selectedArea.area.id, fd).subscribe(data => {
        this.notifService.successNotification('Changes have been saved')
        this.editComment.emit(data);
        this.close.emit();
        this.clearAfterSubmit()
      })
    } else {
      this.areasService.createAreaComment(this.companyId, this.projectId, this.selectedArea.floorId, this.selectedArea.unitId, this.selectedArea.roomId, this.selectedArea.area.id, fd).subscribe((data) => {
        this.notifService.successNotification('Changes have been saved')
        this.createComment.emit(data)
        this.clearAfterSubmit()
        this.isShowEditorMenu = false;
      })
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
