import {
  Component,
  EventEmitter,
  HostBinding,
  Input, isDevMode,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CompaniesService} from "../../../../../_services/companies.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {Company, CompanyPlan} from "../../../../../_models/company";
import * as americanStates from "../../../../../../assets/data/usa_states_cities.json";
import {companyTypeByEnum, modalAnimation, phoneMask} from 'src/app/config/constants';
import {NgSelectComponent} from "@ng-select/ng-select";
import {emailValidatorByPattern, minLengthValidator, requiredValidator} from "../../../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";
import {CompanyPlanService} from "../../../../../_services/companyPlan.service";
import {User, UserRole} from "../../../../../_models/user";
import {NgScrollbar} from "ngx-scrollbar";
import {GlobalStore} from "../../../../../global.store";
import {take} from "rxjs";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
  animations: [modalAnimation]
})
export class CompanyFormComponent {
  @HostBinding('@modalAnimation') modalAnimation: any;
  private _company = new Company();
  states: string[] = Object.keys(americanStates).filter(state => state !== 'default')
  submitBtnDisabled = false;
  companyPlans: CompanyPlan[] = []
  currentUser: User;
  emailError = false;
  nameError = false;
  subdomainError = false;
  companyOldName: string;

  get company(): Company {
    return this._company;
  }

  @Input()
  set company(value: Company) {
    if (value) {
      this._company = JSON.parse(JSON.stringify(value));
      this.companyOldName = value.name
      this.fillCompanyForm();
    }
  }

  companyForm: FormGroup;
  @Output() created:EventEmitter<any> = new EventEmitter<any>();
  @Output() close:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('companySelectedCity') companySelectedCity: NgSelectComponent;
  @ViewChild('ngScrollbar') ngScrollbar: NgScrollbar;

  constructor(private fb: FormBuilder,
              readonly globalStore: GlobalStore,
              private companyService: CompaniesService,
              private notifService: NotificationService,
              private companyPlanService: CompanyPlanService) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user: User) => this.currentUser = user);
    this.companyPlanService.getCompanyPlans().subscribe((data: CompanyPlan[]) => {
      this.companyPlans = data
    })
    this.fillCompanyForm();
  }

  fillCompanyForm() {
    this.companyForm = this.fb.group({
      id: [{ value: this.company.id, disabled: true }],
      name: [this.company.name, [minLengthValidator('NAME'), requiredValidator()]],
      address1: [this.company.address1, [minLengthValidator('ADDRESS'), requiredValidator()]],
      address2: [this.company.address2],
      state: [this.company.state, requiredValidator()],
      city: [this.company.city, requiredValidator()],
      zipCode: [this.company.zipCode, [minLengthValidator('ZIP_CODE'), requiredValidator()]],
      countryCode: [this.company.countryCode],
      phone: [this.company.phoneNumber, [minLengthValidator('PHONE'), requiredValidator()]],
      email: [this.company.email, [emailValidatorByPattern(), requiredValidator()]],
      companyPlan: [{value: this.company.companyPlan || null, disabled: this.currentUser.role === UserRole.ROLE_ADMIN}, requiredValidator()],
      maxUsers: [{value: this.company.companyPlan?.maxUsers || null, disabled: (this.company.companyPlan && this.company.companyPlan.planName !== "Custom") || !this.company.companyPlan || this.currentUser.role === UserRole.ROLE_ADMIN}, [minLengthValidator('CUSTOM_PLAN_MAX_USERS'), requiredValidator()] ],
      subdomain: [{value: this.company.subdomain, disabled: !!this.company.id}, [minLengthValidator('NAME'), requiredValidator()]],
      type: [this.company.type, Validators.required]
    })
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(){
    this.companyForm.valueChanges.subscribe(() => {
      this._company.name = this.companyForm.controls['name'].value;
      this._company.address1 = this.companyForm.controls['address1'].value;
      this._company.address2 = this.companyForm.controls['address2'].value;
      this._company.state = this.companyForm.controls['state'].value;
      this._company.city = this.companyForm.controls['city'].value;
      this._company.zipCode = this.companyForm.controls['zipCode'].value;
      this._company.phoneNumber = this.companyForm.controls['phone'].value;
      this._company.email = this.companyForm.controls['email'].value;
      this._company.subdomain = this.companyForm.controls['subdomain'].value;
      this._company.type = this.companyForm.controls['type'].value;
    })


    this.companyForm.controls['companyPlan'].valueChanges.subscribe((value) => {
      this.company.companyPlan = value;

      if (value.planName === 'Custom') this.companyForm.controls['maxUsers'].enable();
      else this.companyForm.controls['maxUsers'].disable();

      this.companyForm.controls['maxUsers'].patchValue(value.maxUsers);
    })

    this.companyForm.controls['email'].valueChanges.subscribe((value) => this.emailError = false);
    this.companyForm.controls['subdomain'].valueChanges.subscribe((value) => this.subdomainError = false);
    this.companyForm.controls['name'].valueChanges.subscribe((value) => this.nameError = false);

    this.companyForm.controls['maxUsers'].valueChanges.subscribe((value) => {
      if (this.company.companyPlan.planName !== 'Custom') return;

      if (value > 100000) {
        this.companyForm.controls['maxUsers'].setValue(100000)
        return
      } else if(value !== '' && Number(value) === 0) {
        this.companyForm.controls['maxUsers'].setErrors({error: 'Number of users cannot be 0'})
        return
      } else if (value !== '' && value < this.company.usersCount) {
        this.companyForm.controls['maxUsers'].setErrors({error: `Number of users can not be less then actual amount of users in Company (${this.company.usersCount})`})
        return
      }
      this.company.companyPlan.maxUsers = value;
    })
  }

  changeCountryCode(countryCode: string) {
    this.companyForm.controls['countryCode'].setValue(countryCode)
  }

  onInputChange(event: any) {
    let input = event.target.value;

    const cleanedInput = input
      .replace(/[^a-z0-9-]/g, '')
      .replace(/^-|-$/g, '')

    event.target.value = cleanedInput;
    this.companyForm.controls['subdomain']?.setValue(cleanedInput);
  }


  submitForm() {
    if (this.companyForm.invalid) {
      if (this.nameError) this.companyForm.controls['name'].setErrors({error: "Company with such name already exists."});
      if (this.emailError) this.companyForm.controls['email'].setErrors({error: "Company with such email address already exists. Please use a different email."});
      if (this.subdomainError) this.companyForm.controls['subdomain'].setErrors({error: "Subdomain name already in use."});
      return;
    }
    this.submitBtnDisabled = true;

    if (!this.company.id) {
      this.companyService.createCompany(this._company).subscribe((data) => {
        this.created.emit();
        this.notifService.successNotification("Changes have been saved");
        if (environment.production) alert(`Please create subdomain with name - ${data.subdomain}`)
      }, (error: HttpErrorResponse) => {
        this.submitBtnDisabled = false;
        if (error.status === 400) {
          if (error.error.error === 'Name already in use') {
            this.companyForm.controls['name'].setErrors({error: "Company with such name already exists."});
            this.nameError = true;
          }
          else if (error.error.error === 'Email already in use') {
            this.companyForm.controls['email'].setErrors({error: "Company with such email address already exists. Please use a different email."});
            this.emailError = true;
          }
          else if (error.error.error === 'Subdomain already in use') {
            this.companyForm.controls['subdomain'].setErrors({error: "Subdomain name already in use"});
            this.subdomainError = true;
          }
        }
      });
    } else {
      this.companyService.updateCompany(this._company).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
      }, (error: HttpErrorResponse) => {
        this.submitBtnDisabled = false;
        if (error.status === 400) {
          if (error.error.error === 'Name already in use') {
            this.companyForm.controls['name'].setErrors({error: "Company with such name already exists."});
            this.nameError = true;
          }
          else if (error.error.error === 'Email already in use') {
            this.companyForm.controls['email'].setErrors({error: "Company with such email address already exists. Please use a different email."});
            this.emailError = true;
          }
        }
      });
    }
  }

  selectorOpened() {
    if (window.innerWidth < 640) setTimeout(() => this.ngScrollbar.update(), 0)
  }

  protected readonly phoneMask = phoneMask;
  protected readonly companyTypeByEnum = companyTypeByEnum;
  protected readonly UserRole = UserRole;
}
