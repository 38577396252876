import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../../_models/project";
import {NotificationService} from "../../../../../../../_services/notification.service";
import {ProjectAreaStatusesService} from "../../../../../../../_services/project-area-statuses.service";
import {modalAnimation} from "../../../../../../../config/constants";
import {GlobalStore} from "../../../../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";
import {ProjectViewStore} from "../../../project-view/project-view.store";

@Component({
  selector: 'app-deleting-status-modal',
  templateUrl: './deleting-status-modal.component.html',
  styleUrls: ['./deleting-status-modal.component.scss'],
  animations: [modalAnimation]
})
export class DeletingStatusModalComponent implements OnDestroy {
  selectedStatus: ProjectCustomStatuses;
  isLoadingStatus = false;
  deletingBtnDisabled = true;
  private _projectStatuses: ProjectCustomStatuses[] = []
  private _customStatusList: ProjectCustomStatuses[] = []
  private _deletingStatus: ProjectCustomStatuses;
  isError = false;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  @Output() updated: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedWithReplacement: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set deletingStatus (value: ProjectCustomStatuses) {
    this._deletingStatus = value;
  }
  @Input()
  set customStatusList (value: ProjectCustomStatuses[]) {
    this._customStatusList = value;

  }
  @Input()
  set projectStatuses(value: ProjectCustomStatuses[]) {
    this._projectStatuses = value;
  }

  get customStatusList() {
    return this._customStatusList;
  }

  get deletingStatus() {
    return this._deletingStatus;
  }


  get projectStatuses() {
    return this._projectStatuses;
  }

  constructor(private notifService: NotificationService,
              readonly globalStore: GlobalStore,
              private projectStatusesService: ProjectAreaStatusesService) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe(companyId => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => this.projectId = projectId);
  }

  addColorToSelectValue(statusSelected: ProjectCustomStatuses): any {
    if (statusSelected.orderNumber < 0) {
      if (statusSelected.orderNumber !== -3) return 'white'
      else return 'black'
    }
    if (statusSelected.color === ProjectCustomColors.YELLOW || statusSelected.color === ProjectCustomColors.GREY || statusSelected.color === ProjectCustomColors.MINT) {
      return "black"
    } else return "white"

  }

  addBackgroundToStatusItem(status: ProjectCustomStatuses) {
    if (status.orderNumber === -3) return null
    else return status.color
  }

  findSelectedStatus(statusId: number) {
    return this.projectStatuses.find(status => status.id === statusId)
  }

  checkStatusPresent(statusId: number) {
    return this.customStatusList.find(status => status.id === statusId)
  }

  changeAreaStatus(statusId: number) {
    this.isLoadingStatus = true;
    let status = this.findSelectedStatus(statusId)
    status.inUse = true;
    this.selectedStatus = status;
    this.isLoadingStatus = false;
    this.deletingBtnDisabled = false;
    this.isError = false;

  }

  deleteCustomStatus() {
    if(this.deletingBtnDisabled) {
      this.isError = true;
      return
    }
    this.projectStatusesService.deleteStatusWithReplacement(this.companyId, this.projectId, this.deletingStatus.id, this.selectedStatus.id).subscribe(() => {
      this.notifService.successNotification('Changes have been saved')
      this.deletedWithReplacement.emit({newStatus: this.selectedStatus, deletedStatus: this.deletingStatus})
      this.updated.emit(this.deletingStatus.id)
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

}
