<div class="backdrop">
  <ng-scrollbar class="remove-overflow-on-tablet modal" [autoHeightDisabled]="true">
    <form class="form-invite form-create" firstInputAutofocus scrollToFirstInvalidInput appAutoTrim #form="ngForm" [formGroup]="taskForm" (ngSubmit)="submitForm()">
      <button type="button" id="btnCloseMobile" class="close-modal-btn" (click)="close.emit()">
          <svg class="close-modal-icon">
              <use spriteVersion iconName="modal-close"></use>
          </svg>
      </button>
      <div class="form-modal-way-wrapper" *ngIf="task.id">
        <ng-template [ngIf]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
          <span class="form-modal-way">{{globalStore.companyName$ | async}}</span>
          /
        </ng-template>
        <span class="form-modal-way">{{globalStore.projectName$ | async}}</span>
        /
        <span class="form-modal-way">{{taskInitialName}}</span>
        /
        <span class="form-modal-way item-code-way" *ngIf="task.publicId">{{task.publicId}}</span>
      </div>
        <div class="form-inner-wrapper" [attr.paddingTop]="!!task.id">
            <p class="form-modal-title">{{taskForm.controls['id'].value ? 'Edit Task' : 'Create Task'}}</p>

          <div class="main-fields-wrapper">
            <p class="main-error" *ngIf="taskForm.invalid && form.submitted" id="mainErrorMobile">
                <svg class="error-icon">
                    <use spriteVersion iconName="error-icon"></use>
                </svg>
                All fields are required
            </p>

            <div class="group-fields-wrapper">

              <div class="field-wrapper">
                <label class="form-label"><span class="label-text">Task Name</span> </label>
                <span class="input-with-error-wrapper">
                  <input class="form-input-modal" id="taskNameMobile" formInput="NAME" formControlName="name" placeholder="Task Name">
                  <p class="small-error-text-field" id="taskNameErrorMobile" *ngIf="taskForm.controls['name'].invalid && form.submitted">{{taskForm.controls['name'].getError('error')}}</p>
                </span>
              </div>

              <div class="field-wrapper">
                <label class="form-label">Assign Member</label>
                <div class="input-with-error-wrapper">
                  <div class="ng-select-wrapper ng-select-wrapper--wider">
                    <ng-select class="custom select-without-absolute-last-option"
                               id="taskSelectMemberMobile"
                               bindLabel="name"
                               bindValue="id"
                               [items]="users"
                               formControlName="member"
                               [clearable]="false"
                               notFoundText="No results matching"
                               [editableSearchTerm]="true"
                               [searchable]="true"
                               placeholder="Select member">
                    </ng-select>
                  </div>
                  <p class="small-error-text-field" id="taskSelectMemberErrorMobile" *ngIf="taskForm.controls['member'].invalid && form.submitted">This field is required</p>
                </div>
              </div>
              <div class="field-wrapper">
                <label class="form-label">Status</label>
                <div class="input-with-error-wrapper">
                  <div class="ng-select-wrapper ng-select-wrapper--wider">
                    <ng-select class="custom select-without-absolute-last-option"
                               id="filterByStatus"
                               bindLabel="name"
                               bindValue="id"
                               [attr.value]="task.status"
                               (open)="addAttrsToFilterByStatusDropdownOptions()"
                               formControlName="status"
                               notFoundText="No results matching"
                               [searchable]="false"
                               [clearable]="false"
                               placeholder="Status">
                      <ng-option *ngFor="let taskStatus of taskStatusByEnum | keyvalue: originalOrder" [value]="taskStatus.key">{{taskStatus.value}}</ng-option>
                    </ng-select>
                  </div>
                  <p class="small-error-text-field" id="taskSelectStatusError" *ngIf="taskForm.controls['status'].invalid && form.submitted">This field is required</p>
                </div>
              </div>

              <div class="field-wrapper" *ngIf="task.creator?.name">
                <label class="form-label">Created By</label>
                <input class="form-input-modal" id="taskCreator" formInput="NAME" formControlName="creator" placeholder="Created By">
              </div>
            </div>

            <div class="group-fields-wrapper">
              <div class="field-wrapper">
                  <label class="form-label project-optional-label">Description <span class="project-optional-text">(Optional)</span></label>
                  <textarea class="form-input-modal form-textarea" rows="3" formInput="TASK_DESCRIPTION" id="taskDescriptionMobile" formControlName="description" placeholder="Description"></textarea>
              </div>

              <div class="field-wrapper field-wrapper-priority">
                  <label class="form-label project-optional-label">Priority <span class="project-optional-text">(Optional)</span></label>
                  <div class="ng-select-wrapper ng-select-wrapper--wider">
                      <ng-select #prioritySelect
                                 class="custom select-without-absolute-last-option prioritySelect"
                                 id="prioritySelectMobile"
                                 [attr.value]="taskForm.controls['priority'].value"
                                 formControlName="priority"
                                 [clearable]="false"
                                 notFoundText="No results matching"
                                 (open)="addAttrsToPriorityDropdownOptions()"
                                 [searchable]="false"
                                 placeholder="Priority">
                          <ng-option *ngFor="let priority of priorityByEnum | keyvalue: originalOrder" [value]="priority.key">
                              {{priority.value}}
                          </ng-option>
                      </ng-select>
                  </div>
              </div>

              <div class="field-wrapper">
                  <label class="form-label project-optional-label">Due Date <span class="project-optional-text">(Optional)</span></label>
                  <app-custom-datepicker [formControl]="$any(taskForm).controls['dueDate']" [id]="'taskDueDate'"></app-custom-datepicker>
              </div>

              <div class="field-wrapper field-wrapper-file-relative">
                <label class="form-label  project-optional-label">Add Files <span class="project-optional-text">(Optional)</span> </label>
                <app-add-files-to-form [filesFromBack]="task.files || []"
                                       [maxFileSizeInMb]="10"
                                       (updatedFilesListFromBack)="updateFiles($event, 'oldFiles')"
                                       (updatedNewFilesList)="updateFiles($event, 'newFiles')">
                </app-add-files-to-form>
              </div>

            </div>
          </div>
        </div>

        <div class="form-btn-wrapper">
            <button type="button" class="btn-cancel" id="btnCancelMobile" (click)="close.emit()">Cancel</button>
            <button type="submit" class="btn-invite" id="btnSubmitMobile" [disabled]="submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled">{{taskForm.controls['id'].value ? 'Update Task' : 'Create Task'}}</button>
        </div>
    </form>
  </ng-scrollbar>
</div>

