<app-view-area-status [statusesInUseAmount]="projectStore.statistics$ | async"
                      [projectStatuses]="projectStore.projectStatuses$ | async"
                      (updateStatuses)="projectStore.loadProjectStatuses(); projectStore.loadProjectTable()"
                      (startOrderChanging)="startOrderChanging($event)"
                      (cancelOrderChanging)="cancelOrderChanging($event)"
                      (saveOrder)="saveOrder()"
                      (deletedStatusWithReplacement)="projectStore.deleteStatusWithReplacement($event)"
                      [roomsAmount]="tableHead.length">
</app-view-area-status>
<ng-scrollbar *ngIf="!isLoading" class="table-progress-wrapper" [attr.isIOS]="(globalStore.isIOS$ | async)" [autoHeightDisabled]="true" [track]="'all'">
  <div>
    <div class="table-progress-wrapper__inner">
      <div class="progress-table">
        <ul class="left-top-titles">
          <li class="rooms">Rooms</li>
          <li class="areas">Areas</li>
          <li class="row-vertical">
            <p class="floors">Floor</p>
            <p class="units">Units</p>
          </li>
        </ul>
        <ul class="rooms-list">
          <li class="room" *ngFor="let room of tableHead; let idx = index">
              <p class="room-name" [style.max-width]="roomNamesWidthMap.get(room.name) + 'px'" [title]="room.name">
                <span *ngIf="roomOrderingMode" [attr.disabled]="idx === 0" class="room-arrow-icon-wrapper-left" (click)="projectStore.moveRoomLeft(idx)">
                  <svg class="room-arrow-left">
                    <use spriteVersion iconName="room-left-arrow"></use>
                  </svg>
                </span>
                {{room.name}}
                <span *ngIf="roomOrderingMode" [attr.disabled]="idx === tableHead.length - 1" class="room-arrow-icon-wrapper-right" (click)="projectStore.moveRoomRight(idx)">
                  <svg class="room-arrow-right">
                    <use spriteVersion iconName="room-left-arrow"></use>
                  </svg>
                </span>
              </p>
            <ul class="room-areas">
              <li *ngFor="let area of room.areas; let idxArea = index"
                  [attr.roomUsageAmount]="room.usageAmount"
                  [title]="area"
                  [ngStyle]="{'padding-bottom': areaOrderingMode ? '25px' : '8px'}">
                <div class="area-position-arrows-wrapper">
                  <span *ngIf="areaOrderingMode && room.usageAmount > 1" [attr.disabled]="idxArea === 0" class="area-arrow-icon-wrapper-left" (click)="projectStore.moveAreaLeft({roomIndex: idx, areaIndex: idxArea})">
                    <svg class="area-arrow-left">
                      <use spriteVersion iconName="room-left-arrow"></use>
                    </svg>
                  </span>
                  <span *ngIf="areaOrderingMode && room.usageAmount > 1" [attr.disabled]="idxArea === room.areas.length - 1" class="area-arrow-icon-wrapper-right" (click)="projectStore.moveAreaRight({roomIndex: idx, areaIndex: idxArea})">
                    <svg class="area-arrow-right">
                      <use spriteVersion iconName="room-left-arrow"></use>
                    </svg>
                  </span>
                </div>
                {{area}}
              </li>
              <li *ngIf="room.areas.length === 0" class="area-mock"></li>
            </ul>
          </li>
          <li *ngFor="let roomMock of [].constructor(amountMocksHorizontal)" class="room-mock">
            <p class="room-name"></p>
            <div class="area-mock"></div>
          </li>
        </ul>
      </div>

      <app-floors-list
        [floorsFilter]="projectStore.floorFilter$ | async"
        [amountMocksVertical]="amountMocksVertical"
       [amountMocksHorizontal]="amountMocksHorizontal"
       [isNotDesktopScreen]="(globalStore.isNotDesktop$ | async)"
       (areaClick)="openAreaModal($event)"
      ></app-floors-list>
    </div>
  </div>
</ng-scrollbar>

<app-loader-project-progress *ngIf="isLoading"></app-loader-project-progress>
<app-area-form *ngIf="selectedArea"
  (close)="selectedArea = null"
  [projectStatuses]="projectStore.projectStatuses$ | async"
  (updated)="updateArea($event); globalStore.loadProjectProgress()"
  [selectedArea]="selectedArea">
</app-area-form>
