import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../../../../../_services/notification.service";
import {modalAnimation, taskPriorityByEnum, taskStatusByEnum} from 'src/app/config/constants';

import {NgSelectComponent} from "@ng-select/ng-select";
import {Task, TaskFile} from "../../../../../../_models/task";
import {User, UserRole} from "../../../../../../_models/user";
import {UsersService} from "../../../../../../_services/users.service";
import {TasksService} from "../../../../../../_services/tasks.service";
import {convertTaskStatusToEnum, originalOrder} from "../../../../../../_util/utils";
import {minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import {GlobalStore} from "../../../../../../global.store";
import {Subject, switchMap, takeUntil, tap} from "rxjs";

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
  animations: [modalAnimation]
})
export class TaskFormComponent implements OnDestroy{
  @ViewChild('fileInput') fileInputRef: ElementRef;
  @ViewChild('prioritySelect') prioritySelect: NgSelectComponent;

  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  taskForm: FormGroup;
  submitBtnDisabled = false;
  users: User[] = [];
  filesList: TaskFile[] = [];
  taskInitialName: string;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  private _task = new Task();

  get task(): Task {
    return this._task;
  }

  @Input()
  set task(value: Task) {
    if (value) {
      this._task = {...value};
      this.fillTaskForm();
      this.taskInitialName = this._task.name
    }
  }

  constructor(private fb: FormBuilder,
              private userService: UsersService,
              private taskService: TasksService,
              readonly globalStore: GlobalStore,
              private notifService: NotificationService) {
    this.fillTaskForm();
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$
      .pipe(
        tap((projectId) => {
          this.projectId = projectId;
        }),
        switchMap((projectId) =>
          this.userService.getConfirmedEmployeesOnProject(this.companyId, projectId)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.users = data;
      });
  }

  addAttrsToPriorityDropdownOptions() {
    setTimeout(() => document.querySelectorAll('.prioritySelect .ng-option-label').forEach(e => e.setAttribute('data-val', e.textContent.trim().toLowerCase())));
  }

  addAttrsToFilterByStatusDropdownOptions() {
    setTimeout(() => document.querySelectorAll('#filterByStatus .ng-option-label').forEach(e => e.setAttribute('data-val', e.textContent.trim().toLowerCase())));
  }

  subscribeToFormChanges() {
    this.taskForm.valueChanges.subscribe(() => {
      this._task.name = this.taskForm.controls['name'].value;
      this._task.status = convertTaskStatusToEnum(this.taskForm.controls['status'].value);
      this._task.description = this.taskForm.controls['description'].value;
      this._task.priority = this.taskForm.controls['priority']?.value?.toUpperCase();
      this._task.dueDate = this.taskForm.controls['dueDate'].value;
    })

    this.taskForm.controls['member'].valueChanges.subscribe((value) => {
      let user = new User();
      user.id = value;
      this.task.member = user;
    })
  }

  updateFiles(files: TaskFile[], type: 'oldFiles' | 'newFiles') {
    switch (type) {
      case 'oldFiles': {
        this.task.files = [...files];
        break;
      }
      case 'newFiles': {
        this.filesList = [...files]
        break;
      }
    }
  }

  fillTaskForm() {
    this.taskForm = this.fb.group({
      id: [{value: this._task.id, disabled: true}],
      name: [this._task.name, [minLengthValidator('NAME'), requiredValidator()]],
      member: [this._task.member?.id || null, [requiredValidator()]],
      status: [this.task.status || null, [requiredValidator()]],
      creator: [{value: this.task.creator?.name || null, disabled: true} ],
      description: [this._task.description || null],
      files: [this._task.files],
      priority: [this._task.priority],
      dueDate: [this._task.dueDate],
    })
    this.subscribeToFormChanges();
  }

  submitForm() {
    if (this.taskForm.invalid) return
    this.submitBtnDisabled = true;

    const fd = new FormData();
    fd.append('task', JSON.stringify(this._task))
    // @ts-ignore
    for (const file of this.filesList) fd.append('files', file.fileToSave);


    if (!this.task.id) {
      this.taskService.createTask(fd, this.companyId, this.projectId).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
      }, error => this.submitBtnDisabled = false)
    } else {
      this.taskService.updateTask(fd, this.companyId, this.projectId).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
      }, error => this.submitBtnDisabled = false);
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly priorityByEnum = taskPriorityByEnum;
  protected readonly event = event;
  protected readonly originalOrder = originalOrder;
  protected readonly UserRole = UserRole;
    protected readonly taskStatusByEnum = taskStatusByEnum;
}



