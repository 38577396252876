<div class="backdrop">
  <div [enabled]="true" (appOutsideClick)="startChangeStatusProgress = false" class="modal modal--wider">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="close.emit(); removeMentionList(); startChangeStatusProgress = false">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="area-details-wrapper">
      <p class="area-details-way-wrapper">
        <ng-template [ngIf]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
          <span class="form-modal-way">{{globalStore.companyName$ | async}}</span>
          /
        </ng-template>
        <span class="area-details-way">{{globalStore.projectName$ | async}}</span>
        /
        <span class="area-details-way">{{selectedArea.floorName}}</span>
        /
        <span class="area-details-way">{{selectedArea.unitName}}</span>
        /
        <span class="area-details-way">{{selectedArea.roomName}}</span>
        /
        <span class="area-details-way">{{selectedArea.area.name}}</span>
        /
        <span class="area-details-way area-code">{{selectedArea.area.publicId}}</span>
      </p>
      <p class="area-details-name">{{selectedArea.area.name}}</p>

      <div class="area-details-select-with-progress-wrapper">
        <div class="area-status-with-weight-wrapper">
          <div class="ng-select-wrapper areastatusSelect">
            <p class="status-progress-label status-progress-label--margin-bottom">Status:</p>
            <div class="area-status-btn-wrapper" #areaStatusSelect [enabled]="areaStatusSelect.hasAttribute('openMenu')" (appOutsideClick)="areaStatusSelect.removeAttribute('openMenu')">
              <button class="area__status-btn" [attr.istodo]="selectedArea?.area.status.orderNumber === -3" [style.background-color]="addBackgroundToStatusItem(selectedArea.area.status)" [style.color]="addColorToSelectValue(selectedArea.area.status)" (click)="isLoadingStatus ? null : areaStatusSelect.toggleAttribute('openMenu')" >
                {{selectedArea.area.status.name}}
                <svg *ngIf="!isLoadingStatus" class="area__status-icon-dropdown" [style.stroke]="addColorToSelectValue(selectedArea.area.status)">
                  <use spriteVersion iconName="arrow-select-area-status"></use>
                </svg>

                <div *ngIf="isLoadingStatus" class="loader-spin">
                  <svg [style.stroke]="addColorToSelectValue(selectedArea.area.status)">
                    <use spriteVersion iconName="loader-icon"></use>
                  </svg>
                </div>
              </button>
              <div class="area__status-menu-wrapper">
                <ul class="area__status-menu">
                  <li class="area__status-menu__item" *ngFor="let areaStatus of projectStatuses" (click)="changeAreaStatus(areaStatus.id); areaStatusSelect.removeAttribute('openMenu')">
                    <p class="area__status-menu__text" [attr.istodo]="areaStatus.orderNumber === -3" [style.color]="addColorToSelectValue(areaStatus)" [style.background-color]="addBackgroundToStatusItem(areaStatus)" [attr.selectedStatus]="areaStatus.name === selectedArea.area.status.name">{{areaStatus.name}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="field-wrapper priority-wrapper">
            <label class="form-label project-optional-label">Weight</label>
            <div class="ng-select-wrapper">
              <div class="area-weight-btn-wrapper" #areaWeightSelect [enabled]="areaWeightSelect.hasAttribute('openMenu')" (appOutsideClick)="areaWeightSelect.removeAttribute('openMenu')">
                <button class="area__weight-btn" [attr.value]="selectedArea.area.areaWeight"  (click)="isLoadingWeight ? null : areaWeightSelect.toggleAttribute('openMenu')" >
                  {{transformTheFirstLetter(selectedArea.area.areaWeight)}}
                  <svg *ngIf="!isLoadingWeight" class="area__weight-icon-dropdown" [style.stroke]="'black'">
                    <use spriteVersion iconName="arrow-select-area-status"></use>
                  </svg>

                  <div *ngIf="isLoadingWeight" class="loader-spin">
                    <svg [style.stroke]="'black'">
                      <use spriteVersion iconName="loader-icon"></use>
                    </svg>
                  </div>
                </button>
                <div class="area__weight-menu-wrapper">
                  <ul class="area__weight-menu">
                    <li class="area__weight-menu__item" *ngFor="let weight of areaWeightByEnum | keyvalue: originalOrder" (click)="changeAreaWeight(weight.key); areaWeightSelect.removeAttribute('openMenu')">
                      <p class="area__weight-menu__text" [attr.data-val]="weight.value.toUpperCase()" [attr.selectedWeight]="weight.key === selectedArea.area.areaWeight">{{weight.value}}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="area-details-progress" *ngIf="selectedArea.area.status.orderNumber === AreaStatusDefaultOrder.IN_PROGRESS">
          <p class="status-progress-label">Status Progress <span class="status-progress-percent">{{statusProgressDump}}%</span></p>

          <div class="area-details-progress-with-btns">
            <div class="area-status-progress-list-wrapper">
              <button type="button" class="change-status-progress-btn" [disabled]="statusProgressDump === 0" (click)="$event.stopPropagation(); startChangeStatusProgress = true; statusProgressDump = statusProgressDump - 10">
                <svg class="status-progress-btn-icon">
                  <use spriteVersion iconName="minus"></use>
                </svg>
              </button>
              <ul class="area-status-progress-list">
                <li class="area-status-progress-list__item" *ngFor="let bar of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"  #progressSquare [attr.ignored]="calculateProgressSquareAttrs(progressSquare, bar)" (click)="startChangeStatusProgress = true; statusProgressDump = bar"></li>
              </ul>
              <button type="button" class="change-status-progress-btn" [disabled]="statusProgressDump === 100" (click)="$event.stopPropagation(); startChangeStatusProgress = true; statusProgressDump = statusProgressDump + 10">
                <svg class="status-progress-btn-icon">
                  <use spriteVersion iconName="black-plus"></use>
                </svg>
              </button>
            </div>
            <ul class="btns-list" *ngIf="startChangeStatusProgress">
              <li>
                <button type="button" class="btn-confirm" (click)="isLoadingStatusProgress ? null : updateAreaStatusProgress()">
                  <svg *ngIf="!isLoadingStatusProgress" class="btn-confirm__check-icon">
                    <use spriteVersion iconName="check"></use>
                  </svg>

                  <div *ngIf="isLoadingStatusProgress" class="loader-spin loader-spin-progress">
                    <svg [style.stroke]="'black'">
                      <use spriteVersion iconName="loader-icon"></use>
                    </svg>
                  </div>
                  <img class="check-icon-for-ios" src="assets/images/check-icon-png.png" alt="check-icon">
                </button>
              </li>
              <li>
                <button type="button" class="btn-confirm" (click)="statusProgressDump = selectedArea.area.statusProgress; startChangeStatusProgress = false">
                  <svg class="btn-confirm__cansel-icon">
                    <use spriteVersion iconName="close-icon"></use>
                  </svg>
                  <img class="cross-icon-for-ios" src="assets/images/cross-icon-png.png" alt="cross-icon">
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--Area Details Tabs-->

    <ul class="area-details-tabs-list">
      <li class="area-details-tabs-list__item">
        <a class="area-details-tabs-list__link" [class.active]="activeTab === 'activity'" (click)="activeTab = 'activity'">Activity</a>
      </li>
      <li class="area-details-tabs-list__item">
        <a class="area-details-tabs-list__link" [class.active]="activeTab === 'history'" (click)="activeTab = 'history'">History</a>
      </li>
      <li class="area-details-tabs-list__item">
        <a class="area-details-tabs-list__link" [class.active]="activeTab === 'files'" (click)="activeTab = 'files'">Files</a>
      </li>
    </ul>
    <ng-container *ngIf="selectedArea">
      <app-activity-area *ngIf="activeTab === 'activity'" [attr.display]="activeTab === 'activity' ? 'true' : 'false'" [selectedArea]="selectedArea" (updated)="updated.emit(selectedArea); updateHistory()"></app-activity-area>
      <app-history-area *ngIf="activeTab === 'history'" [attr.display]="activeTab === 'history' ? 'true' : 'false'" [selectedArea]="selectedArea"></app-history-area>
      <app-files-area *ngIf="activeTab === 'files'" [attr.display]="activeTab === 'files' ? 'true' : 'false'" [selectedArea]="selectedArea"></app-files-area>
    </ng-container>
  </div>
</div>
