<div class="backdrop" *ngIf="isOpenCustomStatusForm">
    <div class="modal">
        <form class="form-invite form-create" firstInputAutofocus scrollToFirstInvalidInput [formGroup]="statusForm" #form="ngForm" (ngSubmit)="submitForm()"> <!--[formGroup]="taskForm"-->
            <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit(isChangedCustomForm)">
                <svg class="close-modal-icon">
                    <use spriteVersion iconName="modal-close"></use>
                </svg>
            </button>
            <div class="form-inner-wrapper">
                <p class="form-modal-title form-modal-status-title">Customize Status</p>
                <p class="main-error" *ngIf="statusForm.invalid && form.submitted" id="mainError">
                    <svg class="error-icon">
                        <use spriteVersion iconName="error-icon"></use>
                    </svg>
                    All fields are required
                </p>

                <div class="main-fields-customize-statuses-wrapper">
                  <div class="default-statuses-wrapper" [attr.isLineWithMargin]="customStatusList.length !== 0">
                    <div class="status-color-with-field-wrapper">
                        <span class="color-box"></span>
                        <div class="field-wrapper field-status-wrapper">
                            <input class="form-input-modal form-input-modal--disabled" id="statusNotInUse" formInput="NAME" formControlName="notInUse" placeholder="Status Name">
                            <p class="small-error-text-field" id="statusNotInUseError" *ngIf="statusForm.controls['notInUse'].invalid && form.submitted">{{statusForm.controls['notInUse'].getError('error')}}</p>
                        </div>
                    </div>

                    <div class="status-color-with-error-wrapper">
                      <div class="status-color-with-field-wrapper">
                          <span class="color-box" [attr.status]="AreaStatusDefaultOrder.TO_DO"></span>
                          <div class="field-wrapper field-status-wrapper">
                              <input class="form-input-modal" id="statusToDo" formInput="NAME" formControlName="todo" placeholder="Status Name">
                          </div>
                      </div>
                      <p class="small-error-text-field error-default" id="statusToDoError" *ngIf="statusForm.controls['todo'].invalid && form.submitted && !isErrorName(-3)">{{statusForm.controls['todo'].getError('error')}}</p>
                      <p class="small-error-text-field error-default-status-name" id="statusToDoErrorName" *ngIf="statusForm.controls['todo'].invalid && form.submitted && isErrorName(-3)">{{'Status name ' + statusForm.controls['todo'].value + ' already in use'}}</p>
                    </div>

                    <div class="status-color-with-error-wrapper">
                      <div class="status-color-with-field-wrapper">
                          <span class="color-box" [attr.status]="AreaStatusDefaultOrder.IN_PROGRESS"></span>
                          <div class="field-wrapper field-status-wrapper">
                              <input class="form-input-modal" id="statusInProgress" formInput="NAME" formControlName="inProgress" placeholder="Status Name">
                          </div>
                      </div>
                      <p class="small-error-text-field error-default" id="statusInProgressError" *ngIf="statusForm.controls['inProgress'].invalid && form.submitted && !isErrorName(-2)">{{statusForm.controls['inProgress'].getError('error')}}</p>
                      <p class="small-error-text-field error-default-status-name" id="statusErrorNameProgress" *ngIf="statusForm.controls['inProgress'].invalid && form.submitted && isErrorName(-2)">{{'Status name ' + statusForm.controls['inProgress'].value + ' already in use'}}</p>
                    </div>

                    <div class="status-color-with-error-wrapper">
                      <div class="status-color-with-field-wrapper">
                          <span class="color-box" [attr.status]="AreaStatusDefaultOrder.DONE"></span>
                          <div class="field-wrapper field-status-wrapper">
                              <input class="form-input-modal" id="statusDone" formInput="NAME" formControlName="done" placeholder="Status Name">
                          </div>
                      </div>
                      <p class="small-error-text-field error-default" id="statusDoneError" *ngIf="statusForm.controls['done'].invalid && form.submitted && !isErrorName(-1)">{{statusForm.controls['done'].getError('error')}}</p>
                      <p class="small-error-text-field error-default-status-name " id="statusDoneErrorName" *ngIf="statusForm.controls['done'].invalid && form.submitted && isErrorName(-1)">{{'Status name ' + statusForm.controls['done'].value + ' already in use'}}</p>
                    </div>
                  </div>

                  <ng-scrollbar class="statuses-list-wrapper"
                                #scrollbarRef
                                [autoHeightDisabled]="true"
                                cdkScrollable
                                [attr.isLotOfStatuses]="customStatusList.length > 4"
                                [attr.isEmptyStatuses]="customStatusList.length === 0"
                                [attr.isMargin]="customStatusList.length < 11">

                    <ul class="additional-statuses-list"
                        #additionalStatusesListRef
                        [attr.isLotsOfStatuses]="customStatusList.length > 4"
                        *ngIf="customStatusList.length !== 0"
                        cdkDropList
                        cdkDropListOrientation="vertical"
                        (cdkDropListDropped)="dropStatusField($event)">
                      <li cdkDrag
                          [cdkDragDisabled]="disabledDrag"
                          [cdkDragStartDelay]="isNotDesktop ? 200 : 0"
                          cdkDragPreviewClass="area-form-row-previewDrop"
                          tabindex="1"
                          class="additional-statuses-list__item" *ngFor="let customStatus of customStatusList; let i = index">
                        <div class="area-form-row">   <!--[formGroup]="getFormGroup(areaForm)"-->
                          <div class="area-form-dragIcon-with-input-wrapper">
                            <svg class="drag-icon" *ngIf="customStatusList.length > 1">
                                <use spriteVersion iconName="drag-icon"></use>
                            </svg>

                            <div #colorsListRefWrapper class="color-box-with-list-wrapper"
                                 (appOutsideClick)="colorsListRefWrapper.removeAttribute('open')"
                                 [enabled]="colorsListRefWrapper.hasAttribute('open')">
                              <span class="color-box" [attr.open]="colorsListRefWrapper.hasAttribute('open')" [style.background-color]="customStatus.color" (click)="colorsListRefWrapper.toggleAttribute('open')">
                                <svg class="color-box-arrow">
                                    <use spriteVersion iconName="arrow-color"></use>
                                </svg>
                              </span>

                              <ul class="colors-list" #colorsListRef
                                  (isLowerThenMiddleOfContainer)="colorsListRef.style.top='-' + colorsListRef.offsetHeight + 'px'"
                                  [containerElement]="additionalStatusesList"
                                  [isScrollable]="customStatusList.length > 4"
                                  [@modalAnimation] *ngIf="colorsListRefWrapper.hasAttribute('open')">
                                <li class="colors-list__item" *ngFor="let colorItem of colorsList"
                                    [attr.inUse]="colorItem.inUse" [attr.disabled]="customStatus.color !== colorItem.color && colorItem.inUse === true"
                                    [style.background-color]="colorItem.color"
                                    (click)="changeColorStatus($event, i, colorItem.color)"></li>
                              </ul>
                            </div>

                            <input class="form-input-modal custom-status-input" #inputAreaField [id]="i" [style.border-color]="customStatus.errorName || customStatus.error ? '#E41616' : ''" placeholder="Status Name" formInput="ROOM_AREA_NAME" (focus)="disabledDrag = true" (blur)="disabledDrag = false" [value]="customStatus.name" (input)="onInputStatusName($event, i); inputAreaField.setAttribute('invalid', String(inputAreaField.value.length < FORM_ERRORS.ROOM_AREA_NAME.MIN_LENGTH.VALUE))">
                            <button type="button" id="deleteStatusField" class="delete-area-field__btn" (click)="deleteStatus(i, customStatus.inUse, null)" *ngIf="customStatusList.length > 0">
                              <svg class="delete-area-field__icon">
                                  <use spriteVersion iconName="trash-delete-icon"></use>
                              </svg>
                            </button>
                          </div>
                          <p class="small-error-text-field error-status-name" [style.padding-left]="customStatusList.length > 1 ? '75px' : '0'" id="statusErrorName" *ngIf="customStatus.errorName && form.submitted">{{'Status name ' + customStatus.name + ' already in use'}}</p>
                          <p class="small-error-text-field" id="floorNameError" [style.padding-left]="customStatusList.length > 1 ? '75px' : '0'" *ngIf="inputAreaField.getAttribute('invalid') === 'true' && inputAreaField.value !== ''">{{FORM_ERRORS.ROOM_AREA_NAME.MIN_LENGTH.MESSAGE}}</p>
                        </div>
                      </li>
                    </ul>
                  </ng-scrollbar>

                  <button *ngIf="customStatusList.length < 11" type="button" class="add-area-field-btn" (click)="addStatus()" id="addAreaField">
                      <svg class="add-icon">
                          <use spriteVersion iconName="close-icon"></use>
                      </svg>
                      Add Status
                  </button>

                  <p class="info-room-text info-custom-text" *ngIf="customStatusList.length === 11">
                    <svg class="exclamaition-icon" [ngStyle]="{width: '20px'}">
                      <use spriteVersion iconName="exclamation-circle"></use>
                    </svg>
                    <span>You have used the maximum number of custom statuses</span>
                  </p>

                  <p class="info-room-text">
                      <svg class="exclamaition-icon" [ngStyle]="{width: '20px'}">
                          <use spriteVersion iconName="exclamation-circle"></use>
                      </svg>
                      <span>
                          Adding or changing the status will affect all existing project status
                      </span>
                  </p>
                </div>
            </div>

            <div class="form-btn-wrapper">
                <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit(isChangedCustomForm)">Cancel</button>
                <button type="submit" class="btn-invite" id="btnSubmit" [disabled]="submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled">Save and update</button>
            </div>
        </form>
    </div>
</div>


<app-deleting-status-modal *ngIf="isOpenDeleteStatusModal" [deletingStatus]="deletingStatus"
                           [customStatusList]="customStatusList" [projectStatuses]="projectStatuses"
                           (updated)="isOpenDeleteStatusModal = false; isOpenCustomStatusForm = true; deleteStatus(null, false, $event)"
                           (deletedWithReplacement)="deletedWithReplacement.emit($event)"
                           (close)="isOpenDeleteStatusModal = false; isOpenCustomStatusForm = true"></app-deleting-status-modal>


