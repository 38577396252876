import {ProjectCustomStatuses} from "./project";

export class Area {
  id: number;
  name: string;
  publicId: string;
  orderNumber: number;
  version: number;
  status: ProjectCustomStatuses;
  areaWeight: AreaWeight = AreaWeight.MEDIUM;
  statusProgress: number;
  attachmentsAmount: number;
  commentsAmount: number;
  errorName: boolean;
  changed: boolean;

  pp_opacity: any;
}


export class AreasAmount {
  todo: number = 0;
  inProgress: number = 0;
  done: number = 0;
}

export enum AreaStatus {
  TO_DO = "To Do",
  IN_PROGRESS = "In Progress",
  DONE = "Done"
}

export enum AreaStatusDefaultOrder {
  TO_DO = -3,
  IN_PROGRESS = -2,
  DONE = -1
}


export enum AreaWeight {
  NO_WEIGHT = "NO_WEIGHT",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  HUGE = "HUGE",
}
