import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {Unit} from "../_models/unit";
import {Room} from "../_models/room";
import {Area, AreaStatus, AreaWeight} from "../_models/area";
import {Observable} from "rxjs";
import {RoomAreaHistoryItem} from "../_models/roomAreaHistoryItem";
import {AreaComment} from "../_models/areaComment";
import {TaskFile} from "../_models/task";
import {ProjectCustomStatuses} from "../_models/project";

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  constructor(private http: HttpClient) { }

  updateAreaStatusById(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, statusId: number) {
    let timestamp = new Date().getTime();
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/status`, null,{params: {timestamp, statusId: statusId}, withCredentials: true})
  }

  updateAreaWeightById(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, weight: AreaWeight) {
    let timestamp = new Date().getTime();
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/weight`, null,{params: {timestamp, areaWeight: weight}, withCredentials: true})
  }
  updateAreaStatusProgressById(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, progress: number) {
    let timestamp = new Date().getTime();
    return this.http.put(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/progress`, null, {params: {progress, timestamp}, withCredentials: true})
  }

  getAreaHistory(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number): Observable<RoomAreaHistoryItem[]> {
    return this.http.get<RoomAreaHistoryItem[]>(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/history`, {withCredentials: true})
  }

  getAreaComments(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number): Observable<AreaComment[]> {
    return this.http.get<AreaComment[]>(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/comments`, {withCredentials: true})
  }

  createAreaComment(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, comment: FormData): Observable<AreaComment> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<AreaComment>(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/comments`, comment, {headers: headers, withCredentials: true})
  }

  editAreaComment(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, comment: FormData): Observable<AreaComment> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put<AreaComment>(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/comments`, comment, {withCredentials: true})
  }

  deleteAreaComment(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, comment: AreaComment) {
    let params = new HttpParams();
    params = params.append('commentId', comment.id);
    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/comments`, {params: params, withCredentials: true})
  }

  deleteAreaFile(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number, commentId: number) {
    let params = new HttpParams();
    params = params.append('commentId', commentId);
    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}/comments/files`, {params: params, withCredentials: true})
  }

  getAreaById(companyId: number, projectId: number, floorId: number, unitId: number, roomId: number, areaId: number): Observable<Area> {
    return this.http.get<Area>(`${apiUrl}/company/${companyId}/project/${projectId}/floor/${floorId}/unit/${unitId}/room/${roomId}/area/${areaId}`, {withCredentials: true})
  }
}
