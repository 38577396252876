<app-filter-fields btnText="Create Task" searchPlaceholder="Search by Name, ID, or Member" [disabled]="tasksPage?.empty && isNoFilteringEnabled() && !hideCompleted" (openFormBtnClick)="taskFormOpened = true" (hideCompleted)="hideCompleted = !hideCompleted; getTasksList()"
                   (inputChanged)="query = $event; getTasksList()" [filtersAmount]="getFiltersActiveAmount()" hideButtonText="Hide completed tasks" showButtonText="Show completed tasks"
                   (deleteSelectedItem)="deletingPopupOpened = true" (cancelSelectedItem)="multiSelectedTasks = []; isMultiSelectAll = false" [selectedItemsAmount]="multiSelectedTasks.length" (clearAllFilters)="clearFilters()">

  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Assigned Member:
    </label>
    <div class="ng-select-wrapper">
      <ng-select bindValue="id"
                 bindLabel="name"
                 [items]="membersList"
                 [(ngModel)]="selectedMemberId"
                 notFoundText="No results matching"
                 [editableSearchTerm]="true"
                 (change)="getTasksList()"
                 placeholder="Add Member"
                 [disabled]="(currentUser | async).role === UserRole.ROLE_EMPLOYEE">
      </ng-select>
    </div>
  </div>
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Status:
    </label>
    <div class="ng-select-wrapper">
      <ng-select [(ngModel)]="selectedStatus"
                 id="filterByStatus"
                 [attr.value]="selectedStatus"
                 (open)="addAttrsToFilterByStatusDropdownOptions()"
                 notFoundText="No results matching"
                 [searchable]="false"
                 (change)="getTasksList()"
                 placeholder="Add Status">
        <ng-option *ngFor="let taskStatus of taskStatusByEnum | keyvalue: originalOrder" [value]="taskStatus.key">{{taskStatus.value}}</ng-option>
      </ng-select>
    </div>
  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>

<ng-scrollbar *ngIf="tasksPage && !isLoading" externalViewport [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.tasksPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.tasksPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-tasks" *ngIf="!tasksPage.empty; else emptyList" >
        <ul class="table-tasks__header-row">
          <li class="table-tasks__multiselected" *ngIf="multiSelectEnabled && multiSelectedTasks.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>
          <li class="table-tasks__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedTasks.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['publicId']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('publicId')" [style.opacity]="sorting['publicId'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['publicId'] === SortType.DESC" *ngIf="sorting['publicId']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-tasks__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedTasks.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['name']">
              Task Name
              <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-tasks__status">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['status']">
              Status
              <button class="table-sort-icon-btn" (click)="onSortBy('status')" [style.opacity]="sorting['status'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['status'] === SortType.DESC" *ngIf="sorting['status']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__creator-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['creator.name']">
              Created By
              <button class="table-sort-icon-btn" (click)="onSortBy('creator.name')" [style.opacity]="sorting['creator.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['creator.name'] === SortType.DESC" *ngIf="sorting['creator.name']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-tasks__member-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['member.name']">
              Assigned Member
              <button class="table-sort-icon-btn" (click)="onSortBy('member.name')" [style.opacity]="sorting['member.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['member.name'] === SortType.DESC" *ngIf="sorting['member.name']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__priority-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['priority']">
              Priority
              <button class="table-sort-icon-btn" (click)="onSortBy('priority')" [style.opacity]="sorting['priority'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['priority'] === SortType.DESC" *ngIf="sorting['priority']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__due-date-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['dueDate']">
            Due Date
              <button class="table-sort-icon-btn" (click)="onSortBy('dueDate')" [style.opacity]="sorting['dueDate'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['dueDate'] === SortType.DESC" *ngIf="sorting['dueDate']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__actions no-right-border fixed-column"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let task of tasksPage.content" [id]="task.id" class="table-tasks__row" [attr.multiselected]="multiSelectedTasks.includes(task.id)" [ngStyle]="multiSelectedTasks.includes(task.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="task-id multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedTasks.length > 0">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedTasks.includes(task.id)" (change)="toggleCheck(task.id)" id="selectItemCheckbox" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="task-id fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedTasks.length > 0" [attr.done]="task.status.toLowerCase()" (click)="getTaskById(task.id); taskFormOpened = true; $event.stopPropagation()"
                  [ngStyle]="multiSelectedTasks.includes(task.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span class="item-code-way">
                    <span class="list-name-wrapper">
                      {{task.publicId}}
                    </span>
                  </span>
              </li>
              <li class="task-name fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedTasks.length > 0" [attr.done]="task.status.toLowerCase()" (click)="getTaskById(task.id); taskFormOpened = true; $event.stopPropagation()"
                  [ngStyle]="multiSelectedTasks.includes(task.id) ? {'background-color': '#F7F7F8'} : {}">
                <div class="task-name__wrapper">
                    <div class="list-name-wrapper">
                      <span>
                      {{task.name}}
                      </span>

                      <div class="task-file-with-description-icon">
                        <svg class="attachment-icon" *ngIf="task?.hasAttachments">
                          <use spriteVersion iconName="attachment"></use>
                        </svg>
                        <svg class="description-icon" *ngIf="task?.description !== null && task?.description.length !== 0">
                          <use spriteVersion iconName="description"></use>
                        </svg>
                      </div>
                    </div>

                </div>
              </li>

              <li class="task-status-td" [attr.status]="taskStatusByEnum.get(task.status)?.toLowerCase()" #taskStatus [enabled]="taskStatus.hasAttribute('openMenu')" (appOutsideClick)="taskStatus.removeAttribute('openMenu')" (click)="$event.stopPropagation()">
                <button class="task-status-btn" (click)="taskStatus.toggleAttribute('openMenu')">
                  {{taskStatusByEnum.get(task.status)}}
                  <svg class="task-status-icon-dropdown">
                    <use spriteVersion iconName="arrow-task-status"></use>
                  </svg>
                </button>
                <ul class="task-status-menu" #taskStatusList [@fadeIn] *ngIf="taskStatus.hasAttribute('openMenu')" (isLowerThenMiddleOfScreen)="taskStatusList.style.top='-' + taskStatusList.offsetHeight + 'px'">
                  <li class="task-status-menu__item" *ngFor="let status of taskStatusByEnum | keyvalue : originalOrder" (click)="updateTaskStatus(task, status.key); taskStatus.removeAttribute('openMenu')">
                    <p class="task-status-menu__text" [attr.status]="status.value">{{status.value}}</p>
                    <span class="task-status-menu-selected" *ngIf="task.status === status.key"></span>
                  </li>
                </ul>
              </li>
              <li class="task-creator" [attr.done]="task.status.toLowerCase()">{{task.creator?.name || '-'}}</li>
              <li class="task-members" [attr.done]="task.status.toLowerCase()">{{task.member?.name || '-'}}</li>
              <li class="task-priority">
                <svg class="priority-icon" *ngIf="task.priority && task.priority !== TaskPriority.NO_PRIORITY">
                  <use [attr.href]="'assets/sprite.svg#flag-priority-' + task.priority.toLowerCase()"></use>
                </svg>
                <span *ngIf="!task.priority || task.priority === TaskPriority.NO_PRIORITY">-</span>
              </li>
              <li class="task-dueDate">
                <div class="date-box" [attr.done]="task.status.toLowerCase()">
                  <ng-container *ngIf="task.dueDate">
                    <svg class="calendar-icon-table">
                      <use spriteVersion iconName="calendar"></use>
                    </svg>
                    {{task.dueDate | date: 'MM.dd.YY'}}
                  </ng-container>
                  <ng-container *ngIf="!task.dueDate">-</ng-container>
                </div>
              </li>
              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu" #taskDots (click)="taskDots.toggleAttribute('editMenuOpen'); $event.stopPropagation()" [enabled]="taskDots.hasAttribute('editMenuOpen')" (appOutsideClick)="taskDots.removeAttribute('editMenuOpen')">
                <ul class="editMenuList" #editMenuList [attr.editMenuListOffsetHeight]="editMenuList.offsetHeight" (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'" [@fadeIn] *ngIf="taskDots.hasAttribute('editMenuOpen')">
                  <li class="editMenuList__item" id="selectItem" (click)="toggleCheck(task.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li class="editMenuList__item" id="editItem" (click)="getTaskById(task.id); taskFormOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>
                  <li class="editMenuList__item" id="deleteItem" (click)="selectedTask = task; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                  </li>
                </ul>
              </li>

            </ul>
          </li>

          <app-pagination *ngIf="this.tasksPage.totalElements > 20 && (isMobile | async)" [isLoading]="!tasksPage" [paginationInfo]="this.tasksPage" [showAmountForMobile]="pageSizeOnMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<div *ngIf="!tasksPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>


<app-pagination *ngIf="this.tasksPage?.totalElements > 20 && !(isMobile | async)" [showAmountForDesk]="pageSizeOnDesktop" [isLoading]="!tasksPage" [paginationInfo]="this.tasksPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>


<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && !hideCompleted && tasksPage.empty; else notFound">The list of tasks is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Tasks found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

<div class="backdrop manual" [attr.show]="deletingPopupOpened">
  <div [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="selectedTask">Delete the Task “{{selectedTask.name}}”?</p>
      <p class="confirmDeleting-title" *ngIf="!selectedTask">Delete All Selected Tasks?</p>
      <p class="confirmDeleting-text">
        Do you really want to delete {{selectedTask ? 'this task' : 'these tasks'}}? All information about {{selectedTask ? 'the task' : 'these tasks'}} will no longer be available.
      </p>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" id="btnDeleteTask" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="selectedTask ? deleteTask(selectedTask.id) : deleteSelected(); closeDeletingPopup();">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{selectedTask ?  'Task':  'Tasks'}}
      </button>
    </div>
  </div>
</div>

<app-task-form *ngIf="taskFormOpened" (close)="selectedTask = undefined; closeTaskInfoPopup()" (created)="taskFormOpened = false; selectedTask = undefined; getTasksList()" [task]="selectedTask"></app-task-form>


