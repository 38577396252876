import {Component} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService} from "../../../_services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, first} from "rxjs";
import {User} from "../../../_models/user";
import {UsersService} from "../../../_services/users.service";
import {emailValidatorByPattern, minLengthValidator, requiredValidator} from "../../../_util/validators";
import {GlobalStore} from "../../../global.store";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.scss']
})
export class LoginComponent{
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [emailValidatorByPattern(), requiredValidator()]),
    password: new FormControl('', [minLengthValidator('PASSWORD'), requiredValidator()]),
    remember_me: new FormControl()
  })
  isShowPassword: boolean = false;
  returnUrl: string;
  submitted: boolean = false;
  error: boolean = false;
  showLoader = false;

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UsersService,
              readonly globalStore: GlobalStore) {
    this.route.queryParams.subscribe((params) => this.returnUrl = params['returnUrl']);
    this.authService.makeLogoutRequest().subscribe();

  }

  onSubmit() {
    if (this.loginForm.invalid) return;

    this.showLoader = true;

    const remember_me = this.loginForm.get("remember_me")?.value === true ? 'on' : 'off';
    const loginData = {...this.loginForm.value, remember_me}
    this.authService.login(loginData).pipe(first()).subscribe((data : User) => {
      this.globalStore.currentUser$.pipe(filter(user => !!user), first()).subscribe(() => {
        if (!this.returnUrl) this.returnUrl = this.userService.getUserHomePageUrl(data);
        this.router.navigateByUrl(this.returnUrl);
      })
    },
    () => {
      this.submitted = true;
      this.error = true;
      this.showLoader = false;
    });
  }
}
