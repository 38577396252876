<div class="ng-select-wrapper" [attr.isError]="isError">
  <div class="select-btn-wrapper" #selectRef
       [enabled]="selectRef.hasAttribute('openMenu')"
       (appOutsideClick)="selectRef.removeAttribute('openMenu');">
    <button class="select-btn"
            id="selectBtn"
            type="button"
            (click)="selectRef.toggleAttribute('openMenu')">
      <div class="select-btn-flag-with-code-wrapper">
        <img [src]="'https://hatscripts.github.io/circle-flags/flags/' + selectedCountry?.code.toLowerCase() + '.svg'" class="country-flag">
        <span>{{selectedCountryDialCode || userCurrentCountry?.dial_code}}</span>
      </div>
      <svg class="select-icon-dropdown">
        <use spriteVersion iconName="arrow-select-area-status"></use>
      </svg>
    </button>


    <div class="select-menu-wrapper">
      <ul class="select-menu">
        <li class="select-menu__item" *ngFor="let country of countriesList let idx = index" (click)="changeOption(country); selectRef.removeAttribute('openMenu')">
          <p class="select-menu__text"
             [attr.selectedOption]="country.dial_code === selectedCountryDialCode || country.dial_code === userCurrentCountry.dial_code">
            <img [src]="'https://hatscripts.github.io/circle-flags/flags/' + country.code.toLowerCase() + '.svg'" class="country-flag">
            {{country.dial_code}}
          </p>
        </li>
      </ul>
    </div>
  </div>

</div>
