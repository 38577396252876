import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  Output,
  QueryList, Renderer2, SimpleChanges, ViewChild,
  ViewChildren
} from '@angular/core';
import {modalAnimation} from "../../../../../../config/constants";
import {FormBuilder, FormGroup} from "@angular/forms";
import {FORM_ERRORS, minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import { AreaStatusDefaultOrder} from "../../../../../../_models/area";
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../_models/project";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ProjectAreaStatusesService} from "../../../../../../_services/project-area-statuses.service";
import {NotificationService} from "../../../../../../_services/notification.service";
import {GlobalStore} from "../../../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";
import {logCumulativeDurations} from "@angular-devkit/build-angular/src/builders/browser-esbuild/profiling";
import {NgScrollbar} from "ngx-scrollbar";

@Component({
  selector: 'app-status-color-picker',
  templateUrl: './status-color-picker.component.html',
  styleUrls: ['./status-color-picker.component.scss'],
  animations: [modalAnimation]
})
export class StatusColorPickerComponent implements OnDestroy {
  @HostBinding('@modalAnimation') modalAnimation: any;
  @ViewChild('additionalStatusesListRef', { static: false }) additionalStatusesList: ElementRef;
  @ViewChild('scrollbarRef') scrollbarRef: NgScrollbar;

  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedWithReplacement: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  isChangedCustomForm = false;
  disabledDrag = false;
  isOpenDeleteStatusModal = false;
  isOpenCustomStatusForm = true;
  deletingStatus: ProjectCustomStatuses;

  statusForm: FormGroup;
  submitBtnDisabled = false;
  customStatusList: ProjectCustomStatuses[] = [];
  colorsList  = [{color: ProjectCustomColors.RED, inUse: false}, {color: ProjectCustomColors.ORANGE, inUse: false},
                                            {color: ProjectCustomColors.YELLOW, inUse: false}, {color: ProjectCustomColors.MINT, inUse: false},
                                            {color: ProjectCustomColors.BLUE, inUse: false}, {color: ProjectCustomColors.VIOLET, inUse: false},
                                            {color: ProjectCustomColors.LIGHT_VIOLET, inUse: false}, {color: ProjectCustomColors.BROWN, inUse: false},
                                            {color: ProjectCustomColors.LIGHT_BLUE, inUse: false}, {color: ProjectCustomColors.GREEN, inUse: false},
                                            {color: ProjectCustomColors.PINK, inUse: false}]
  _projectStatuses: ProjectCustomStatuses[] = [];
  statusNames: string[] = []

  companyId: number;
  projectId: number;

  isNotDesktop: boolean = false;

  private readonly destroy$ = new Subject<void>()

  get projectStatuses(): ProjectCustomStatuses[] {
    return this._projectStatuses;
  }

  @Input()
  set projectStatuses(value: ProjectCustomStatuses[]) {
    if (value && this.projectStatuses.length === 0) {
      this._projectStatuses = JSON.parse(JSON.stringify(value));
      this.statusNames = this._projectStatuses.map(status => status.name)
      this.customStatusList = this._projectStatuses.filter(status => status.orderNumber > 0)
      this.checkStatusColorUse()
      this.fillTaskForm();
    }
  }

  constructor(private fb: FormBuilder,
              private projectAreasStatusesService: ProjectAreaStatusesService,
              readonly globalStore: GlobalStore,
              private notifService: NotificationService,
              private renderer: Renderer2) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe(companyId => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => this.projectId = projectId);
    this.fillTaskForm();
    this.globalStore.isNotDesktop$.pipe(takeUntil(this.destroy$)).subscribe(data => this.isNotDesktop = data)
  }



  onInputStatusName(event: any, statusIdx: number) {
    this.customStatusList.map((status, i) => {
      if(statusIdx === i && status.errorName === true && status.name !== event.target.value.trim()) status.errorName = false;
      if(statusIdx === i) {
        status.name = event.target.value.trim();
        status.changed = true;
      }
    })
  }

  dropStatusField(event: CdkDragDrop<ProjectCustomStatuses[]>) {
    moveItemInArray(this.customStatusList, event.previousIndex, event.currentIndex);
  }

  deleteStatus(statusIndex: number | null, customStatusUse: boolean, statusId: number | null) {
    this.isChangedCustomForm = true;
    let status: ProjectCustomStatuses
    if(statusIndex !== null) status = this.customStatusList.find((status, i) => statusIndex === i)
    if(statusId) {
      status = this.customStatusList.find((status) => statusId === status.id)
    }
    if(customStatusUse) {
      this.deletingStatus = status;
      this.isOpenCustomStatusForm = false;
      this.isOpenDeleteStatusModal = true;
      return
    }

    if(status && status.color !== '#C6CED9') this.colorsList.find(colorItem => colorItem.color === status.color).inUse = false;
    if(status && statusIndex !== null) this.customStatusList = this.customStatusList.filter((status, i) => statusIndex !== i);
    if(status && statusId !== null) {
      this.customStatusList = this.customStatusList.filter((status, i) => status.id !== statusId);
    }
  }

  checkStatusColorUse() {
    this.customStatusList.map(status => {
      if (status.color === '#C6CED9') return
      this.colorsList.find(colorItem => colorItem.color === status.color).inUse = true
    })
  }

  subscribeToFormChanges(){
    this.statusForm.valueChanges.subscribe(() => {
      this.statusForm.controls['todo'].valueChanges.subscribe(() => this._projectStatuses.find(status => status.orderNumber === -3).changed = true);
      this.statusForm.controls['inProgress'].valueChanges.subscribe(() => this._projectStatuses.find(status => status.orderNumber === -2).changed = true)
      this.statusForm.controls['done'].valueChanges.subscribe(() => this._projectStatuses.find(status => status.orderNumber === -1).changed = true)
      this._projectStatuses.find(status => status.orderNumber === -3).name = this.statusForm.controls['todo'].value;
      this._projectStatuses.find(status => status.orderNumber === -2).name = this.statusForm.controls['inProgress'].value;
      this._projectStatuses.find(status => status.orderNumber === -1).name = this.statusForm.controls['done'].value;
    })

  }

  fillTaskForm() {
    const statusToDo = this.projectStatuses.find(status => status.orderNumber === -3)?.name;
    const statusInProgress = this.projectStatuses.find(status => status.orderNumber === -2)?.name;
    const statusDone = this.projectStatuses.find(status => status.orderNumber === -1)?.name;
    this.statusForm = this.fb.group({
      notInUse: [{value: 'Not In Use', disabled: true}],
      todo: [statusToDo, [minLengthValidator('NAME'), requiredValidator()]],
      inProgress: [statusInProgress, [minLengthValidator('NAME'), requiredValidator()]],
      done: [statusDone, [minLengthValidator('NAME'), requiredValidator()]]
    })

    this.subscribeToFormChanges()
  }

  addStatus() {
    const statusField = new ProjectCustomStatuses();
    statusField.name = ''
    statusField.color = '#C6CED9';
    this.customStatusList.push(statusField);
    this.scrollbarRef.update();
  }

  changeColorStatus(event: any, statusIdx: number, color: string) {
    this.customStatusList.map((status, i) => {
      if(statusIdx === i && status.color === color) {
        this.colorsList.find(colorItem => colorItem.color === color).inUse = false;
        status.color = '#C6CED9';
        return
      }
      if(statusIdx === i && status.color && status.color !== '#C6CED9' &&  status.color !== color) {
        this.colorsList.find(colorItem => colorItem.color === status.color).inUse = false;
        this.colorsList.find(colorItem => colorItem.color === color).inUse = true;
        status.color = color;
        return
      }
      if(statusIdx === i) {
        status.color = color;
        this.colorsList.find(colorItem => colorItem.color === color).inUse = true;
      }
    })

  }

  isErrorName(statusOrder: number) {
    return this.projectStatuses.find(status => status.orderNumber === statusOrder).errorName
  }

  submitForm() {
    let hasError = false;
    this.customStatusList.map((status, i) => {
      status.orderNumber = i + 1
      status.defaultStatus = false;
      if(status.name === '') {
        status.error = true;
        hasError = true;
      }
      else status.error = false;
    });

    if(hasError) return;

    this.customStatusList = this.customStatusList.filter(customStatus => customStatus.name !== '')
    let allStatusesList = [...this.customStatusList]
    let defaultStatuses: ProjectCustomStatuses[] = [];
    this._projectStatuses.map(status => {
      if(status.defaultStatus === true) defaultStatuses.push(status)
    })
    allStatusesList.unshift(...defaultStatuses)

    defaultStatuses.map(defaultStatus => {
      if(defaultStatus.name === '') hasError = true;
    })

    if(hasError) return;


    this.projectAreasStatusesService.saveProjectAreaStatuses(this.companyId, this.projectId, allStatusesList).subscribe((data) => {
      this.created.emit(allStatusesList)
      this.notifService.successNotification('Changes have been saved')
    }, error => {
      if (error.status === 400) {
        allStatusesList.map(status => {
          if (error.error.error.includes(status.name) && status.changed) status.errorName = true;
          if (error.error.error.includes(status.name) && status.defaultStatus === true && status.changed) {
            switch (status.orderNumber) {
              case -3: {
                this.statusForm.controls['todo'].setErrors({invalid: true})
                break
              }
              case -2: {
                this.statusForm.controls['inProgress'].setErrors({invalid: true})
                break
              }
              case -1: {
                this.statusForm.controls['done'].setErrors({invalid: true})
                break
              }
            }
          }
        })
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly FORM_ERRORS = FORM_ERRORS;
  protected readonly String = String;
    protected readonly AreaStatusDefaultOrder = AreaStatusDefaultOrder;
  protected readonly ElementRef = ElementRef;
}
