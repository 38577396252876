import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {MaterialStoredLocation, MaterialUnit} from "../_models/material";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaterialUnitService {

  constructor(private http: HttpClient) { }

  getAllMaterialUnits(companyId: number, projectId: number):Observable<MaterialUnit[]> {
    return this.http.get<MaterialUnit[]>(`${apiUrl}/company/${companyId}/project/${projectId}/material/units`, { withCredentials: true})
  }

  createMaterialUnit(companyId: number, projectId: number, name: string) {
    return this.http.post(`${apiUrl}/company/${companyId}/project/${projectId}/material/units`, name,{withCredentials: true})
  }

  deleteMaterialUnit(companyId: number, projectId: number, materialUnitId: number) {
    let params = new HttpParams();
    if (materialUnitId) params = params.set('id', materialUnitId);

    return this.http.delete(`${apiUrl}/company/${companyId}/project/${projectId}/material/units`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }
}
