import { Area } from "../../../../_models/area";
import { Floor } from "../../../../_models/floor";
import { Room } from "../../../../_models/room";
import { Unit } from "../../../../_models/unit";
import {ProjectCustomStatuses} from "../../../../_models/project";

export interface AreaClickPayload {
  area: Area;
  floor: Floor;
  unit: Unit;
  room: Room;
}

export class SelectedRoom {
  room: Room;
  unitId: number;
  unitName: string;
}

export class SelectedArea {
  area: Area;
  floorId: number;
  floorName: string;
  unitId: number;
  unitName: string;
  roomId: number;
  roomName: string;
}
