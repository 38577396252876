
<div class="status-with-filters-wrapper" [attr.isViewPage]="isViewPage ? 'true' : 'false'">
  <ul class="view-area-status__list">
      <li class="view-area-status__item">
          <span class="view-area-status__mark view-area-status__mark--not-use"></span>
          Not In Use
      </li>
      <li class="view-area-status__item" *ngFor="let status of projectStatuses">
          <span class="view-area-status__mark" [attr.status]="status.orderNumber === -3 ? status.orderNumber : ''" [style.background-color]="status.orderNumber !== -3 ? status.color : ''"></span>
          {{status.name}} ({{(statusesInUseAmount && Math.round(statusesInUseAmount.get(status.id) / totalElements * 1000) / 10) || 0}}%)
      </li>
  </ul>

    <div class="filters-with-customize-btn-wrapper">
        <ng-template [ngIf]="!isViewPage && !isRoomOrderingMode && !isAreaOrderingMode">
            <app-filters-project-progress></app-filters-project-progress>
        </ng-template>

        <div *ngIf="(globalStore.currentUser$ | async).role !== UserRole.ROLE_EMPLOYEE && !isViewPage && !isRoomOrderingMode && !isAreaOrderingMode" class="customize-status-wrapper" (appOutsideClick)="isOpenStatusMenu = false" [enabled]="isOpenStatusMenu === true">
            <button type="button" class="customize-status-btn" [attr.open]="isOpenStatusMenu" (click)="isOpenStatusMenu = true"></button>
            <ul class="customize-menu" [attr.open]="isOpenStatusMenu">
                <li class="customize-menu__item" (click)="isOpenStatusMenu = false; openCustomizeForm = true">Customize Status</li>
                <li class="customize-menu__item" [attr.disabled]="roomsAmount === 0" (click)="isOpenStatusMenu = false; startOrderChanging.emit('rooms'); isRoomOrderingMode = true">Room Position</li>
              <li class="customize-menu__item" [attr.disabled]="roomsAmount === 0" (click)="isOpenStatusMenu = false; startOrderChanging.emit('areas'); isAreaOrderingMode = true">Area Position</li>
            </ul>
        </div>

      <div *ngIf="isRoomOrderingMode || isAreaOrderingMode" class="btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="cancelOrder()">Cancel</button>
        <button type="submit" class="btn-invite" id="btnAddUsers" [disabled]="saveBtnDisabled" loader="white" [showLoader]="saveBtnDisabled" (click)="isRoomOrderingMode = false; isAreaOrderingMode = false; saveOrder.emit()">Save</button>
      </div>

    </div>
</div>

<app-status-color-picker *ngIf="openCustomizeForm" (close)="openCustomizeForm = false"
                         (created)="openCustomizeForm = false; updateStatuses.emit()"
                         (deletedWithReplacement)="deletedStatusWithReplacement.emit($event)"
                         [projectStatuses]="projectStatuses"></app-status-color-picker>

