
<div fileDropArea [inputElement]="fileInput"
     (filesDropped)="toggleStatusLoadingFile($event)"
     class="filedrop-box" id="drop-areaMobile" [attr.isMarginBottom]="errorUploadFileSize || errorUploadFilesAmount || getSumMaterialFileList().length !== 0">
  <div class="upload-file-text-wrapper">
    <div class="file-icon-wrapper" (click)="fileInput.click()">
      <svg class="file-icon">
        <use spriteVersion iconName="file-icon"></use>
      </svg>
    </div>
    <p class="upload-file-text">Drop your document here, or
      <a class="browse-link" (click)="fileInput.click()"> <input type="file" accept="audio/*,video/*,image/*,application/*" #fileInput class="input-file" id="fileInputMobile"> click to browse</a></p>
    <p class="max-size-text">Max size {{maxFileSizeInMb}}Mb.</p>
  </div>
</div>
<p class="small-error-text-field upload-file-error" id="uploadFileErrordrop-area" *ngIf="errorUploadFileSize || errorUploadFilesAmount">{{errorUploadFileSize ? 'Error to upload. File must not be over ' + maxFileSizeInMb + 'MB.' : 'Error to upload. Files amount must not be over 10.'}}</p>

<ul class="files-list">
  <li class="file-item" *ngFor="let file of getSumMaterialFileList()">
    <a [href]="file.fileUrl || '#'" target="_blank" [attr.url]="!!file.fileUrl">
      <div class="file-item-inner-wrapper">
        <img [src]="file.imgSrc || file.fileUrl" class="file-img" *ngIf="(file.imgSrc || file.fileUrl) && isFileCanBePreviewed(file.name); else filePlaceholder">
        <ng-template #filePlaceholder>
          <div class="file-loaded-icon-wrapper">
            <svg class="file-loaded-icon">
              <use spriteVersion iconName="document-icon"></use>
            </svg>
          </div>
        </ng-template>
        <div class="fileName-with-loader">
          <p class="fileName-with-timeLeft">{{file.name | sliceText: 40}} <span *ngIf="file.progress < 100" class="timeLeft">{{file.progress + '% / ' + file.timeLeft + 's'}}</span></p>
          <p *ngIf="file.progress < 100"  class="loadingBar" id="loaderFiledrop-area" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + file.progress + '%, #F0F2F6 ' + file.progress + '%), #FF4E00 100%'"></p>
          <p *ngIf="file.progress === 100 || file.sizeBytes" class="fileSize" id="fileSizeMobile">{{file.sizeBytes | fileSizePrettify}}</p>
        </div>
      </div>
    </a>
    <svg class="delete-icon" (click)="removeFile(file)">
      <use spriteVersion iconName="trash-delete-icon"></use>
    </svg>
  </li>
</ul>
