import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction
} from '@angular/core';
import { Area } from 'src/app/_models/area';
import { Floor } from 'src/app/_models/floor';
import { Room } from 'src/app/_models/room';
import { Unit } from 'src/app/_models/unit';
import { areaWeightByEnum } from 'src/app/config/constants';
import { AreaClickPayload } from '../../types';
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../_models/project";

@Component({
  selector: 'app-rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsListComponent {
  @Input() public unit: Unit;
  @Input() public currentFloor: Floor;

  @Input() public amountMocksHorizontal: number;
  @Input() public isNotDesktopScreen: boolean;
  @Output() public areaClick = new EventEmitter<AreaClickPayload>();

  protected readonly areaWeightByEnum = areaWeightByEnum;

  trackByRoom: TrackByFunction<Room> =(_: number, room: Room): number => {
    return room.id;
  }

  trackByArea: TrackByFunction<Area> = (_: number, area: Area): string => {
    return [area.id, area.statusProgress, area.areaWeight, area.attachmentsAmount, area.commentsAmount, area.pp_opacity].join('-');
  }

  addColorToAreaTriangle(area: Area): any {
    if (area.status.orderNumber < 0) {
      if (area.status.orderNumber !== -3 && area.statusProgress >= 60) return 'white'
      else return 'black'
    }
    if (area.status.color === ProjectCustomColors.YELLOW || area.status.color === ProjectCustomColors.GREY || area.status.color === ProjectCustomColors.MINT) {
      return "black"
    } else return "white"
  }
}
