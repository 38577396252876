<ul class="units-list">
  <ng-container *ngIf="floor.units.length; else emptyUnitsTpl">
    <li class="units-list__item" *ngFor="let unit of floor.units; trackBy: trackByUnit">
      <p class="unit-cell-name" [title]="unit.name"> {{unit.name}}</p>
      <app-rooms-list [unit]="unit" [currentFloor]="floor" [amountMocksHorizontal]="amountMocksHorizontal" [isNotDesktopScreen]="isNotDesktopScreen"
        (areaClick)="areaClick.emit($event)"></app-rooms-list>
    </li>
  </ng-container>

  <ng-template #emptyUnitsTpl>
    <li class="units-list__item unit-mock" *ngIf="floor.units.length === 0">
      <p class="unit-cell-name"></p>
      <ul *ngIf="(floors$ | async).length > 1 || floor.units.length > 0" class="area-progress-list-rooms">
        <li *ngFor="let room of tableHead$ | async" class="area-progress-list-rooms__item">
          <ul class="area-progress-list-areas">
            <li class="area-progress-list-areas__item disabled" *ngFor="let area of room.areas"></li>
            <li class="area-progress-list-areas__item area-mock" *ngIf="room.areas.length === 0"></li>
          </ul>
        </li>
        <li *ngFor="let roomMock of [].constructor(amountMocksHorizontal)"
          class="area-progress-list-rooms__item room-mock">
          <ul class="area-progress-list-areas">
            <li class="area-progress-list-areas__item area-mock"></li>
          </ul>
        </li>
      </ul>
    </li>
  </ng-template>
</ul>
