import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TrackByFunction,
  EventEmitter,
  Output,
  OnChanges, SimpleChanges
} from '@angular/core';
import { Floor } from 'src/app/_models/floor';
import { Unit } from 'src/app/_models/unit';
import { AreaClickPayload } from '../../types';
import {ProjectProgressStore} from "../project-progress.store";

@Component({
  selector: 'app-units-list',
  templateUrl: './units-list.component.html',
  styleUrls: ['./units-list.component.scss', '../rooms-list/rooms-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsListComponent {
  @Input() public floor: Floor;
  tableHead$ = this.projectProgressStore.tableHead$;
  floors$ = this.projectProgressStore.floors$;

  @Input() public amountMocksHorizontal: number;
  @Input() public isNotDesktopScreen: boolean;
  @Output() public areaClick = new EventEmitter<AreaClickPayload>();

  trackByUnit: TrackByFunction<Unit> = (_: number, unit: Unit): number => {
    return unit.id;
  }

  constructor(readonly projectProgressStore: ProjectProgressStore) {
  }
}
