<div class="files-container">
  <p *ngIf="(areaFormStore.commentsWithFiles$ | async).length === 0; else filesPresent" class="no-changes-placeholder">No files have been added yet</p>
  <ng-template #filesPresent>
    <ul class="comments-items">
      <li *ngFor="let item of (areaFormStore.commentsWithFiles$ | async)">
        <div class="top-line">
          <div class="initiator">
            <p class="initiator">{{(currentUser | async).id === item.author.id ? 'You' : item.author.name}}</p>
            <span>added file<ng-container *ngIf="item.files.length > 1">s</ng-container></span>
          </div>
          <div class="date-and-actions">
            <p class="timestamp">{{item.timestamp | date: 'MMM d, y h:mm a'}}</p>
            <div (appOutsideClick)="editMenuComment.removeAttribute('editMenuCommentOpen')" [enabled]="editMenuComment.hasAttribute('editMenuCommentOpen')" [ngStyle]="{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}">

              <span *ngIf="(currentUser | async).role === UserRole.ROLE_SUPERADMIN || (currentUser | async).id === item.author.id" class="comments-list__comment-menu-dots" id="menuListEditCommentOpen" (click)="editMenuComment.toggleAttribute('editMenuCommentOpen')"></span>

              <ul class="editMenuList" #editMenuComment>
                <li class="editMenuList__item" id="deleteUnitBtn" (click)="editMenuComment.removeAttribute('editMenuCommentOpen'); deleteFile(item.id)">
                  <svg class="editMenu-icon">
                    <use spriteVersion iconName="trash-delete-icon"></use>
                  </svg>
                  Delete
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul class="files">
          <li class="file" *ngFor="let file of item.files" [attr.wider]="item.files.length > 1 ? 'false' : 'true'">
            <a class="file-link" [href]="file.fileUrl" target="_blank">
              <div class="file-item-inner-wrapper">
                <img *ngIf="renderImage(file.fileUrl); else docIcon" src="{{file.fileUrl}}"/>
                <ng-template #docIcon>
                  <div class="file-loaded-icon-wrapper">
                    <svg class="file-loaded-icon"><use spriteVersion iconName="document-icon"></use></svg>
                  </div>
                </ng-template>
                <div class="fileName-with-loader">
                  <p class="fileName-with-timeLeft">{{file.name | sliceText: 40}}</p>
                  <p class="fileSize" id="fileSize">{{file.sizeBytes | fileSizePrettify}}</p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-template>
</div>
