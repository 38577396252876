import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Unit} from "../../../../../../_models/unit";
import {RoomsService} from "../../../../../../_services/rooms.service";
import {NotificationService} from "../../../../../../_services/notification.service";
import {TemplatesService} from "../../../../../../_services/templates.service";
import {Room} from "../../../../../../_models/room";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Floor} from "../../../../../../_models/floor";
import {UnitsService} from "../../../../../../_services/units.service";
import {minLengthValidator, requiredValidator,} from "../../../../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PopupOpened, ProjectViewStore} from "../project-view.store";
import {UserRole} from "../../../../../../_models/user";
import {of} from "rxjs";
import {GlobalStore} from "../../../../../../global.store";
import {modalAnimation} from "../../../../../../config/constants";

@Component({
  selector: 'app-unit-block',
  templateUrl: './unit-block.component.html',
  styleUrls: ['./unit-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [modalAnimation],
})
export class UnitBlockComponent {
  private _unit: Unit;
  unitForm: FormGroup;

  @Input() currentFloor: Floor;
  @Input() isCdkDragDisabled = false;
  @Input() isNotDesktop = false;
  @Input() roomTemplatesShortList: Room[] = [];

  @Output() openDeletingPopup: EventEmitter<any> = new EventEmitter<any>()

  templateToDelete: Room;
  deletingBtnDisabled = false;

  isUnitFormOpened = false;
  isUnitMenuOpened = false;
  isAddRoomMenuOpened = false;

  get unit(): Unit {
    return this._unit;
  }

  @Input()
  set unit(value: Unit) {
    if (value) {
      this._unit = value;
      this.unitForm = this.fb.group({
        name: [this._unit.name, [minLengthValidator('FLOOR_UNIT_NAME'), requiredValidator()]],
      })
    }
  }

  constructor(
    readonly projectViewStore: ProjectViewStore,
    readonly globalStore: GlobalStore,
    private fb: FormBuilder,
    private unitsService: UnitsService,
    private roomsService: RoomsService,
    private notifService: NotificationService,
    private roomTemplatesService: TemplatesService) {
  }

  openEditUnitForm(unit: Unit) {
    this.isUnitFormOpened = true;
    this.isUnitMenuOpened = false;
    this.projectViewStore.updateIsCdkDragDisabled(true);

    this.unitForm.controls['name'].setValue(unit.name);
  }

  dropRooms(event: CdkDragDrop<Room[]>) {
    let newOrder = event.currentIndex;
    let oldOrder = event.previousIndex;

    if (newOrder === oldOrder) return;

    let roomPayload =  event.item.data as RoomDropPayload;

    const unitId = roomPayload.unitId;
    this.projectViewStore.changeRoomOrder(of({unitId, newOrder, oldOrder}));

    this.roomsService.updateRoomOrderById(this.projectViewStore.companyId, this.projectViewStore.projectId, this.currentFloor.id, roomPayload.unitId, roomPayload.room.id, newOrder).subscribe();
  }

  updateUnitName() {
    if (this.unitForm.invalid) return;
    if (this._unit.name === this.unitForm.controls['name'].value) {
      this.isUnitFormOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.projectViewStore.updateIsCdkDragDisabled(false);
      return;
    }

    this.unitsService.updateUnitNameById(this.projectViewStore.companyId, this.projectViewStore.projectId, this.currentFloor.id, this._unit.id, this.unitForm.controls['name'].value, this._unit.version).subscribe((data) => {
      this._unit.name = this.unitForm.controls['name'].value;
      this._unit.version++;
      this.projectViewStore.updateUnitName({unitId: this.unit.id, unitName: this.unit.name})
      this.isUnitFormOpened = false;
      this.projectViewStore.updateIsCdkDragDisabled(false);

      this.notifService.successNotification('Changes have been saved')
    }, (error: HttpErrorResponse) => {
      if (error.status === 400 && error.error.error === 'Name already in use') this.unitForm.controls['name'].setErrors({error: 'Unit with such name already exists'})
    })
  }

  openCreateRoomForm() {
    this.isAddRoomMenuOpened = false;
    this.projectViewStore.updateSelectedObject({obj: {room: null, unitId: this.unit.id, unitName: null}})
    this.projectViewStore.updateIsCdkDragDisabled(false);
    this.projectViewStore.updatePopupOpened(PopupOpened.ROOM_CREATE)
  }

  openCreateRoomBasedOnTemplateRoomForm(roomTemplate: Room) {
    this.isAddRoomMenuOpened = false;
    this.projectViewStore.updateIsCdkDragDisabled(false);

    this.roomTemplatesService.getTemplateById(this.projectViewStore.companyId, this.projectViewStore.projectId, roomTemplate.id).subscribe((data) => {
      this.projectViewStore.updatePopupOpened(PopupOpened.CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE)
      this.projectViewStore.updateSelectedObject({obj: {room: data, unitId: this.unit.id, unitName: this.unit.name}})
    })
  }

  onOpenEditTemplate(roomTemplate: Room) {
    this.isAddRoomMenuOpened = false;
    this.roomTemplatesService.getTemplateById(this.projectViewStore.companyId, this.projectViewStore.projectId, roomTemplate.id).subscribe((data) => {
      this.projectViewStore.updateSelectedObject({obj: {room: data, unitId: this.unit.id, unitName: this.unit.name}})
      this.projectViewStore.updatePopupOpened(PopupOpened.TEMPLATE_EDIT)
    })
  }

  openDeleteTemplate(template: Room) {
    this.isAddRoomMenuOpened = false;
    this.templateToDelete = template;
    this.openDeletingPopup.emit({type: 'template', template: this.templateToDelete, unit: null})
  }

  addRoomClicked() {
    if (this.roomTemplatesShortList.length > 0) {
      this.isAddRoomMenuOpened = !this.isAddRoomMenuOpened;
      this.projectViewStore.updateIsCdkDragDisabled(!this.isCdkDragDisabled);
    }
    else this.openCreateRoomForm()
  }

  protected readonly UserRole = UserRole;
  protected readonly event = event;
}
export class RoomDropPayload {
  unitId: number;
  room: Room;
}
